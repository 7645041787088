import React, { useState, useContext, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, Alert, Platform, StatusBar, Dimensions } from 'react-native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import SignupScreen from './SignupScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import SignupOkScreen from './SignupOkScreen';
import { registerForPushNotificationsAsync  } from '../MenuScreen';

import { Ionicons } from '@expo/vector-icons';
import { HeaderCenterImage } from '../../components/CustomHeader';
import i18n from 'i18n-js';
import { ApolloClient, InMemoryCache, gql, useMutation, HttpLink, ApolloLink, concat } from '@apollo/client';

import MyContext from '../../components/MyContext';
import ApiKeys from '../../constants/ApiKeys';
import { createStrapiExpoToken, removeStrapiPushToken, sendLog } from '../../components/StrapiInterface';
import analytics from '@react-native-firebase/analytics';
import { MyButton } from '../../components/MyComponents';

const { width, height } = Dimensions.get('window');
const fontScale = width > 480 ? 1.5 : 1;

const LoginScreen = ({ navigation }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [expoPush, setExpoPush] = useState(false);

    var { myEmail, myJwt, mySettingId, myId, myLocale, myProvinceIdx, myLocalityIdx, myPushToken, updateMyContext } = useContext(MyContext);

    useEffect(() => {
        if (myPushToken) setExpoPush(true);
    });

    const LOGIN_MUTATION = gql`
        mutation loginMutation($identifier: String!, $password: String!) {
            login (input: { identifier: $identifier, password: $password}) {
                jwt,
                user {
                    id
                    email
                }
            }
        }
    `;

    const [mutateFunction, { loading, error, data }] = useMutation(LOGIN_MUTATION);

    if (loading) console.log(`[ LoginScreen ] Trying to log in ...`);
    if (error) console.log(`[ LoginScreen ] Login error! ${error.message}`);
    if (data) console.log('[ LoginScreen ] Login succeeded! ' + JSON.stringify(data));

    const _getStrapiUserSettings = async ( newJwt, userId ) => {
        console.log("[ LoginScreen ] _getStrapiUserSettings is called. ", email, ", jwt: ", newJwt);

        const client_no_jwt = new ApolloClient({
            uri: ApiKeys.StrapiConfig.URI,
            cache: new InMemoryCache(),
        });

        const httpLink = new HttpLink({ uri: ApiKeys.StrapiConfig.URI });

        const authMiddleware = new ApolloLink((operation, forward) => {
            // add the authorization to the headers
            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    authorization: `Bearer ${newJwt}`,
                }
            }));

            return forward(operation);
        })

        const client_w_jwt = new ApolloClient({
            cache: new InMemoryCache(),
            link: concat(authMiddleware, httpLink),
        });

        // !! MyEmail cannot be used because the context variable will not be updated until this running instance is finished.
        const GET_USERSETTINGS = gql`
          query getUserSettings {
            usersettings (where: { email: "${email}" } ) {
              id,
              email,
              locale,
              province_idx,
              locality_idx,
              expo_push,
            },
          }
        `;

        const CREATE_USERSETTING = gql`
          mutation createUserSetting {
            createUsersetting (input: { data: { email: "${email}", locale: "${myLocale}",province_idx: ${myProvinceIdx}, locality_idx: ${myLocalityIdx}, expo_push: ${expoPush} } } ) {
              usersetting {
                id,
                email,
              }
            },
          }
        `;

        try {
            const res = await client_no_jwt.query({
                query: GET_USERSETTINGS
            });

            //console.log(res);
            if (res) {
                let settings = res.data.usersettings;
                console.log("[ LoginScreen ] (_getStrapiUserSettings) usersettings: ", JSON.stringify(settings));

                switch (settings.length) {
                    case 0: // there is no usersetting for this email.
                        try {
                            let result = await client_w_jwt.mutate({
                                mutation: CREATE_USERSETTING
                            });
                            console.log("[ LoginScreen ] (_getStrapiUserSettings) Success: createUserSetting ", result);
                            updateMyContext({ mySettingId: result.data.createUsersetting.usersetting.id });
                            if (expoPush) { // if expo notification was enabled.
                                await createStrapiExpoToken({ myPushToken: myPushToken, myId: userId });
                            }
                        } catch (err) {
                            sendLog(i18n.t('create_usersetting_err')+"[e01]" + JSON.stringify(err));
                            //Alert.alert(i18n.t('create_usersetting_err')+"[e01]");
                            console.log("[ LoginScreen ] (_getStrapiUserSettings) Error: createUserSetting ", err);
                        }
                        break;
                    case 1:
                        updateMyContext({ 
                            mySettingId: settings[0].id, 
                            myLocale: settings[0].locale, 
                            myProvinceIdx: settings[0].province_idx, 
                            myLocalityIdx: settings[0].locality_idx,
                        });

                        if (settings[0].expo_push) {
                            try {
                                let token = await registerForPushNotificationsAsync();
                                if (myPushToken && myPushToken != token) {
                                    console.log("[ LoginScreen ] (_getStrapiUserSettings) Error!: PushToken Mismatch....");
                                    sendLog(i18n.t('setting_expotoken_err')+"[e11]");
                                    //Alert.alert(i18n.t('setting_expotoken_err')+"[e11]");
                                }
                                updateMyContext({ myPushToken: token });   
                                await createStrapiExpoToken({ myPushToken: token, myId: userId });
                            } catch(err) { 
                                sendLog(i18n.t('setting_expotoken_err')+"[e13]" + JSON.stringify(err));
                                console.log("[ LoginScreen ] (_getStrapiUserSettings) Error!: ", err);  
                            };      
                        } else if (myPushToken) {
                            await removeStrapiPushToken({ myPushToken: myPushToken });         
                            updateMyContext({ myPushToken: '' });
                        }

                        console.log("[ LoginScreen ] (_getStrapiUserSettings) mySettingId is ", settings[0].id);
                        break;
                    default:
                        sendLog(i18n.t('fetch_usersetting_err')+"[e21]");
                        //Alert.alert(i18n.t('fetch_usersetting_err')+"[e21]");
                        break;
                }
            }
        } catch (err) {
            sendLog(i18n.t('fetch_usersetting_err')+"[e23]" + JSON.stringify(err));
            //Alert.alert(i18n.t('fetch_usersetting_err')+"[e23]"); 
            console.log("[ LoginScreen ] _getStrapiUserSettings: ", err)
        }
    }

    const _onLoginPress = async () => {
        const error_msg_1 = i18n.t('error_unknown_id_m');
        const error_msg_2 = i18n.t('error_login_fail_m');

        console.log("[ LoginScreen ] (_onLoginPress) is called. email: " + email + ", password: " + password);
        
        try {
            const res = await mutateFunction({ variables: { identifier: email, password: password } });

            console.log("[ LoginScreen ] (_onLoginPress) Results..." + JSON.stringify(res.data.login));

            // !!! JWT should be updated before updating Email. If not, Menuscreen generates invalid JWT error on intermediate refreshings.
            updateMyContext({ myJwt: res.data.login.jwt });
            updateMyContext({ myEmail: res.data.login.user.email });
            updateMyContext({ myId: res.data.login.user.id });

            // !!! Accessing the Strapi user setting should be with the jwt fetched above.
            //     The myJwt in MyContext cannot be used because this new value will be reflected after this running instance is finished.
            _getStrapiUserSettings( res.data.login.jwt, res.data.login.user.id );

            if (Platform.OS === 'android' || Platform.OS === 'ios')
                await analytics().logEvent('Login', {
                    status: 'succeed'
                });        
        //Alert.alert("Login succeeded!"); 
            navigation.navigate("Main");
        } catch (error) {
            if (Platform.OS === 'android' || Platform.OS === 'ios')
                await analytics().logEvent('Login', {
                    status: 'fail'
                });        
            console.log("[ LoginScreen ] (_onLoginPress) Error: 이메일 혹은 패스워드가 잘못되었습니다.");
            console.log("error..." + error.graphQLErrors[0].extensions.code + ": " + error.graphQLErrors[0].extensions.exception.data.message[0].messages[0].message);
            Alert.alert(i18n.t("wrong_email_password"));
        }
    }

    const _onCancelPress = () => {
        console.log("[ LoginScreen ] _onCancelPress is called.");
        navigation.goBack();
        //navigation.navigate('Main');
    }

    const _onCreateAccountPress = () => {
        navigation.navigate('Signup');
    }

    const _onForgotPasswordPress = () => {
        navigation.navigate('ForgotPassword');
    }

    return (
        <View style={styles.container}>
            <StatusBar barStyle="light-content" hidden={false} />
            <Text style={{ fontSize: 19 * fontScale, fontWeight: 'bold', color: '#2b2e34', marginVertical: 25 * fontScale }}>{i18n.t('login_m')}</Text>
            <TextInput style={styles.textInput}
                value={email}
                onChangeText={(text) => { setEmail(text) }}
                placeholder={" "+i18n.t('email')}
                placeholderTextColor="#d0d0d0"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
            />

            <View style={{ paddingTop: 10 * fontScale }} />

            <TextInput style={styles.textInput}
                value={password}
                onChangeText={(text) => { setPassword(text) }}
                placeholder={" "+i18n.t("password")}
                placeholderTextColor="#d0d0d0"
                secureTextEntry={true}
                autoCapitalize="none"
                autoCorrect={false}
            />

            <MyButton
                style={[styles.button, { marginTop: 20 * fontScale }]} 
                onPress={_onLoginPress} 
                children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
                />
            <MyButton 
                style={styles.button} 
                onPress={_onCancelPress} 
                children={() => <Text style={styles.appButtonText}>{i18n.t('cancel_m')}</Text>}
                />
            <View style={{
                paddingTop: 10 * fontScale, paddingBottom: 20 * fontScale, borderColor: 'rgb(43, 46, 52)', borderTopWidth: 1,
                width: '80%', alignSelf: 'center', marginVertical: 30 * fontScale, borderBottomWidth: 1
            }}>
                <MyButton 
                    style={styles.button} 
                    onPress={_onCreateAccountPress} 
                    children={() => <Text style={styles.appButtonText}>{i18n.t('create_account_m')}</Text>}
                    />
                <MyButton 
                    style={styles.button} 
                    onPress={_onForgotPasswordPress} 
                    children={() => <Text style={styles.appButtonText}>{i18n.t('forget_pwd_q')}</Text>}
                    />
            </View>
            <Text style={{ fontSize: 13 * fontScale, lineHeight: 19, textAlign: 'center', width: '80%' }}>
                {i18n.t('login_err_guide_m')}
            </Text>
            <Text style={{ fontSize: 15 * fontScale, textAlign: 'center', width: '80%', marginTop: 15, marginBottom: 40 }}>
                support@cakory.com
            </Text>
            <Text style={{ fontSize: 10 * fontScale, textAlign: 'center' }}>CAKORY (c) 2022 All Rights Reserved</Text>
        </View>
    );

}

export default function LoginStack()  {
    const Stack = createStackNavigator();

    return (
        <Stack.Navigator
            initialRouteName='Login'
            screenOptions={{
                headerStyle: {
                    backgroundColor: '#272727',
                },
                headerMode: 'float',
                headerTitle: () => <HeaderCenterImage />,
                headerTitleAlign: 'center',
                headerTintColor: '#ffffff',
                headerBackTitleVisible: false,
                headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="white" />,
                ...TransitionPresets.SlideFromRightIOS, // This makes screen switching in Android be like IOS style.
            }}
        >
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Signup" component={SignupScreen} />
            <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
            <Stack.Screen name="SignupOk" component={SignupOkScreen} />
        </Stack.Navigator>
    )
};


const styles = StyleSheet.create({
    container: {
        flex: 1, alignItems: "center",
    },
    textInput: {
        fontSize: 14 * fontScale,
        width: 200 * fontScale, height: 40 * fontScale, borderWidth: 1, borderRadius: 4,
    },
    button: {
        marginVertical: 10 * fontScale,
        paddingHorizontal: 15 * fontScale
    },
    appButtonText: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
        color: "#fff",
        alignSelf: "center",
        textTransform: "uppercase"
    }
});