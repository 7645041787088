import React, { createContext } from "react";

const MyContext = React.createContext({
  myId: -1,
  myEmail: '',
  mySettingId: -1,
  myLocale: 'en',
  myProvinceIdx: 0,
  myLocalityIdx: 0,
  myJwt: '',
  myPushToken: '', // expo notification token
  myGpsLatitude: 51.0447, // center of calgary
  myGpsLongitude: -114.0719,
  myNotiCheckTime: 0,
  myNotiArriveTime: 0,
  myApolloQueryTime: 0,
  myBannerRefreshTime: 0,
  updateMyContext: () => {},
})
  
export default MyContext;  