import React from 'react';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { Image, View, Dimensions } from 'react-native';

import Colors from '../constants/Colors';

const { width, height } = Dimensions.get('window');
const fontScale = width > 480? 1.5 : 1;

export default class TabBarIcon extends React.Component {
  render() {
    return (
      <Ionicons
        name={this.props.name}
        size={26 * fontScale}
        style={{ marginBottom: -3 }}
        color={this.props.focused ? Colors.tabIconSelected : Colors.tabIconDefault}
      />
    );
  }
}

export class SearchIcon extends React.Component {
  render() { 
    return (
      <FontAwesome name="search" size={20} color={this.props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} />
    );
  }
}

export class ListIcon extends React.Component {
  render() { 
    return (
      <FontAwesome name="list" size={20} color={this.props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} />
    );
  }
}

export class BookmarkIcon extends React.Component {
  render() { 
    return (
      <FontAwesome name="bookmark-o" size={22} color={this.props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} />
    );
  }
}

export class MetricIcon extends React.Component {
  render() { 
    return (
      <FontAwesome name="calculator" size={19} color={this.props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} />
    );
  }
}
