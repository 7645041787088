import React, { useState, useEffect, useRef } from 'react';
import { Alert, Platform, StatusBar, StyleSheet, View, Text, Dimensions, Image, Linking } from 'react-native';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import AppNavigator from './navigation/AppNavigator';
import { Ionicons } from '@expo/vector-icons';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from 'i18n-js';
import ApiKeys from './constants/ApiKeys';
import * as SplashScreen from 'expo-splash-screen';
import MyContext from './components/MyContext';
import FlashMessage from 'react-native-flash-message';
import * as Localization from 'expo-localization';
import { ko, en } from './assets/data/i18n'
import { default as mapping } from './mapping.json';
import { createStrapiExpoToken, sendLog } from './components/StrapiInterface';
import * as Sentry from 'sentry-expo';

if (Platform.OS === 'android') {
  // When using EAS build, the statusbar in Android does not work the same as in Expo go.
  StatusBar.setBackgroundColor('black');
}

Sentry.init({
  dsn: 'https://c570e65a9fb64bdabbcb3c035700ae25@o1101063.ingest.sentry.io/6126720',
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

// Access any @sentry/react-native exports via:
//Sentry.Native.*

// Access any @sentry/browser exports via:
//Sentry.Browser.*

const { width: deviceWidth } = Dimensions.get('window');
const fontScale = deviceWidth > 480 ? 1.5 : 1;

const CONTAINER_MARGIN_TOP = (
  Platform.OS === 'ios'
    ? 20
    :
    StatusBar.currentHeight + 10);  // Just to add a bit more padding

const HORIZONTAL_MARGIN = 8; // left/right margin to screen edge

const client = new ApolloClient({
  uri: ApiKeys.StrapiConfig.URI,
  cache: new InMemoryCache()
});

i18n.fallbacks = true;
i18n.translations = { ko, en };
i18n.locale = Localization.locale;

export default function App(props) {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [ctxMyId, setCtxMyId] = useState(-1);
  const [ctxMyEmail, setCtxMyEmail] = useState('');
  const [ctxMySettingId, setCtxMySettingId] = useState(-1);
  const [ctxMyLocale, setCtxMyLocale] = useState('');
  const [ctxMyProvinceIdx, setCtxMyProvinceIdx] = useState(0);
  const [ctxMyLocalityIdx, setCtxMyLocalityIdx] = useState(0);
  const [ctxMyGpsLatitude, setCtxMyGpsLatitude] = useState(51.0447); // calgary center
  const [ctxMyGpsLongitude, setCtxMyGpsLongitude] = useState(-114.0719);
  const [ctxMyJwt, setCtxMyJwt] = useState('');
  const [ctxMyPushToken, setCtxMyPushToken] = useState(''); // expo notification token
  const [ctxMyNotiCheckTime, setCtxMyNotiCheckTime] = useState(0); // the latest time to check push notification messages
  const [ctxMyNotiArriveTime, setCtxMyNotiArriveTime] = useState(0); // the latest time to receive push notification messages
  const [ctxMyApolloQueryTime, setCtxMyApolloQueryTime] = useState(0); // the latest time to conduct Apollo Query
  const [ctxMyBannerRefreshTime, setCtxMyBannerRefreshTime] = useState(0); // the latest time to refresh banner

  const _updateMyContext = async ({ myId, myEmail, mySettingId, myLocale, myProvinceIdx, myLocalityIdx, myGpsLatitude, myGpsLongitude,
    myJwt, myPushToken, myNotiCheckTime, myNotiArriveTime, myApolloQueryTime, myBannerRefreshTime }) => {
    if (typeof myId !== 'undefined' && myId !== ctxMyId) {
      console.log("(_updateMyContext) ctxMyId: ", myId);
      setCtxMyId(myId);
      try {
        const res = await AsyncStorage.setItem('MyId', JSON.stringify(myId));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e01]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e01]');
      };
    }

    if (typeof myEmail !== 'undefined' && myEmail !== ctxMyEmail) {
      console.log("(_updateMyContext) ctxMyEmail: ", myEmail);
      setCtxMyEmail(myEmail);
      try {
        const res = await AsyncStorage.setItem('MyEmail', myEmail);
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e03]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e03]');
      };
    }

    if (typeof mySettingId !== 'undefined' && mySettingId !== ctxMySettingId) {
      console.log("(_updateMyContext) ctxMySettingId: ", mySettingId);
      setCtxMySettingId(mySettingId);
      try {
        const res = await AsyncStorage.setItem('MySettingId', JSON.stringify(mySettingId));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e05]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e05]');
      };
    }

    if (typeof myLocale !== 'undefined' && myLocale !== ctxMyLocale) {
      console.log("(_updateMyContext) ctxMyLocale: ", myLocale);
      i18n.locale = myLocale;
      setCtxMyLocale(myLocale);
      try {
        const res = AsyncStorage.setItem('Setting-Locale', myLocale);
        //Alert.alert(i18n.t('locale_change_msg_m'));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e07]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e07]');
      };
    };

    if (typeof myProvinceIdx !== 'undefined' && myProvinceIdx !== ctxMyProvinceIdx) {
      console.log("(_updateMyContext) ctxMyProvinceIdx: ", myProvinceIdx);
      setCtxMyProvinceIdx(myProvinceIdx);
      try {
        const res = AsyncStorage.setItem('Setting-ProvinceIdx', JSON.stringify(myProvinceIdx));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e09]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e09]');
      };
    };

    if (typeof myLocalityIdx !== 'undefined' && myLocalityIdx !== ctxMyLocalityIdx) {
      console.log("(_updateMyContext) ctxMyLocalityIdx: ", myLocalityIdx);
      setCtxMyLocalityIdx(myLocalityIdx);
      try {
        const res = AsyncStorage.setItem('Setting-LocalityIdx', JSON.stringify(myLocalityIdx));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e11]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e11]');
      };
    }

    if (typeof myGpsLatitude !== 'undefined' && myGpsLatitude !== ctxMyGpsLatitude) {
      console.log("(_updateMyContext) ctxMyGpsLatitude: ", myGpsLatitude);
      setCtxMyGpsLatitude(myGpsLatitude);
    };

    if (typeof myGpsLongitude !== 'undefined' && myGpsLongitude !== ctxMyGpsLongitude) {
      console.log("(_updateMyContext) ctxMyGpsLongitude: ", myGpsLongitude);
      setCtxMyGpsLongitude(myGpsLongitude);
    };

    if (typeof myJwt !== 'undefined' && myJwt !== ctxMyJwt) {
      console.log("(_updateMyContext) ctxMyJwt: ", myJwt);
      setCtxMyJwt(myJwt);
      try {
        const res = AsyncStorage.setItem('MyJwt', myJwt);
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e13]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e13]');
      };
    }

    if (typeof myPushToken !== 'undefined' && myPushToken !== ctxMyPushToken) {
      console.log("(_updateMyContext) ctxMyPushToken: ", myPushToken);
      setCtxMyPushToken(myPushToken);
      try {
        const res = AsyncStorage.setItem('MyPushToken', myPushToken);
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e15]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e15]');
      };
    }

    if (typeof myNotiCheckTime !== 'undefined' && myNotiCheckTime !== ctxMyNotiCheckTime) {
      console.log("(_updateMyContext) ctxMyNotiCheckTime: ", myNotiCheckTime);
      setCtxMyNotiCheckTime(myNotiCheckTime);
      try {
        const res = AsyncStorage.setItem('MyNotiCheckTime', JSON.stringify(myNotiCheckTime));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e17]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e17]');
      };
    }

    if (typeof myNotiArriveTime !== 'undefined' && myNotiArriveTime !== ctxMyNotiArriveTime) {
      console.log("(_updateMyContext) ctxMyNotiArriveTime: ", myNotiArriveTime);
      setCtxMyNotiArriveTime(myNotiArriveTime);
      try {
        const res = AsyncStorage.setItem('MyNotiArriveTime', JSON.stringify(myNotiArriveTime));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e17]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e17]');
      };
    }

    if (typeof myApolloQueryTime !== 'undefined' && myApolloQueryTime !== ctxMyApolloQueryTime) {
      console.log("(_updateMyContext) ctxMyApolloQueryTime: ", myApolloQueryTime);
      setCtxMyApolloQueryTime(myApolloQueryTime);
      try {
        const res = AsyncStorage.setItem('MyApolloQueryTime', JSON.stringify(myApolloQueryTime));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e19]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e19]');
      };
    }

    if (typeof myBannerRefreshTime !== 'undefined' && myBannerRefreshTime !== ctxMyBannerRefreshTime) {
      console.log("(_updateMyContext) ctxMyBannerRefreshTime: ", myBannerRefreshTime);
      setCtxMyBannerRefreshTime(myBannerRefreshTime);
      try {
        const res = AsyncStorage.setItem('MyBannerRefreshTime', JSON.stringify(myBannerRefreshTime));
      } catch (err) {
        sendLog(i18n.t('usercontext_update_err') + '[e21]' + JSON.stringify(err));
        Alert.alert(i18n.t('usercontext_update_err') + '[e21]');
      };
    }
  }

  const _initializeMyContext = async () => {
    // get all the AsyncStorage data
    var userId = 0;
    try {
      const res = await AsyncStorage.getItem('MyId');
      console.log('(App.js) result of AsyncStorage(MyId) getItem: ' + JSON.parse(res));
      if (res != null) {
        setCtxMyId(JSON.parse(res));
        userId = JSON.parse(res);
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get MyId from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MyEmail');
      console.log('(App.js) result of AsyncStorage(MyEmail) getItem: ' + res);
      if (res != null) {
        setCtxMyEmail(res);
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get MyEmail from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MyJwt');
      console.log('(App.js) result of AsyncStorage(MyJwt) getItem: ' + res);
      if (res != null) {
        setCtxMyJwt(res);
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get JWT from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MyPushToken');
      console.log('(App.js) result of AsyncStorage(MyPushToken) getItem: ' + res);
      if (res != null) {
        setCtxMyPushToken(res);
        await createStrapiExpoToken({ myPushToken: res, myId: userId });
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get PushToken from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MyNotiCheckTime');
      console.log('(App.js) result of AsyncStorage(MyNotiCheckTime) getItem: ' + JSON.parse(res));
      if (res != null) {
        setCtxMyNotiCheckTime(JSON.parse(res));
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get NotiCheckTime from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MyNotiArriveTime');
      console.log('(App.js) result of AsyncStorage(MyNotiArriveTime) getItem: ' + JSON.parse(res));
      if (res != null) {
        setCtxMyNotiArriveTime(JSON.parse(res));
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get NotiArriveTime from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MyApolloQueryTime');
      console.log('(App.js) result of AsyncStorage(MyApolloQueryTime) getItem: ' + JSON.parse(res));
      if (res != null) {
        setCtxMyApolloQueryTime(JSON.parse(res));
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get ApolloQueryTime from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MyBannerRefreshTime');
      console.log('(App.js) result of AsyncStorage(MyBannerRefreshTime) getItem: ' + JSON.parse(res));
      if (res != null) {
        setCtxMyBannerRefreshTime(JSON.parse(res));
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get BannerRefreshTime from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('MySettingId');
      console.log('(App.js) result of AsyncStorage(MySettingId) getItem: ' + JSON.parse(res));
      if (res != null) {
        setCtxMySettingId(JSON.parse(res));
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get MySettingId from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('Setting-Locale');
      console.log('(App.js) result of AsyncStorage(Setting-Locale) getItem: ' + res);
      if (res != null) {
        i18n.locale = res;
        setCtxMyLocale(res);
      } else if (Platform.OS === 'web') { // set default values for locale and area in web browsers.
        const myLocale = Localization.getLocales()[0].languageCode; console.log("[app.js] get current locale in web browser:", myLocale);

        _updateMyContext({ myLocale: myLocale });
        _updateMyContext({ myProvinceIdx: 0 });
        _updateMyContext({ myLocalityIdx: 2 });
      }
      console.log('(App.js) Set Locale to : ' + i18n.locale);
    } catch (error) {
      console.log('(App.js) Error: Fail to get Locale from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('Setting-ProvinceIdx');
      console.log('(App.js) result of AsyncStorage(Setting-ProvinceIdx) getItem: ' + res);
      if (res != null) {
        setCtxMyProvinceIdx(JSON.parse(res));
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get Setting-ProvinceIdx from Asyncstorage.' + error);
    }

    try {
      const res = await AsyncStorage.getItem('Setting-LocalityIdx');
      console.log('(App.js) result of AsyncStorage(Setting-LocalityIdx) getItem: ' + res);
      if (res != null) {
        setCtxMyLocalityIdx(JSON.parse(res));
      }
    } catch (error) {
      console.log('(App.js) Error: Fail to get Setting-LocalityIdx from Asyncstorage.' + error);
    }
  }

  useEffect(() => {
    const _loadResourcesAsync = async () => {
      try {
        SplashScreen.preventAutoHideAsync();

        await _initializeMyContext();
        /*
        Asset.loadAsync([
          require('./assets/images/cakory-splashscreen_1200.png'),
        ]),
        */
        await Font.loadAsync({
          // This is the font that we are using for our tab bar
          ...Ionicons.font,
          // We include SpaceMono because we use it in HomeScreen.js. Feel free
          // to remove this if you are not using it in your app
          'CustomHangul': require('./assets/fonts/GowunDodum-Regular.ttf'),
          'NanumBarunGothicBold': require('./assets/fonts/NanumBarunGothicBold.ttf'),
        });

      } catch (error) {
        // In this case, you might want to report the error to your error
        // reporting service, for example Sentry
        console.warn(error);

      } finally {
        setIsLoadingComplete(true);
        SplashScreen.hideAsync()
      }
    };

    _loadResourcesAsync();
  }, []);


  // Splash screen could not hide correctly when the next main screen has a modal component, in which case
  // the hideAsync function of this Splash screen generates error because the screen control is already
  // transferred to the Modal component.

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <ApolloProvider client={client}>
        <MyContext.Provider
          value={{
            myId: ctxMyId,
            myEmail: ctxMyEmail,
            mySettingId: ctxMySettingId,
            myLocale: ctxMyLocale,
            myProvinceIdx: ctxMyProvinceIdx,
            myLocalityIdx: ctxMyLocalityIdx,
            myJwt: ctxMyJwt,
            myPushToken: ctxMyPushToken,
            myGpsLatitude: ctxMyGpsLatitude,
            myGpsLongitude: ctxMyGpsLongitude,
            myNotiCheckTime: ctxMyNotiCheckTime,
            myNotiArriveTime: ctxMyNotiArriveTime,
            myApolloQueryTime: ctxMyApolloQueryTime,
            myBannerRefreshTime: ctxMyBannerRefreshTime,
            updateMyContext: _updateMyContext,
          }}
        >
          <View style={{ flex: 1, backgroundColor: '#e5deca' }}>
            <View style={styles.container}>
              {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
              <AppNavigator />
              <FlashMessage position="top" />
            </View>
          </View>
        </MyContext.Provider>
      </ApolloProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto'
  },

  popupContainer: {
    position: 'absolute',
    width: deviceWidth - (HORIZONTAL_MARGIN * 2),
    left: HORIZONTAL_MARGIN,
    right: HORIZONTAL_MARGIN,
    top: CONTAINER_MARGIN_TOP,
  },

  popupContentContainer: {
    backgroundColor: 'white',  // TEMP
    borderRadius: 12,
    minHeight: 86,
    // === Shadows ===
    // Android
    elevation: 2,
    // iOS
    shadowColor: '#000000',
    shadowOpacity: 0.5,
    shadowRadius: 3,
    shadowOffset: {
      height: 1,
      width: 0,
    },
  },

  popupHeaderContainer: {
    height: 32,
    backgroundColor: '#2f95dc',  // TEMP
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingVertical: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIconContainer: {
    height: 20,
    width: 20,
    marginLeft: 12,
    marginRight: 8,
    borderRadius: 4,
  },
  headerIcon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  headerTextContainer: {
    flex: 1,
  },
  headerText: {
    fontSize: 13,
    color: 'white',
    lineHeight: 20,
  },
  headerTimeContainer: {
    marginHorizontal: 16,
  },
  headerTime: {
    fontSize: 12,
    color: 'white',
    lineHeight: 14,
  },
  contentContainer: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 10,
    paddingHorizontal: 16,
  },
  contentTitleContainer: {
  },
  contentTitle: {
    fontSize: 15,
    lineHeight: 18,
    color: 'black',
  },
  contentTextContainer: {
  },
  contentText: {
    fontSize: 12,
    lineHeight: 14,
    color: '#808080',
    marginTop: 5,
  },
});
