import React from 'react';
import { View, StyleSheet, StatusBar, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import i18n from 'i18n-js';
import MainTabNavigator from './MainTabNavigator';
import LoginStack from './../screens/auth/LoginScreen';
import MyContext from '../components/MyContext';
import { SettingsScreen } from '../screens/MenuScreen';
import { MyButton } from '../components/MyComponents';

const Stack = createStackNavigator();

export default class AppNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      askedFirstSetup: false,
    };
  }
  static contextType = MyContext;

  render() {
    if (this.context.myLocale || this.state.askedFirstSetup) {
      return (
        <NavigationContainer>
          <Stack.Navigator
            initialRouteName='Main'
            screenOptions={{
              headerShown: false
            }}
          >
            <Stack.Screen name='LoginStack' component={LoginStack} />
            <Stack.Screen name='Main' component={MainTabNavigator} />
          </Stack.Navigator>
        </NavigationContainer>
      )
    } else {
      return (
        <View style={styles.container}>
          <StatusBar barStyle="default" />

          <View style={{ marginHorizontal: 35 }}>

              <Text style={{ fontSize: 30, textAlign: 'center', fontFamily: 'CustomHangul', lineHeight: 40}}>{i18n.t('welcome', { locale: 'en' })}</Text>
              <Text style={{ fontSize: 18, textAlign: 'center', fontFamily: 'CustomHangul' }}>{i18n.t('please_setup_for_the_first', { locale: 'en' })}</Text>

              <View style={{ marginTop: 20, marginBottom: 40 }}>
                <Text style={{ fontSize: 30, textAlign: 'center', fontFamily: 'CustomHangul', lineHeight: 40 }}>{i18n.t('welcome', { locale: 'ko' })}</Text>
                <Text style={{ fontSize: 18, textAlign: 'center', fontFamily: 'CustomHangul', marginVertical: 0 }}>{i18n.t('please_setup_for_the_first', { locale: 'ko' })}</Text>
              </View>

              <MyButton onPress={() => this.setState({ askedFirstSetup: true })}>
                <Text style={{ fontSize: 18, alignSelf: 'center', fontFamily: 'CustomHangul', color: 'white' }}>{i18n.t('i_got_it', { locale: 'ko' })} / {i18n.t('i_got_it', { locale: 'en' })}</Text>
              </MyButton>

          </View>

        </View>
      )
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
