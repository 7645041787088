import React, { useState, useEffect } from 'react';
import { StyleSheet, Pressable, Text, View, Dimensions } from 'react-native';
 
const { width, height } = Dimensions.get('window');
const fontScale = width > 480 ? 1.5 : 1;

export function MyButton(props) {
        return (
            <Pressable
                style={[styles.buttonContainer, props.style]}
                onPress={props.onPress}
                disabled={props.disabled}
            >
                    {props.children}
            </Pressable>
        );
}

MyButton.defaultProps = {
    scale: 1,
    disabled: false
}

const styles = StyleSheet.create({
    buttonContainer: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
        backgroundColor: '#2f95dc',
    },
});