export const en = {
  mt_search: "Search",
  mt_list: "Directory",
  mt_bookmark: "Bookmarks",
  mt_unit_conversion: "Unit Conversion",
  unit_conversion: "Unit Conversion",
  h_coupons_t: "Coupon - Editor' Choice",
  c_all_l: 'All',
  c_food_l: 'Food',
  c_service_l: 'Service',
  c_ent_l: 'Entertainment',
  c_tour_l: 'Tour',
  c_shopping_l: 'Shopping',
  logout_m: 'Sign Out',
  login_m: 'Sign In',
  change_pwd_m: 'Change Password',
  setting_m: 'Settings',
  user_info_m: 'User Information',
  id_m: 'ID',
  locale_m: 'Language',
  privacy_policy_m: 'Privacy policy',
  contact_us_m: 'Contact us',
  app_ver_m: 'App version',
  confirm_m: 'OK',
  done_m: 'Done',
  cancel_m: 'Cancel',
  create_account_m: 'Create an account',
  forget_pwd_q: 'Forget your password?',
  login_err_guide_m: 'If you experience any problem in sign-in, please contact us via email. We will fix it as soon as possible.',
  create_account_m: 'Create an Account',
  success_in_create_account_m: 'Success in creating an account.',
  go_to_login_page_m: 'Go to the sign-in page.',
  reset_pwd_m: 'Reset Password',
  reset_pwd_guide_m: "Please press 'OK' button after entering the email address used for your account. A website link to change your password will be shortly sent to the email address.",
  change_pwd_guide_m: "Please press 'OK' button. A website link to change your password will be shortly sent to your email address.",
  error_unknown_id_m: 'Error: this email was not registered.',
  error_login_fail_m: 'Failed in sign-in. Please contact us if you experience this error again.: ',
  error_mismatch_pwd_m: 'Oops! Two passwords are different.',
  error_used_email_m: 'Oops! This email is already used.',
  error_create_account_m: 'Oops! Cannot make a new account. Please contact us.',
  noti_create_account_m: 'A confirmation email has been sent to the email address you entered. Plase check your email box.',
  noti_change_pwd_m: 'A notification email has been sent to your email address. Plase check your email box.',
  error_change_pwd_m: 'Oops! Cannot change the password. Please contact us.',
  locale_change_msg_m: 'If there is no change, please restart this app.',
  error_change_pwd_not_logged_m: 'You can change password only while signed in.',
  area_for_search: 'Area',
  fields_for_search: 'Fields',
  name: 'Name',
  address: 'Address',
  phonenum: 'Phone',
  category: 'Category',
  mapview: 'Map',
  among_categories: 'In Categories ...',
  among_tags: 'In Tags ...',
  nothing_searched: 'Nothing found. Please check the search conditions.',
  among_name: 'In Names ...',
  among_address: 'In Addresses ...',
  only_one_word: ' ',
  load_more: 'Load more',
  upto_now1: 'Total',
  upto_now2: 'Up to now',
  setting_locale_err: "Error in Locale setting.",
  geolocation_err: 'Cannot get Geolocation information. Please check your device.',
  search_err: "Error in Searching.",
  banner_err: "Error in fetching banners.",
  internet_err: "Please check the Internet connection.",
  geo_yourlocation: "Current location",
  point: 'Point value',
  not_logged: 'Not signed in.',
  clear_setting: 'Reset settings',
  ok_clear_setting: 'Settings are cleared successfully. Please restart this app.',
  setting_expotoken_err: "Cannot access the push notification setting.",
  registering_expopush_err: 'Cannot register for push notification.',
  create_usersetting_err: "Cannot create the user settings.",
  fetch_usersetting_err: 'Cannot fetch the user settings data.',
  update_usersetting_err: 'Cannot update the user settings data.',
  usersetting_conflict: 'The user settings data have some conflicts.',
  please_login_again: 'Please sign in again.',
  push_notification: 'Push notification',
  change_password_err: 'Cannot change password.',
  me_query_err: 'User identity is not valid.',
  share_place: 'Sharing',
  share_cakory_footer: 'Sent from CAKORY mobile app(cakory.com)',
  refer_to_the_link: 'Please click the following link for detailed information.',
  please_login: 'Please sign in for this service.',
  bookmarked_places: 'Places',
  clothes_c: 'Clothes',
  food_c: 'Food',
  house_c: 'House',
  car_c: 'Car',
  institution_c: 'Institution',
  playing_c: 'Playing',
  merchandise_c: 'Merchandise',
  professional_service_c: 'Professional service',
  life_edu_service_c: 'Life/Edu service',
  company_c: 'Company',
  hanbok: 'Korean traditional clothes',
  restaurant: 'Restaurant',
  cafe: 'Cafe/Sandwitch',
  ricecake: 'Rice cake',
  bakery: 'Bakery',
  grocery: 'Grocery',
  butcher_shop: 'Butcher shop',
  food_wholesale: 'Food wholesale',
  catering: 'Side dishes/Catering/Meal kits',
  accommodation: 'Accommodation',
  realtor: 'Realtor',
  plumbing: 'Plumbing/Heating',
  electric: 'Electrical service',
  roofing: 'Roofing/Siding/Tiling',
  kitchen: 'Kitchen/Cabinet/Garage',
  light: 'Light/Paint',
  floor: 'Floor/Blind',
  interior: 'House repair/Interior',
  security: 'Security/Communication',
  pest_control: 'Pest control/Detox',
  dealership: 'Dealership',
  repairshop: 'Car repair shop',
  carwash: 'Car wash/Detailing',
  tire: 'Parts/Tire',
  rv: 'RV',
  government: 'Government',
  religion: 'Religion',
  volunteer: 'Volunteer',
  media: 'Press/Media',
  korean_community: 'Korean community',
  education: 'Education',
  billiard: 'Billiard/Bowling',
  karaoke: 'Karaoke',
  travel_agent: 'Travel Agent',
  bar: 'Bar',
  medical_equip: 'Medical equipment',
  supplement: 'Health supplement',
  jewelry: 'Jewelry',
  cosmetic: 'Cosmetics',
  bag: 'Bag',
  ice_cream: 'Ice cream',
  bidet: 'Bidet',
  water_filter: 'Water purifier',
  dollar_shop: 'Dollar shop',
  hearing_aid: 'Hearing aid',
  liquor: 'Liquor',
  tobacco: 'Tobacco',
  farm: 'Farm',
  restaurant_equip: 'Restaurant equipments/Packing materials',
  electronics: 'Electronics/Computer',
  printer_ink: 'Staionary/Printer ink',
  furniture: 'Furniture',
  bookstore: 'Bookstore',
  flower: 'Flower',
  lawyer: 'Lawyer',
  accountant: 'Accountant',
  immigration: 'Immigration/Oversea studying',
  translator: 'Translator/Notary',
  construction: 'House/Construction',
  finance: 'Finance/Insurance',
  realty: 'Realty Buy/Sell',
  medical_service: 'Hospital/Medical services',
  design_ad: 'Design/Ad/Marketing',
  print: 'Print/Sign/Frame',
  sw_dev: 'SW/Web Development',
  av_solution: 'Audio/Video Solution',
  pos_network: 'POS/ATM/Network',
  realty_mng: 'Property management',
  debt: 'Debt consulting',
  fire_equip: 'Fire equipment',
  piano_tuning: 'Musical inst. tuning',
  daycare: 'Daycare/Childcare',
  tutoring: 'Tutoring/Private lecturing',
  beauty: 'Beauty/Body',
  pet_grooming: 'Pet grooming',
  laundry: 'Laundry/Alteration',
  matching: 'Matching service',
  event: 'Event services',
  moving: 'House/Package moving',
  internet: 'Internet/Mobile',
  elec_repair: 'Elec/Computer repair',
  landscape: 'Landscape',
  house_clean: 'House/Carpet cleaning',
  delivery: 'Parcel delivery',
  exercise: 'Gym/Exercise',
  ride: 'Ride/Taxi',
  housekeeping: 'Housekeeping/Post-natal care',
  internet_cafe: 'Internet cafe',
  locksmith: 'Locksmith',
  shoes_repair: 'Shoes repair',
  wedding: 'Wedding',
  photo_studio: 'Photo/Studio',
  sajoo: 'Fortune teller',
  manufacturing: 'Manufacturing',
  dental_lab: 'Dental lab',
  elec_part: 'Electronic parts',
  trading: 'Trading',
  korean_food: 'Korean food',
  japanese_food: 'Japanese food',
  chinese_food: 'Chinese food',
  vietnamese_food: 'Vietnamese food',
  chicken: 'Chicken',
  street_food: 'Street food',
  pizza: 'Pizza',
  pork_cutlet: 'Donkatseu',
  western_food: 'Western food',
  allyoucaneat: 'All you can eat',
  light_food: 'Light food/snack',
  architect: 'Architect',
  renovation: 'Contractor/Renovation',
  mortgage: 'Mortgage',
  bank: 'Bank',
  loan: 'Loan',
  insurance: 'Insurance/Financial Planning',
  home_inspector: 'Home inspector',
  appraiser: 'Appraiser',
  oriental_clinic: 'Oriental clinic',
  hospital: 'Physician/Hospital',
  pharmacy: 'Pharmacy',
  dentist: 'Dentist/Denture',
  eyeglasses: 'Optometrics/Eyeglasses',
  nursing: 'Nurse',
  pet_clinic: 'Pet clinic',
  martial_lesson: 'Martial art',
  tutoring: 'Tutoring/Education consulting',
  driving_lesson: 'Driving lesson',
  art_lesson: 'Art/Drawing',
  music_lesson: 'Music lesson',
  language_lesson: 'Language lesson',
  sport_lesson: 'Sports lesson',
  sw_lesson: 'SW Coding',
  barister_lesson: 'Barister/Coffee',
  korean_culture_lesson: 'Korean culture',
  hair_salon: 'Hair/Skin/Nail',
  spa: 'Spa/Massage',
  tattoo: 'Tatoo',
  yoga: 'Yoga/Fitness',
  dance: 'Dance lesson',
  golf: 'Golf',
  'kids_clothes': 'Children\'s apparel',
  'regular_clothes': 'General apparel',
  'fashion_clothes': 'Fashion/Boutique',
  'mens_clothes': 'Men\'s suits',
  'fur_leather': 'Fur/leather',
  'underwear_socks': 'Underwear/Socks',
  'hats': 'Hats/Misc',
  food_delivery: 'Food delivery',
  window: 'Window/Glass/Mirror',
  bodyshop: 'Car body shop',
  car_rent: 'Car rent',
  towing: 'Towing',
  'sports_leisure': 'Sports/Leisure',
  'bedding': 'Bedding',
  'giftshop': 'Shopping/Giftshop',
  'pet_product': 'Pet products',
  'musical_instrument': 'Musical instrument',
  'beauty_material': 'Beauty materials',
  'funeral': 'Funeral/Cemetery',
  'psychology_counselling': 'Psychology counselling',
  'health_counselling': 'Health Counselling',
  'nursing_home': 'Nursing home',
  'wig': 'Wig',
  'machine_manufacture': 'Machine manufacturing',
  'home_staging': 'Home staging/decoration',
  disaster: 'Damage restoring',
  physiotherapy: 'Chiropractic/Physiotherapy',
  'video_rent': 'Video rental',
  'embroidery': 'Embroidery',
  'trophy': 'Trophy',
  'calligraphy': 'Calligraphy',
  'sewing': 'Sewing',
  'craftwork': 'Craftwork',
  'winery': 'Winery/Brewery',
  'beekeeper': 'Beekeeper',
  'branch_office': 'Branch office',
  'private_lesson': 'Private lesson',
  'danjeon': 'Zen',
  'kitchenware': 'Kitchenware',
  'storage': 'Storage',
  'junk': 'Junk removal',
  'proxy_buy': 'Purchasing agent',
  'sleep_training': 'Sleeping training',
  'debate': 'Debating/Eloquence',
  'fishing': 'Horse riding/Fishing',
  'site': 'Historic sites',
  'dessert': 'Dessert',
  'skewer': 'Skewer',
  'cooking_lesson': 'Cooking lesson',
  'jjajangmyeon': 'Jjajangmyeon', '짜장면': 'Jjajangmyeon',
  'jjamppong': 'Jjamppong', '짬뽕': 'Jjamppong',
  'yusanseul': 'Yusanseul', '유산슬': 'Yusanseul',
  'parish': 'Catholic church', '성당': 'Catholic church',
  'church': 'Church', '교회': 'Church',
  'temple': 'Temple', '절': 'Temple',
  'gopchang': 'Gopchang', '곱창': 'Gopchang',
  'bulgogi': 'Bulgogi', '불고기': 'Bulgogi',
  'galbi': 'Galbi', '갈비': 'Galbi',
  'bibimbap': 'Bibimbap', '비빔밥': 'Bibimbap',
  'japchae': 'Japchae', '잡채': 'Japchae',
  'jjukkumi': 'Jjukkumi', '쭈꾸미': 'Jjukkumi',
  'naengmyeon': 'Naengmyeon', '냉면': 'Naengmyeon',
  'samgyetang': 'Samgyetang', '삼계탕': 'Samgyetang',
  'budaejjigae': 'Budae jjigae (a.k.a. Army stew)', '부대찌개': 'Budae jjigae (a.k.a. Army stew)',
  'samgyeopsal': 'Samgyeopsal', '삼겹살': 'Samgyeopsal',
  'ganjang gejang': 'Ganjang gejang', '간장게장': 'Ganjang gejang',
  'yukhoe': 'Yukhoe', '육회': 'Yukhoe',
  'juk': 'Juk', '죽': 'Juk',
  'ssambap': 'Ssambap', '쌈밥': 'Ssambap',
  'ramyeon': 'Ramyeon', '라면': 'Ramyeon',
  'tteokbokki': 'Tteokbokki', '떡볶이': 'Tteokbokki',
  'hotdog': 'Hotdog', '핫도그': 'Hotdog',
  'cupbap': 'Cupbap', '컵밥': 'Cupbap',
  'poke': 'Poke', '포케': 'Poke',
  'sundae': 'Sundae', '순대': 'Sundae',
  'hanjeongsik': 'Hanjeongsik', '한정식/코스요리': 'Hanjeongsik',
  'bingsoo': 'Bingsoo', '빙수': 'Bingsoo',
  'sushi': 'Sushi', '초밥': 'Sushi',
  'hoedeopbap': 'Hoedeopbap', '회덮밥': 'Hoedeopbap',
  'foodcourt': 'Food court', '푸드코트': 'Food court',
  'vegetarian': 'Vegetarian', '배지테리안': 'Vegetarian',
  'makgeolli': 'Makgeolli', '막걸리': 'Makgeolli',
  'soju': 'Soju', '소주': 'Soju',
  'gomtang': 'Gomtang', '곰탕': 'Gomtang',
  'kkochi': 'Kkochi', '꼬치': 'Kkochi',
  'sundubujjigae': 'Sundubu jjigae', '순두부찌개': 'Sundubu jjigae',
  'kimchijjigae': 'Kimchi jjigae', '김치찌개': 'Kimchi jjigae',
  'buchimgae': 'Buchimgae', '부침개/전': 'Buchimgae',
  'nurungjitang': 'Nurungjitang', '누룽지탕': 'Nurungjitang',
  'kkanpunggi': 'Kkanpunggi', '깐풍기': 'Kkanpunggi',
  'palbochae': 'Palbochae', '팔보채': 'Palbochae',
  'yangjangpi': 'Yangjangpi', '양장피': 'Yangjangpi',
  'ohyangjangyuk': 'Ohyangjangyuk', '오향장육': 'Ohyangjangyuk',
  'tangsuyuk': 'Tangsuyuk', '탕수육': 'Tangsuyuk',
  'roll': 'Roll', '롤': 'Roll',
  'japaneseramen': 'Japanese ramen', '일본 라면': 'Japanese ramen',
  'hoe': 'Hoe', '회': 'Hoe',
  'gimbap': 'Gimbap', '김밥': 'Gimbap',
  'donkatseu': 'Donkatseu', '돈까스': 'Donkatseu',
  'bubbletea': 'Bubble tea', '버블티': 'Bubble tea',
  'friedchicken': 'Fried chicken', '후라이드치킨': 'Fried chicken',
  'buldak': 'Buldak', '불닭': 'Buldak',
  'odeng': 'Odeng', '오뎅': 'Odeng',
  'bossam': 'Bossam', '보쌈': 'Bossam',
  'jokbal': 'Jokbal', '족발': 'Jokbal',
  'janchiguksu': 'Janchiguksu', '잔치국수': 'Janchiguksu',
  'gukbap': 'Gukbap', '국밥': 'Gukbap',
  'dakgangjung': 'Dakgangjung', '닭강정': 'Dakgangjung',
  'yangnyeomchicken': 'Yangnyeom chicken', '양념치킨': 'Yangnyeom chicken',
  'jeyuk': 'Jeyuk', '제육': 'Jeyuk',
  'tteokguk': 'Tteokguk', '떡국/만두국': 'Tteokguk',
  'gamjatang': 'Gamjatang', '감자탕': 'Gamjatang',
  'eel': 'Eel', '장어': 'Eel',
  'dakttongjip': 'Dakttongjip', '닭똥집': 'Dakttongjip',
  'altang': 'Altang', '알탕': 'Altang',
  'udon': 'Udon', '우동': 'Udon',
  'chineseudon': 'Chinese udon', '중국식 우동': 'Chinese udon',
  'seolleongtang': 'Seolleongtang', '설렁탕': 'Seolleongtang',
  'doenjangjjigae': 'Doenjang jjigae', '된장찌개': 'Doenjang jjigae',
  'kimchibokkeumbap': 'Kimchi bokkeumbap', '김치볶음밥': 'Kimchi bokkeumbap',
  'dakgalbi': 'Dakgalbi', '닭갈비': 'Dakgalbi',
  'jjimdak': 'Jjimdak', '찜닭': 'Jjimdak',
  'sikhye': 'Sikhye', '식혜': 'Sikhye',
  'gunmandu': 'Gun mandu', '군만두': 'Gun mandu',
  'bokkeumbap': 'Bokkeumbap', '중국식 볶음밥': 'Bokkeumbap',
  'rajogi': 'Rajogi', '라조기': 'Rajogi',
  'yukgaejang': 'Yukgaejang', '육개장': 'Yukgaejang',
  'haejangguk': 'Haejangguk', '해장국': 'Haejangguk',
  'kalguksu': 'Kalguksu', '칼국수': 'Kalguksu',
  'kongguksu': 'Kongguksu', '콩국수': 'Kongguksu',
  'origogi': 'Ori gogi', '오리고기': 'Ori gogi',
  'saengseongui': 'Saengseon gui', '생선구이': 'Saengseon gui',
  'hongeo': 'Hongeo', '홍어': 'Hongeo',
  'maeuntang': 'Maeuntang', '매운탕': 'Maeuntang',
  'retreat': 'Retreat', '기도원': 'Retreat',
  'dashcam': 'Dash cam', '블랙박스': 'Dash cam',
  'kimchi_products': 'Kimchi (for sale)', '김치 (판매용)': 'Kimchi (for sale)',
  'hotteok': 'Hotteok', '호떡': 'Hotteok',
  'bungeoppang': 'Bungeo ppang', '붕어빵': 'Bungeo ppang',
  'hodugwaja': 'Hodu gwaja', '호도과자': 'Hodu gwaja',
  'croffle': 'Croffle', '크로플': 'Croffle',
  'mf_nabe': 'Mille-Feuille Nabe', '밀푀유나베': 'Mille-Feuille Nabe',
  /* for reverse conversion: start */
  '의': 'Clothes',
  '식': 'Food',
  '주': 'House',
  '자동차': 'Car',
  '기관/단체': 'Institution',
  '놀거리': 'Playing',
  '상품구매/상점': 'Merchandise',
  '전문/자격 서비스': 'Professional service',
  '생활/교육 서비스': 'Life/Edu service',
  '기업체': 'Company',
  '한복': 'Korean traditional clothes',
  '식당': 'Restaurant',
  '카페/샌드위치': 'Cafe/Sandwitch',
  '떡집': 'Rice cake',
  '베이커리': 'Bakery',
  '식품점/시장': 'Grocery',
  '정육점': 'Butcher shop',
  '식자재도매': 'Food wholesale',
  '반찬/케이터링/밀키트': 'Side dishes/Catering/Meal kits',
  '숙박/홈스테이': 'Accommodation',
  '부동산중개인': 'Realtor',
  '배관/난방': 'Plumbing/Heating',
  '전기/냉동': 'Electrical service',
  '지붕/외벽/타일': 'Roofing/Siding/Tiling',
  '부엌/캐비넷/가라지': 'Kitchen/Cabinet/Garage',
  '조명/페인트': 'Light/Paint',
  '마루/블라인드': 'Floor/Blind',
  '집수리/인테리어': 'House repair/Interior',
  '보안/통신': 'Security/Communication',
  '방역/독성제거': 'Pest control/Detox',
  '자동차판매': 'Dealership',
  '자동차정비': 'Car repair shop',
  '세차/디테일링': 'Car wash/Detailing',
  '부품/타이어': 'Parts/Tire',
  '캠핑카': 'RV',
  '정부기관': 'Government',
  '종교단체/시설': 'Religion',
  '봉사단체': 'Volunteer',
  '언론/방송': 'Press/Media',
  '교민단체/동호회': 'Korean community',
  '교육기관/단체': 'Education',
  '당구/볼링': 'Billiard/Bowling',
  '노래방': 'Karaoke',
  '여행사/항공사': 'Travel Agent',
  '주점': 'Bar',
  '의료기기': 'Medical equipment',
  '건강식품/보조제': 'Health supplement',
  '보석상/시계': 'Jewelry',
  '화장품': 'Cosmetics',
  '가방': 'Bag',
  '아이스크림': 'Ice cream',
  '비데': 'Bidet',
  '정수기': 'Water purifier',
  '천원샵': 'Dollar shop',
  '보청기': 'Hearing aid',
  '술': 'Liquor',
  '담배': 'Tobacco',
  '농장': 'Farm',
  '식당용기구/포장재': 'Restaurant equipments/Packing materials',
  '전자제품/컴퓨터': 'Electronics/Computer',
  '사무용품/프린터잉크': 'Stationary/Printer ink',
  '가구': 'Furniture',
  '서점': 'Bookstore',
  '꽃집/꽃꽂이': 'Flower',
  '변호사/법률서비스': 'Lawyer',
  '회계사/세무서비스': 'Accountant',
  '이민/유학원': 'Immigration/Oversea studying',
  '번역/통역/공증': 'Translator/Notary',
  '주택/건축관련': 'House/Construction',
  '금융/보험': 'Finance/Insurance',
  '부동산/거래관련': 'Realty Buy/Sell',
  '의사/의료서비스': 'Hospital/Medical services',
  '디자인/광고/마케팅': 'Design/Ad/Marketing',
  '인쇄/간판/액자': 'Print/Sign/Frame',
  'SW개발/웹개발': 'SW/Web Development',
  '음향/영상솔루션': 'Audio/Video Solution',
  'POS/ATM/네트워크설치': 'POS/ATM/Network',
  '부동산관리/상담': 'Property management',
  '채무상담/채무조정': 'Debt consulting',
  '소방시설': 'Fire equipment',
  '악기수리/조율': 'Musical inst. tuning',
  '데이케어/유치원': 'Daycare/Childcare',
  '학원/교습': 'Tutoring/Private lecturing',
  '뷰티/몸가꾸기': 'Beauty/Body',
  '애완동물관리': 'Pet grooming',
  '세탁/수선': 'Laundry/Alteration',
  '결혼정보': 'Matching service',
  '이벤트/행사': 'Event services',
  '이삿짐/운송': 'House/Package moving',
  '인터넷/모바일': 'Internet/Mobile',
  '전자제품/컴퓨터수리': 'Elec/Computer repair',
  '조경': 'Landscape',
  '청소/카펫클리닝': 'House/Carpet cleaning',
  '택배': 'Parcel delivery',
  '운동/체력단련': 'Gym/Exercise',
  '라이드/택시': 'Ride/Taxi',
  '산후조리/가사도우미': 'Housekeeping/Post-natal care',
  '피씨방': 'Internet cafe',
  '열쇠/도어락': 'Locksmith',
  '신발수선': 'Shoes repair',
  '웨딩': 'Wedding',
  '사진/스튜디오': 'Photo/Studio',
  '사주/운세': 'Fortune teller',
  '제조업': 'Manufacturing',
  '치기공소': 'Dental lab',
  '전자부품': 'Electronic parts',
  '무역업/수입상': 'Trading',
  '한식': 'Korean food',
  '일식': 'Japanese food',
  '중식': 'Chinese food',
  '베트남음식': 'Vietnamese food',
  '치킨': 'Chicken',
  '길거리음식': 'Street food',
  '피자': 'Pizza',
  '돈까스': 'Donkatseu',
  '서양식': 'Western food',
  '뷔페/올유캔잇': 'All you can eat',
  '분식': 'Light food/snack',
  '건축사/건축설계': 'Architect',
  '건축시공/리노베이션': 'Contractor/Renovation',
  '모기지/융자': 'Mortgage',
  '은행': 'Bank',
  '대출': 'Loan',
  '보험/자산관리/투자금융': 'Insurance/Financial Planning',
  '홈인스펙션': 'Home inspector',
  '부동산감정평가사': 'Appraiser',
  '한의원': 'Oriental clinic',
  '의사/병원': 'Physician/Hospital',
  '약국': 'Pharmacy',
  '치과/틀니': 'Dentist/Denture',
  '안경점/검안사': 'Optometrics/Eyeglasses',
  '간호/간병인': 'Nurse',
  '동물병원': 'Pet clinic',
  '무술도장': 'Martial art',
  '보습/진학상담': 'Tutoring/Education consulting',
  '운전교습': 'Driving lesson',
  '미술교습/화실': 'Art/Drawing',
  '음악교습': 'Music lesson',
  '어학': 'Language lesson',
  '체육/운동교습': 'Sports lesson',
  'SW코딩': 'SW Coding',
  '바리스타/커피': 'Barister/Coffee',
  '한국문화': 'Korean culture',
  '미용실/스킨/네일': 'Hair/Skin/Nail',
  '스파/마사지/찜질방': 'Spa/Massage',
  '문신': 'Tatoo',
  '요가/피트니스': 'Yoga/Fitness',
  '무용/댄스': 'Dance lesson',
  '골프': 'Golf',
  '아동복': 'Children\'s apparel',
  '일반의류': 'General apparel',
  '양장/패션': 'Fashion/Boutique',
  '양복점': 'Men\'s suits',
  '모피/가죽': 'Fur/leather',
  '속옷/양말': 'Underwear/Socks',
  '모자/잡화': 'Hats/Misc',
  '음식배달': 'Food delivery',
  '창/문/유리/거울': 'Window/Glass/Mirror',
  '바디샵': 'Car body shop',
  '렌트카': 'Car rent',
  '견인': 'Towing',
  '스포츠/레저용품': 'Sports/Leisure',
  '침구류': 'Bedding',
  '쇼핑/선물가게': 'Shopping/Giftshop',
  '동물용품': 'Pet products',
  '악기': 'Musical instrument',
  '미용재료': 'Beauty material',
  '장의사/장례': 'Funeral/Cemetery',
  '심리상담': 'Psychology counselling',
  '건강상담': 'Health Counselling',
  '양로원/요양원': 'Nursing home',
  '가발': 'Wig',
  '기계/설비제작': 'Machine manufacturing',
  '홈스테이징/데코레이션': 'Home staging/decoration',
  '재난손상복구': 'Damage restoring',
  '카이로프랙틱/물리치료': 'Chiropractic/Physiotherapy',
  '비디오대여': 'Video rental',
  '자수': 'Embroidery',
  '상패/트로피': 'Trophy',
  '서예/사군자': 'Calligraphy',
  '재봉': 'Sewing',
  '공예': 'Craftwork',
  '양조장': 'Winery/Brewery',
  '양봉': 'Beekeeper',
  '한국기업/지사': 'Branch office',
  '학원/교습': 'Private lesson',
  '단전호흡': 'Zen',
  '그릇': 'Kitchenware',
  '보관창고': 'Storage',
  '정크처리': 'Junk removal',
  '구매대행': 'Purchasing agent',
  '수면교육': 'Sleeping training',
  '웅변/토론': 'Debating/Eloquence',
  '승마/낚시': 'Horse riding/Fishing',
  '관광지': 'Historic sites',
  '디저트': 'Dessert',
  '꼬치/구이': 'Skewer',
  '요리강습': 'Cooking lesson',
  /* for reverse conversion: end */
  view_all: 'View all',
  none: 'None',
  'Your location': 'Current location',
  Alberta: 'Alberta',
  'British Columbia': 'British Columbia',
  Ontario: 'Ontario',
  lt5km: 'within 5km',
  lt15km: 'within 15km',
  lt25km: 'within 25km',
  lt50km: 'within 50km',
  Calgary: 'Calgary',
  Edmonton: 'Edmonton',
  'Etc': 'Etc.',
  Vancouver: 'Vancouver',
  Kelowna: 'Kelowna',
  Toronto: 'Toronto',
  Ottawa: 'Ottawa',
  Outofbound: 'Out of Canada',
  usercontext_update_err: 'Cannot initialize user information.',
  visit_link: 'Press this popup to visit the related page.',
  welcome: 'Welcome',
  please_setup_for_the_first: 'First time users need to configure basic settings.',
  goto_settting: 'Go to the settings page',
  report_error: 'Report Error',
  under_review: 'Under review',
  directions: 'Directions',
  succeed_in_report: 'Thank you for reporting.',
  fail_in_report: 'Oops! Something wrong ...',
  report_err_msg1: 'This page is for reporting errors in directory information. We will give you +1 point when your report is accepted. \
    Your point value would be used to get some rewards.',
  report_err_item0: 'Business closed.',
  report_err_item1: 'Wrong phone number.',
  report_err_item2: 'Wrong address.',
  report_err_item3: 'Changed business.',
  finish: 'OK',
  place_name: 'Business name',
  access_report_err: 'Cannot access the error reporting information.',
  what_are_you_looking_for: 'What are you looking for?',
  directory_top: 'Business Directory',
  directory_sublist: 'List of Businesses',
  detailed: 'Business details',
  i_got_it: 'I got it!',
  options: 'Options',
  filtering_letters: 'Enter a word for filtering',
  filtering: 'Filtering',
  enter_search: 'Please enter a word.',
  ok_thanks: 'OK~ Thanks.',
  no_thanks: 'No! Thanks.',
  wrong_email_password: "Email or Pasword is wrong. If you create a new account, please check the confirmation email.",
  email: 'Email',
  password: 'Password',
  password_confirm: 'Password confirm',
  enter_password: 'Enter a password.',
  selected_nothing: "Please select at least one field.",
  adjust_search_conditions: 'Cannot find places. Please adjust searching conditions.',
  Manitoba: 'Manitoba',
  Winnipeg: 'Winnipeg',
  'New Brunswick': 'New Brunswick',
  'Saint John': 'Saint John',
  'Nova Scotia': 'Nova Scotia',
  Halifax: 'Halifax',
  Saskatchewan: 'Saskatchewan',
  Regina: 'Regina',
  Quebec: 'Quebec',
  Montreal: 'Montreal',
  'Online Only': 'Online Only',
  Markham: 'Markham',
  Mississauga: 'Mississauga',
  'Richmond Hill': 'Richmond Hill',
  Vaughan: 'Vaughan',
  'North York': 'North York',
  London: 'London',
  Hamilton: 'Hamilton',
  Victoria: 'Victoria',
  Burnaby: 'Burnaby',
  Coquitlam: 'Coquitlam',
  Langley: 'Langley',
  Surrey: 'Surrey',
  Allarea: 'All areas',
  'Washington': 'Washington',
  'Seattle': 'Seattle',
  'Bellevue': 'Bellevue',
  'Federal Way': 'Federal Way',
  'Lakewood': 'Lakewood',
  'Lynnwood': 'Lynnwood',
  'Tacoma': 'Tacoma',
  find_geolocation: 'Finding current location',
  bookmarks_overflow: 'The number of bookmarks cannot exceed 5.',
  notice_caution: 'Caution: Cakory App does not guarantee the correctness of information. Please use Cakory App under your own responsibility.',
  sharing_err: 'Failed in sharing.',
  search_results: 'Search Results',
  notification_list: 'Notifications List',
  nothing_to_show: 'There is nothing to show. Please try to change the area or search conditions.',
  res_search_by_image: 'Restaurant search with an image',
  food_directory: 'Food directory',
  google_rating: 'Google rating',
  google_search: 'Google search/rating',
  warning_list_long: 'Too many results! Cannot show all the results. Please refine the area through filtering.',
  warning_reset_setting: 'Warning! Bookmarks will be deleted as well on "RESET SETTINGS".',
  not_tags: 'This is not a tag.',
  use_tag_alts_1: 'Please use',
  use_tag_alts_2: 'instead.',
  new_place: 'New place',
  new_event: 'New event',
  new_ad: 'Ad',
  new_notice: 'Notice',
  no_bookmarks: 'There is no bookmark registered.',
  out_of_canada_coordinate: 'Your location is out of canada.',
  weight: 'Weight',
  pound: 'Pound',
  kilogram: 'Kilogram',
  volume: 'Volume',
  ounce: 'Ounce',
  mililiter: 'Mililiter',
  length: 'Length',
  inch: 'Inch',
  centimeter: 'Centimeter',
  feet: 'Feet',
  temperature: 'Temperature',
  fahrenheit: 'Fahrenheit',
  celsius: 'Celsius',
  delete_account_m: 'Delete Account',
  delete_account_guide_m: 'This action will completely remove your account information including the point value.',
  delete_account_confirm_m: 'Do you really want to delete your account?',
  delete_account_err: 'Error on deleting the account.',
  delete_account_success: 'Succeed in deleting the account',
  email_used: 'This email address is already used. Please try with other email address.',
  email_notconfirmed: 'This email address is under waiting confirmation. Please check your email inbox.',
  comm_server: 'Accessing servers...',
  korean_food_list: 'K-Food',
  only_mobileapp: 'This function is supported only in mobile apps.'
}

export const ko = {
  mt_search: "검색",
  mt_list: "목록",
  mt_bookmark: "북마크",
  mt_unit_conversion: "도량형",
  unit_conversion: "도량형 변환",
  h_coupons_t: "추천 쿠폰",
  c_all_l: '전체',
  c_food_l: '음식',
  c_service_l: '서비스',
  c_ent_l: '엔터테인먼트',
  c_tour_l: '여행',
  c_shopping_l: '쇼핑',
  logout_m: '로그아웃',
  login_m: '로그인',
  change_pwd_m: '비밀번호 바꾸기',
  setting_m: '설정',
  user_info_m: '사용자 정보',
  id_m: '아이디',
  locale_m: '표시 언어',
  privacy_policy_m: '개인정보 취급방침',
  contact_us_m: '문의하기',
  app_ver_m: '앱 정보',
  confirm_m: '확인',
  done_m: '완료',
  cancel_m: '취소',
  create_account_m: '계정 새로 만들기',
  forget_pwd_q: '비밀번호를 잊어 버리셨나요?',
  login_err_guide_m: '로그인 또는 아이디/비밀번호 찾기에 문제가 있으시면 메일로 문의해주세요. 빠른 시간 내에 처리해 드립니다.',
  create_account_m: '계정 만들기',
  success_in_create_account_m: '새로운 계정을 만드는데 성공했습니다.',
  go_to_login_page_m: '로그인 페이지로 이동하기',
  reset_pwd_m: '비밀번호 재설정',
  reset_pwd_guide_m: "아이디로 사용하시는 이메일 주소를 입력하고 '확인'을 눌러주세요. 비밀번호 설정용 웹페이지 링크를 해당 이메일로 보내드립니다.",
  change_pwd_guide_m: "'확인'을 눌러주세요. 비밀번호 변경용 웹페이지 링크를 해당 이메일로 보내드립니다.",
  error_unknown_id_m: '등록되지 않은 아이디입니다.',
  error_login_fail_m: '로그인에 실패했습니다. 다시 시도해 보셔도 실패하시면 당사 연락처로 문의해주시기 바랍니다. ',
  error_mismatch_pwd_m: '패스워드가 서로 다릅니다.',
  error_used_email_m: '이미 사용되고 있는 아이디/이메일입니다.',
  error_create_account_m: '계정을 새로 만들 수 없습니다. 당사 연락처로 문의해 주시기 바랍니다.',
  noti_create_account_m: '아이디로 사용하고 계신 이메일 주소로 확정용 메일이 전송되었습니다. 메일함을 확인해주시기 바랍니다.',
  noti_change_pwd_m: '아이디로 사용하고 계신 이메일 주소로 안내 메일이 전송되었습니다. 메일함을 확인해주시기 바랍니다.',
  error_change_pwd_m: '에러가 발생했습니다. 당사 연락처로 문의해 주시기 바랍니다.',
  locale_change_msg_m: '언어가 바뀌지 않았다면 앱을 종료하고 다시 시작해보시기 바랍니다.',
  error_change_pwd_not_logged_m: '로그인 상태일 때만 패스워드를 바꿀 수 있습니다.',
  area_for_search: '찾을 지역',
  fields_for_search: '검색할 필드',
  name: '이름',
  address: '주소',
  phonenum: '전화',
  category: '카테고리',
  mapview: '지도보기',
  among_categories: '목록에서...',
  among_tags: '연관어에서...',
  nothing_searched: '검색된 것이 없습니다. 검색조건을 확인해보세요.',
  among_name: '이름에서...',
  among_address: '주소에서...',
  only_one_word: ' 한 개의 단어만 지원합니다.',
  load_more: '더 보기',
  upto_now1: '현재까지',
  upto_now2: '개',
  setting_locale_err: "언어 설정에 오류가 있습니다.",
  geolocation_err: "현재위치 정보를 가져올 수 없습니다. 기기의 설정을 확인해보세요.",
  search_err: "검색에 실패했습니다.",
  banner_err: "배너정보를 가져오는데 실패했습니다.",
  internet_err: "인터넷이 연결되었는지 확인해보세요.",
  geo_yourlocation: "현재 위치",
  point: '포인트 점수',
  not_logged: '로그인 상태가 아닙니다.',
  clear_setting: '설정 초기화하기',
  ok_clear_setting: '설정이 초기화되었습니다. 앱을 다시 실행해주세요.',
  setting_expotoken_err: "푸쉬알람 설정 저장 정보에 접근할 수 없습니다.",
  registering_expopush_err: '푸쉬알람 설정을 등록할 수 없습니다.',
  create_usersetting_err: "사용자 설정 정보를 생성하지 못했습니다.",
  fetch_usersetting_err: '사용자 설정 정보를 읽어올 수 없습니다.',
  update_usersetting_err: '사용자 설정 정보를 갱신할 수 없습니다.',
  usersetting_conflict: '사용자 설정 정보에 불일치가 있습니다.',
  please_login_again: '다시 로그인해 주세요.',
  push_notification: '푸쉬 알림',
  change_password_err: '패스워드 변경에 실패했습니다.',
  me_query_err: '사용자 로그인 상태가 유효하지 않습니다.',
  share_place: '정보 공유',
  share_cakory_footer: 'CAKORY 앱(cakory.com)에서 보냄.',
  refer_to_the_link: '자세한 정보는 링크를 클릭해서 확인하세요.',
  please_login: '로그인을 해야 사용할 수 있는 서비스입니다.',
  bookmarked_places: '내가 찜한 곳들',
  clothes_c: '의',
  food_c: '식',
  house_c: '주',
  car_c: '자동차',
  institution_c: '기관/단체',
  playing_c: '놀거리',
  merchandise_c: '상품구매/상점',
  professional_service_c: '전문/자격 서비스',
  life_edu_service_c: '생활/교육 서비스',
  company_c: '기업체',
  hanbok: '한복',
  restaurant: '식당',
  cafe: '카페/샌드위치',
  ricecake: '떡집',
  bakery: '베이커리',
  grocery: '식품점/시장',
  butcher_shop: '정육점',
  food_wholesale: '식자재도매',
  catering: '반찬/케이터링/밀키트',
  accommodation: '숙박/홈스테이',
  realtor: '부동산중개인',
  plumbing: '배관/난방',
  electric: '전기/냉동',
  roofing: '지붕/외벽/타일',
  kitchen: '부엌/캐비넷/가라지',
  light: '조명/페인트',
  floor: '마루/블라인드',
  interior: '집수리/인테리어',
  security: '보안/통신',
  pest_control: '방역/독성제거',
  dealership: '자동차판매',
  repairshop: '자동차정비',
  carwash: '세차/디테일링',
  tire: '부품/타이어',
  rv: '캠핑카',
  government: '정부기관',
  religion: '종교단체/시설',
  volunteer: '봉사단체',
  media: '언론/방송',
  korean_community: '교민단체/동호회',
  education: '교육기관/단체',
  billiard: '당구/볼링',
  karaoke: '노래방',
  travel_agent: '여행사/항공사',
  bar: '주점',
  medical_equip: '의료기기',
  supplement: '건강식품/보조제',
  jewelry: '보석상/시계',
  cosmetic: '화장품',
  bag: '가방',
  ice_cream: '아이스크림',
  bidet: '비데',
  water_filter: '정수기',
  dollar_shop: '천원샵',
  hearing_aid: '보청기',
  liquor: '술',
  tobacco: '담배',
  farm: '농장',
  restaurant_equip: '식당용기구/포장재',
  electronics: '전자제품/컴퓨터',
  printer_ink: '사무용품/프린터잉크',
  furniture: '가구',
  bookstore: '서점',
  flower: '꽃집/꽃꽂이',
  lawyer: '변호사/법률서비스',
  accountant: '회계사/세무서비스',
  immigration: '이민/유학원',
  translator: '번역/통역/공증',
  construction: '주택/건축관련',
  finance: '금융/보험',
  realty: '부동산/거래관련',
  medical_service: '의사/의료서비스',
  design_ad: '디자인/광고/마케팅',
  print: '인쇄/간판/액자',
  sw_dev: 'SW개발/웹개발',
  av_solution: '음향/영상솔루션',
  pos_network: 'POS/ATM/네트워크설치',
  realty_mng: '부동산관리/상담',
  debt: '채무상담/채무조정',
  fire_equip: '소방시설',
  piano_tuning: '악기수리/조율',
  daycare: '데이케어/유치원',
  tutoring: '학원/교습',
  beauty: '뷰티/몸가꾸기',
  pet_grooming: '애완동물관리',
  laundry: '세탁/수선',
  matching: '결혼정보',
  event: '이벤트/행사',
  moving: '이삿짐/운송',
  internet: '인터넷/모바일',
  elec_repair: '전자제품/컴퓨터수리',
  landscape: '조경',
  house_clean: '청소/카펫클리닝',
  delivery: '택배',
  exercise: '운동/체력단련',
  ride: '라이드/택시',
  housekeeping: '산후조리/가사도우미',
  internet_cafe: '피씨방',
  locksmith: '열쇠/도어락',
  shoes_repair: '신발수선',
  wedding: '웨딩',
  photo_studio: '사진/스튜디오',
  sajoo: '사주/운세',
  manufacturing: '제조업',
  dental_lab: '치기공소',
  elec_part: '전자부품',
  trading: '무역업/수입상',
  korean_food: '한식',
  japanese_food: '일식',
  chinese_food: '중식',
  vietnamese_food: '베트남음식',
  chicken: '치킨',
  street_food: '길거리음식',
  pizza: '피자',
  pork_cutlet: '돈까스',
  western_food: '서양식',
  allyoucaneat: '뷔페/올유캔잇',
  light_food: '분식',
  architect: '건축사/건축설계',
  renovation: '건축시공/리노베이션',
  mortgage: '모기지/융자',
  bank: '은행',
  loan: '대출',
  insurance: '보험/자산관리/투자금융',
  home_inspector: '홈인스펙션',
  appraiser: '부동산감정평가사',
  oriental_clinic: '한의원',
  hospital: '의사/병원',
  pharmacy: '약국',
  dentist: '치과/틀니',
  eyeglasses: '안경점/검안사',
  nursing: '간호/간병인',
  pet_clinic: '동물병원',
  martial_lesson: '무술도장',
  tutoring: '보습/진학상담',
  driving_lesson: '운전교습',
  art_lesson: '미술교습/화실',
  music_lesson: '음악교습',
  language_lesson: '어학',
  sport_lesson: '체육/운동교습',
  sw_lesson: 'SW코딩',
  barister_lesson: '바리스타/커피',
  korean_culture_lesson: '한국문화',
  hair_salon: '미용실/스킨/네일',
  spa: '스파/마사지/찜질방',
  tattoo: '문신',
  yoga: '요가/피트니스',
  dance: '무용/댄스',
  golf: '골프',
  'kids_clothes': '아동복',
  'regular_clothes': '일반의류',
  'fashion_clothes': '양장/패션',
  'mens_clothes': '양복점',
  'fur_leather': '모피/가죽',
  'underwear_socks': '속옷/양말',
  'hats': '모자/잡화',
  food_delivery: '음식배달',
  window: '창/문/유리/거울',
  bodyshop: '바디샵',
  car_rent: '렌트카',
  towing: '견인',
  'sports_leisure': '스포츠/레저용품',
  'bedding': '침구류',
  'giftshop': '쇼핑/선물가게',
  'pet_product': '동물용품',
  'musical_instrument': '악기',
  'beauty_material': '미용재료',
  'funeral': '장의사/장례',
  'psychology_counselling': '심리상담',
  'health_counselling': '건강상담',
  'nursing_home': '양로원/요양원',
  'wig': '가발',
  'machine_manufacture': '기계/설비제작',
  'home_staging': '홈스테이징/데코레이션',
  disaster: '재난손상복구',
  physiotherapy: '카이로프랙틱/물리치료',
  'video_rent': '비디오대여',
  'embroidery': '자수',
  'trophy': '상패/트로피',
  'calligraphy': '서예/사군자',
  'sewing': '재봉',
  'craftwork': '공예',
  'winery': '양조장',
  'beekeeper': '양봉',
  'branch_office': '한국기업/지사',
  'private_lesson': '학원/교습',
  'danjeon': '단전호흡',
  'kitchenware': '그릇',
  storage: '보관창고',
  junk: '정크처리',
  proxy_buy: '구매대행',
  sleep_training: '수면교육',
  debate: '웅변/토론',
  fishing: '승마/낚시',
  'site': '관광지',
  'dessert': '디저트',
  'skewer': '꼬치/구이',
  'cooking_lesson': '요리강습',
  'jjajangmyeon': '짜장면', '짜장면': '짜장면',
  'jjamppong': '짬뽕', '짬뽕': '짬뽕',
  'yusanseul': '유산슬', '유산슬': '유산슬',
  'parish': '성당', '성당': '성당',
  'church': '교회', '교회': '교회',
  'temple': '절', '절': '절',
  'gopchang': '곱창', '곱창': '곱창',
  'bulgogi': '불고기', '불고기': '불고기',
  'galbi': '갈비', '갈비': '갈비',
  'bibimbap': '비빔밥', '비빔밥': '비빔밥',
  'japchae': '잡채', '잡채': '잡채',
  'jjukkumi': '쭈꾸미', '쭈꾸미': '쭈꾸미',
  'naengmyeon': '냉면', '냉면': '냉면',
  'samgyetang': '삼계탕', '삼계탕': '삼계탕',
  'budaejjigae': '부대찌개', '부대찌개': '부대찌개',
  'samgyeopsal': '삼겹살', '삼겹살': '삼겹살',
  'ganjang gejang': '간장게장', '간장게장': '간장게장',
  'yukhoe': '육회', '육회': '육회',
  'juk': '죽', '죽': '죽',
  'ssambap': '쌈밥', '쌈밥': '쌈밥',
  'ramyeon': '라면', '라면': '라면',
  'tteokbokki': '떡볶이', '떡볶이': '떡볶이',
  'hotdog': '핫도그', '핫도그': '핫도그',
  'cupbap': '컵밥', '컵밥': '컵밥',
  'poke': '포케', '포케': '포케',
  'sundae': '순대', '순대': '순대',
  'hanjeongsik': '한정식/코스요리', '한정식/코스요리': '한정식/코스요리',
  'bingsoo': '빙수', '빙수': '빙수',
  'sushi': '초밥', '초밥': '초밥',
  'hoedeopbap': '회덮밥', '회덮밥': '회덮밥',
  'foodcourt': '푸드코트', '푸드코트': '푸드코트',
  'vegetarian': '배지테리안', '배지테리안': '배지테리안',
  'makgeolli': '막걸리', '막걸리': '막걸리',
  'soju': '소주', '소주': '소주',
  'gomtang': '곰탕', '곰탕': '곰탕',
  'kkochi': '꼬치', '꼬치': '꼬치',
  'sundubujjigae': '순두부찌개', '순두부찌개': '순두부찌개',
  'kimchijjigae': '김치찌개', '김치찌개': '김치찌개',
  'buchimgae': '부침개/전', '부침개/전': '부침개/전',
  'nurungjitang': '누룽지탕', '누룽지탕': '누룽지탕',
  'kkanpunggi': '깐풍기', '깐풍기': '깐풍기',
  'palbochae': '팔보채', '팔보채': '팔보채',
  'yangjangpi': '양장피', '양장피': '양장피',
  'ohyangjangyuk': '오향장육', '오향장육': '오향장육',
  'tangsuyuk': '탕수육', '탕수육': '탕수육',
  'roll': '롤', '롤': '롤',
  'japaneseramen': '일본 라면', '일본 라면': '일본 라면',
  'hoe': '회', '회': '회',
  'gimbap': '김밥', '김밥': '김밥',
  'donkatseu': '돈까스', '돈까스': '돈까스',
  'bubbletea': '버블티', '버블티': '버블티',
  'friedchicken': '후라이드치킨', '후라이드치킨': '후라이드치킨',
  'buldak': '불닭', '불닭': '불닭',
  'odeng': '오뎅', '오뎅': '오뎅',
  'bossam': '보쌈', '보쌈': '보쌈',
  'jokbal': '족발', '족발': '족발',
  'janchiguksu': '잔치국수', '잔치국수': '잔치국수',
  'gukbap': '국밥', '국밥': '국밥',
  'dakgangjung': '닭강정', '닭강정': '닭강정',
  'yangnyeomchicken': '양념치킨', '양념치킨': '양념치킨',
  'jeyuk': '제육', '제육': '제육',
  'tteokguk': '떡국/만두국', '떡국/만두국': '떡국/만두국',
  'gamjatang': '감자탕', '감자탕': '감자탕',
  'eel': '장어', '장어': '장어',
  'dakttongjip': '닭똥집', '닭똥집': '닭똥집',
  'altang': '알탕', '알탕': '알탕',
  'udon': '우동', '우동': '우동',
  'chineseudon': '중국식 우동', '중국식 우동': '중국식 우동',
  'seolleongtang': '설렁탕', '설렁탕': '설렁탕',
  'doenjangjjigae': '된장찌개', '된장찌개': '된장찌개',
  'kimchibokkeumbap': '김치볶음밥', '김치볶음밥': '김치볶음밥',
  'dakgalbi': '닭갈비', '닭갈비': '닭갈비',
  'jjimdak': '찜닭', '찜닭': '찜닭',
  'sikhye': '식혜', '식혜': '식혜',
  'gunmandu': '군만두', '군만두': '군만두',
  'bokkeumbap': '중국식 볶음밥', '중국식 볶음밥': '중국식 볶음밥',
  'rajogi': '라조기', '라조기': '라조기',
  'yukgaejang': '육개장', '육개장': '육개장',
  'haejangguk': '해장국', '해장국': '해장국',
  'kalguksu': '칼국수', '칼국수': '칼국수',
  'kongguksu': '콩국수', '콩국수': '콩국수',
  'origogi': '오리고기', '오리고기': '오리고기',
  'saengseongui': '생선구이', '생선구이': '생선구이',
  'hongeo': '홍어', '홍어': '홍어',
  'maeuntang': '매운탕', '매운탕': '매운탕',
  'retreat': '기도원', '기도원': '기도원',
  'dashcam': '블랙박스', '블랙박스': '블랙박스',
  'kimchi_products': '김치 (판매용)', '김치 (판매용)': '김치 (판매용)',
  'hotteok': '호떡', '호떡': '호떡',
  'bungeoppang': '붕어빵', '붕어빵': '붕어빵',
  'hodugwaja': '호두과자', '호두과자': '호두과자',
  'croffle': '크로플', '크로플': '크로플',
  'mf_nabe': '밀푀유나베', '밀푀유나베': '밀푀유나베',
  /* for reverse conversion: start */
  '의': '의',
  '식': '식',
  '주': '주',
  '자동차': '자동차',
  '기관/단체': '기관/단체',
  '놀거리': '놀거리',
  '상품구매/상점': '상품구매/상점',
  '전문/자격 서비스': '전문/자격 서비스',
  '생활/교육 서비스': '생활/교육 서비스',
  '기업체': '기업체',
  '한복': '한복',
  '식당': '식당',
  '카페/샌드위치': '카페/샌드위치',
  '떡집': '떡집',
  '베이커리': '베이커리',
  '식품점/시장': '식품점/시장',
  '정육점': '정육점',
  '식자재도매': '식자재도매',
  '반찬/케이터링/밀키트': '반찬/케이터링/밀키트',
  '숙박/홈스테이': '숙박/홈스테이',
  '부동산중개인': '부동산중개인',
  '배관/난방': '배관/난방',
  '전기/냉동': '전기/냉동',
  '지붕/외벽/타일': '지붕/외벽/타일',
  '부엌/캐비넷/가라지': '부엌/캐비넷/가라지',
  '조명/페인트': '조명/페인트',
  '마루/블라인드': '마루/블라인드',
  '집수리/인테리어': '집수리/인테리어',
  '보안/통신': '보안/통신',
  '방역/독성제거': '방역/독성제거',
  '자동차판매': '자동차판매',
  '자동차정비': '자동차정비',
  '세차/디테일링': '세차/디테일링',
  '부품/타이어': '부품/타이어',
  '캠핑카': '캠핑카',
  '정부기관': '정부기관',
  '종교단체/시설': '종교단체/시설',
  '봉사단체': '봉사단체',
  '언론/방송': '언론/방송',
  '교민단체/동호회': '교민단체/동호회',
  '교육기관/단체': '교육기관/단체',
  '당구/볼링': '당구/볼링',
  '노래방': '노래방',
  '여행사/항공사': '여행사/항공사',
  '주점': '주점',
  '의료기기': '의료기기',
  '건강식품/보조제': '건강식품/보조제',
  '보석상/시계': '보석상/시계',
  '화장품': '화장품',
  '가방': '가방',
  '아이스크림': '아이스크림',
  '비데': '비데',
  '정수기': '정수기',
  '천원샵': '천원샵',
  '보청기': '보청기',
  '술': '술',
  '담배': '담배',
  '농장': '농장',
  '식당용기구/포장재': '식당용기구/포장재',
  '전자제품/컴퓨터': '전자제품/컴퓨터',
  '사무용품/프린터잉크': '사무용품/프린터잉크',
  '가구': '가구',
  '서점': '서점',
  '꽃집/꽃꽂이': '꽃집/꽃꽂이',
  '변호사/법률서비스': '변호사/법률서비스',
  '회계사/세무서비스': '회계사/세무서비스',
  '이민/유학원': '이민/유학원',
  '번역/통역/공증': '번역/통역/공증',
  '주택/건축관련': '주택/건축관련',
  '금융': '금융',
  '부동산/거래관련': '부동산/거래관련',
  '의사/의료서비스': '의사/의료서비스',
  '디자인/광고/마케팅': '디자인/광고/마케팅',
  '인쇄/간판/액자': '인쇄/간판/액자',
  'SW개발/웹개발': 'SW개발/웹개발',
  '음향/영상솔루션': '음향/영상솔루션',
  'POS/ATM/네트워크설치': 'POS/ATM/네트워크설치',
  '부동산관리/상담': '부동산관리/상담',
  '채무상담/채무조정': '채무상담/채무조정',
  '소방시설': '소방시설',
  '악기수리/조율': '악기수리/조율',
  '데이케어/유치원': '데이케어/유치원',
  '학원/교습': '학원/교습',
  '뷰티/몸가꾸기': '뷰티/몸가꾸기',
  '애완동물관리': '애완동물관리',
  '세탁/수선': '세탁/수선',
  '결혼정보': '결혼정보',
  '이벤트/행사': '이벤트/행사',
  '이삿짐/운송': '이삿짐/운송',
  '인터넷/모바일': '인터넷/모바일',
  '전자제품/컴퓨터수리': '전자제품/컴퓨터수리',
  '조경': '조경',
  '청소/카펫클리닝': '청소/카펫클리닝',
  '택배': '택배',
  '운동/체력단련': '운동/체력단련',
  '라이드/택시': '라이드/택시',
  '산후조리/가사도우미': '산후조리/가사도우미',
  '피씨방': '피씨방',
  '열쇠/도어락': '열쇠/도어락',
  '신발수선': '신발수선',
  '웨딩': '웨딩',
  '사진/스튜디오': '사진/스튜디오',
  '사주/운세': '사주/운세',
  '제조업': '제조업',
  '치기공소': '치기공소',
  '전자부품': '전자부품',
  '무역업/수입상': '무역업/수입상',
  '한식': '한식',
  '일식': '일식',
  '중식': '중식',
  '베트남음식': '베트남음식',
  '치킨': '치킨',
  '길거리음식': '길거리음식',
  '피자': '피자',
  '돈까스': '돈까스',
  '서양식': '서양식',
  '보쌈/족발': '보쌈/족발',
  '뷔페/올유캔잇': '뷔페/올유캔잇',
  '분식': '분식',
  '건축사/건축설계': '건축사/건축설계',
  '건축시공/리노베이션': '건축시공/리노베이션',
  '모기지/융자': '모기지/융자',
  '은행': '은행',
  '대출': '대출',
  '보험/자산관리/투자금융': '보험/자산관리/투자금융',
  '홈인스펙션': '홈인스펙션',
  '부동산감정평가사': '부동산감정평가사',
  '한의원': '한의원',
  '의사/병원': '의사/병원',
  '약국': '약국',
  '치과/틀니': '치과/틀니',
  '안경점/검안사': '안경점/검안사',
  '간호/간병인': '간호/간병인',
  '동물병원': '동물병원',
  '무술도장': '무술도장',
  '보습/진학상담': '보습/진학상담',
  '운전교습': '운전교습',
  '미술교습/화실': '미술교습/화실',
  '음악교습': '음악교습',
  '어학': '어학',
  '체육/운동교습': '체육/운동교습',
  'SW코딩': 'SW코딩',
  '바리스타/커피': '바리스타/커피',
  '한국문화': '한국문화',
  '미용실/스킨/네일': '미용실/스킨/네일',
  '스파/마사지/찜질방': '스파/마사지/찜질방',
  '문신': '문신',
  '요가/피트니스': '요가/피트니스',
  '무용/댄스': '무용/댄스',
  '골프': '골프',
  '아동복': '아동복',
  '일반의류': '일반의류',
  '양장/패션': '양장/패션',
  '양복점': '양복점',
  '모피/가죽': '모피/가죽',
  '속옷/양말': '속옷/양말',
  '모자/잡화': '모자/잡화',
  '음식배달': '음식배달',
  '창/문/유리/거울': '창/문/유리/거울',
  '바디샵': '바디샵',
  '렌트카': '렌트카',
  '견인': '견인',
  '스포츠/레저용품': '스포츠/레저용품',
  '침구류': '침구류',
  '쇼핑/선물가게': '쇼핑/선물가게',
  '동물용품': '동물용품',
  '악기': '악기',
  '미용재료': '미용재료',
  '장의사/장례': '장의사/장례',
  '심리상담': '심리상담',
  '건강상담': '건강상담',
  '양로원/요양원': '양로원/요양원',
  '가발': '가발',
  '기계/설비제작': '기계/설비제작',
  '홈스테이징/데코레이션': '홈스테이징/데코레이션',
  '재난손상복구': '재난손상복구',
  '카이로프랙틱/물리치료': '/카이로프랙틱/물리치료',
  '비디오대여': '비디오대여',
  '자수': '자수',
  '상패/트로피': '상패/트로피',
  '서예/사군자': '서예/사군자',
  '재봉': '재봉',
  '공예': '공예',
  '양조장': '양조장',
  '양봉': '양봉',
  '한국기업/지사': '한국기업/지사',
  '학원/교습': '학원/교습',
  '단전호흡': '단전호흡',
  '그릇': '그릇',
  '보관창고': '보관창고',
  '정크처리': '정크처리',
  '구매대행': '구매대행',
  '수면교육': '수면교육',
  '웅변/토론': '웅변/토론',
  '승마/낚시': '승마/낚시',
  '관광지': '관광지',
  '디저트': '디저트',
  '꼬치/구이': '꼬치/구이',
  '요리강습': '요리강습',
  /* for reverse conversion: end */
  view_all: '모두보기',
  None: '없음',
  'Your location': '현재위치',
  Alberta: '알버타',
  'British Columbia': '브리티시콜럼비아',
  Ontario: '온타리오',
  lt5km: '반경 5km이내',
  lt15km: '반경 15km이내',
  lt25km: '반경 25km이내',
  lt50km: '반경 50km이내',
  Calgary: '캘거리',
  Edmonton: '에드먼튼',
  'Etc': '기타',
  Vancouver: '밴쿠버',
  Kelowna: '켈로나',
  Toronto: '토론토',
  Ottawa: '오타와',
  Outofbound: '비캐나다지역',
  usercontext_update_err: '사용자 정보 확인에 실패했습니다.',
  visit_link: '이 창을 터치하면 관련페이지로 이동합니다.',
  welcome: '반갑습니다',
  please_setup_for_the_first: '처음 사용하시는 분은 초기 설정을 해주셔야 합니다.',
  goto_settting: '설정 화면으로 가기',
  report_error: '오류신고',
  under_review: '신고 검토중',
  directions: '경로 찾기',
  succeed_in_report: '등록되었습니다. 감사합니다.',
  fail_in_report: '신고 등록에 실패했습니다. 관리자에게 문의하세요.',
  report_err_msg1: '오류 신고 페이지입니다. 신고한 오류가 채택되면 포인트가 1점 올라갑니다. 후에 포인트에 따라 소정의 상품을 제공할 예정입니다.',
  report_err_item0: '업체가 없어졌습니다.',
  report_err_item1: '전화번호가 잘못되었습니다.',
  report_err_item2: '주소가 잘못되었습니다.',
  report_err_item3: '업종이 바뀌었습니다.',
  finish: '완료',
  place_name: '업소 이름',
  access_report_err: '업체 오류 신고 정보에 접근할 수 없습니다. 관리자에게 문의하세요.',
  what_are_you_looking_for: '어디를 찾고 계신가요?',
  directory_top: '목록',
  directory_sublist: '업체 상세 목록',
  detailed: '업소 상세정보',
  i_got_it: '확인',
  options: '선택사항',
  filtering_letters: '필터링용 문자들',
  filtering: '필터링',
  enter_search: '검색할 단어를 입력해주세요.',
  ok_thanks: '받겠습니다',
  no_thanks: '안 받겠습니다',
  wrong_email_password: "이메일 혹은 패스워드가 잘못되었습니다. 만약 새로 만든 계정이라면, 확인용 이메일을 처리해주셔야 합니다.",
  email: '이메일',
  password: '비밀번호',
  password_confirm: '비밀번호 재확인',
  enter_password: '비밀번호를 입력하세요.',
  selected_nothing: '최소 한 개의 필드는 선택해주세요.',
  adjust_search_conditions: '찾는 곳이 없습니다. 검색조건을 조정해 보세요.',
  Manitoba: '매니토바',
  Winnipeg: '위니펙',
  'New Brunswick': '뉴브런즈윅',
  'Saint John': '세인트존',
  'Nova Scotia': '노바스코샤',
  Halifax: '헬리팩스',
  Saskatchewan: '사스캐츄완',
  Regina: '리자이나',
  Quebec: '퀘벡',
  Montreal: '몬트리올',
  'Online Only': '온라인 업체',
  Markham: '마컴',
  Mississauga: '미시사가',
  'Richmond Hill': '리치몬드힐',
  Vaughan: '본',
  'North York': '노스욕',
  London: '런던',
  Hamilton: '해밀턴',
  Victoria: '빅토리아',
  Burnaby: '버나비',
  Coquitlam: '코퀴틀람',
  Langley: '랭리',
  Surrey: '써리',
  Allarea: '전 지역',
  'Washington': '미 워싱턴주',
  'Seattle': '시애틀',
  'Bellevue': '벨뷰',
  'Federal Way': '페더럴웨이',
  'Lakewood': '레이크우드',
  'Lynnwood': '린우드',
  'Tacoma': '타고마',
  find_geolocation: '현재위치를 확인하고 있습니다.',
  bookmarks_overflow: '북마크는 5개를 넘길 수 없습니다.',
  notice_caution: '주의: Cakory 앱의 정보에는 오류가 있을 수 있습니다. Cakory 앱은 오류에 대한 책임을 지지 않습니다.',
  sharing_err: '정보 공유에 실패했습니다. 관리자에게 문의하세요.',
  search_results: '검색결과',
  notification_list: '푸쉬 알림 목록',
  nothing_to_show: '보여줄 내용이 없습니다. 지역 설정을 더 넓히시거나 검색 조건을 바꿔보세요.',
  res_search_by_image: '사진으로 식당 찾기',
  food_directory: '음식 목록',
  google_rating: '구글 평점',
  google_search: '구글 검색/평점 보기',
  warning_list_long: '목록의 크기가 너무 커서 다 보여줄 수 없습니다. 필터링을 통해 지역을 조정하세요.',
  warning_reset_setting: '주의! 설정을 초기화하면 북마크도 모두 지워집니다.',
  not_tags: '연관어가 아닙니다.',
  use_tag_alts_1: '대안으로',
  use_tag_alts_2: '등을 사용해보시기 바랍니다.',
  new_place: '새 업체',
  new_event: '새 행사',
  new_ad: '광고',
  new_notice: '공지',
  no_bookmarks: '등록된 북마크가 없습니다.',
  out_of_canada_coordinate: '현재 위치가 캐나다가 아닙니다.',
  weight: '무게',
  pound: '파운드',
  kilogram: '킬로그램',
  volume: '부피',
  ounce: '온스',
  mililiter: '밀리리터',
  length: '길이',
  inch: '인치',
  centimeter: '센티미터',
  feet: '피트',
  temperature: '온도',
  fahrenheit: '화씨',
  celsius: '섭씨',
  delete_account_m: '계정 없애기',
  delete_account_guide_m: '이 동작은 포인트 점수를 포함하여 계정 정보를 완전히 삭제합니다.',
  delete_account_confirm_m: '정말로 계정을 삭제하시겠습니까?',
  delete_account_err: '계정 삭제 중 오류가 발생했습니다.',
  delete_account_success: '계정 삭제가 정상적으로 완료되었습니다.',
  email_used: '이미 사용되고 있는 이메일 주소입니다. 다른 이메일 주소를 사용해주세요.',
  email_notconfirmed: '확정을 기다리고 있는 이메일 주소입니다. 이 이메일 주소로 전송된 확정용 이메일을 확인해주세요.',
  comm_server:'서버와 통신 중입니다...',
  korean_food_list: '한국 음식 목록',
  only_mobileapp: '모바일앱에서만 지원됩니다.'
}