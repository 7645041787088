import React, { useState, useEffect, useContext } from 'react';
import {
  Image, Dimensions, Alert, StyleSheet, Text, TouchableOpacity, View, FlatList, PixelRatio,
  RefreshControl, StatusBar, Platform, Linking, SafeAreaView, TextInput, AppState, ActivityIndicator, Keyboard,
  TouchableWithoutFeedback,
  Modal, // use react-native's Modal.
  ScrollView, Share
} from 'react-native';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import HTML from 'react-native-render-html';
import { HeaderCenterText, HeaderOptionsImage, HeaderMenuImage, HeaderNotificationImage } from '../components/CustomHeader';
import openMap from 'react-native-open-maps';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import Communications from 'react-native-communications';
import { gql, useQuery, useMutation, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import i18n from 'i18n-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MyContext from '../components/MyContext';
import ApiKeys from '../constants/ApiKeys';
import { checkJwtValidity } from './MenuScreen';
import { areaList, LeftmostLng, RightmostLng } from '../assets/data/geo';
import RNPickerSelect from 'react-native-picker-select';
import { useIsFocused } from '@react-navigation/core';
import { sendLog } from '../components/StrapiInterface';
import analytics from '@react-native-firebase/analytics';
import * as Sentry from 'sentry-expo';
import Markdown from 'react-native-markdown-display';
import { MyButton } from '../components/MyComponents';
import RadioButtonGroup, { RadioButtonItem } from 'expo-radio-button';

const newclient = new ApolloClient({
  uri: ApiKeys.StrapiConfig.URI,
  cache: new InMemoryCache(),
});

const { width: windowFullWidth, height: windowHeight } = Dimensions.get('window');
const windowWidth = windowFullWidth > 800 ? 800 : windowFullWidth;
const fontScale = windowWidth > 480 ? 1.5 : 1;

const arrayDataMain = [
  { id: 10, title: 'clothes_c', image_url: require('../assets/images/ypage/shirt.png') },
  { id: 20, title: 'food_c', image_url: require('../assets/images/ypage/restaurant.png') },
  { id: 30, title: 'house_c', image_url: require('../assets/images/ypage/home.png') },
  { id: 40, title: 'car_c', image_url: require('../assets/images/ypage/car.png') },
  { id: 50, title: 'institution_c', image_url: require('../assets/images/ypage/institution.png') },
  { id: 60, title: 'playing_c', image_url: require('../assets/images/ypage/kite.png') },
  { id: 70, title: 'merchandise_c', image_url: require('../assets/images/ypage/box.png') },
  { id: 80, title: 'professional_service_c', image_url: require('../assets/images/ypage/briefcase.png') },
  { id: 90, title: 'life_edu_service_c', image_url: require('../assets/images/ypage/customer-service.png') },
  { id: 100, title: 'company_c', image_url: require('../assets/images/ypage/office-building.png') },
];

export default class YpageListScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      siteTitle: '',
      ad_banner_10: '',
    };
  }

  render() {
    return (
      <SafeAreaView style={styles.container} >
        <StatusBar barStyle="default" hidden={false} />

        <View style={{ flex: 1, marginLeft: 5, marginRight: 5, marginTop: 20 }}>
          <FlatList
            data={this.state.data}
            keyExtractor={item => item.title.toString()}
            horizontal={false}
            numColumns={3}
            //ListHeaderComponent={this.renderHeader}
            refreshControl={
              <RefreshControl
                refreshing={false}
                enabled={false}
              />
            }

            renderItem={({ item }) => {
              if (((item.title).trim() != "")) {
                return (
                  <TouchableOpacity
                    style={styles.ypageListItemContainer}
                    onPress={() => this.props.navigation.navigate('YpageMidlist',
                      {
                        title: item.title,
                        id: item.id,
                      })}
                  >
                    <View style={styles.ypageListItem}>
                      <Image source={item.image_url} style={styles.ypageListIcon}
                      />
                      <Text style={styles.ypageListTitle}>{i18n.t(item.title)} </Text>
                    </View>
                  </TouchableOpacity>
                )
              }
            }}

          //onEndReached={this.handleLoadMore}
          //onEndReachedThreshold={50}

          />
        </View>

      </SafeAreaView>
    );
  }

  _setArrayData() {
    this.setState({
      data: arrayDataMain,
    });

  }

  componentDidMount() {
    this.props.navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={i18n.t('directory_top')} />,
      headerTitleAlign: 'left',
      headerRight: () => <View style={{ flexDirection: 'row' }}>
        <HeaderNotificationImage onPress={() => this.props.navigation.navigate('NotificationList')} />
        <HeaderMenuImage onPress={() => this.props.navigation.navigate('MenuList')} />
      </View>,
    })

    this._setArrayData();

    const onFocus = this.props.navigation.addListener('focus', () => { console.log('will focus at YpageMain'); this._setArrayData(); });
  }
}

export class YpageMidlistScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      siteTitle: '',
      ad_banner_10: '',
    };
  }

  static contextType = MyContext;

  render() {
    return (
      <SafeAreaView
        style={styles.container}
      >
        <StatusBar barStyle="default" hidden={false} />

        <View style={{ flex: 1, marginLeft: 5, marginRight: 5, marginTop: 20 }}>
          <FlatList
            data={this.state.data}
            keyExtractor={item => item.title.toString()}
            horizontal={false}
            numColumns={3}
            //ListHeaderComponent={this.renderHeader}
            refreshControl={
              <RefreshControl
                refreshing={false}
                enabled={false}
              />
            }

            renderItem={({ item }) => {
              if (((item.title).trim() != "")) {
                return (
                  <TouchableOpacity
                    style={styles.ypageListItemContainer}
                    onPress={() => this.props.navigation.navigate('YpageSublist',
                      {
                        title: item.title,
                        id: item.id,
                        provinceIdx: this.context.myProvinceIdx,
                        localityIdx: this.context.myLocalityIdx,
                      })}
                  >
                    <View style={styles.ypageListItem}>
                      <Text style={styles.ypageListTitle}>{i18n.t(item.title)} </Text>
                    </View>
                  </TouchableOpacity>
                )
              }
            }}

          //onEndReached={this.handleLoadMore}
          //onEndReachedThreshold={50}

          />
        </View>

      </SafeAreaView>
    );
  }

  _setArrayData() {
    var arrayData = {
      '10': [{ title: 'hanbok', id: 0 },
      { title: 'kids_clothes', id: 0 },
      { title: 'regular_clothes', id: 0 },
      { title: 'fashion_clothes', id: 0 },
      { title: 'mens_clothes', id: 0 },
      { title: 'fur_leather', id: 0 },
      { title: 'underwear_socks', id: 0 },
      { title: 'hats', id: 0 }
      ], //의
      '20': [{ title: 'restaurant', id: 1000 },
      { title: 'cafe', id: 0 },
      { title: 'ricecake', id: 0 },
      { title: 'bakery', id: 0 },
      { title: 'grocery', id: 0 },
      { title: 'food_wholesale', id: 0 },
      { title: 'catering', id: 0 },
      { title: 'dessert', id: 0 },
      { title: 'food_delivery', id: 0 },
      { title: 'butcher_shop', id: 0 }
      ], //식
      '30': [{ title: 'accommodation', id: 0 },
      { title: 'realtor', id: 0 },
      { title: 'plumbing', id: 0 },
      { title: 'electric', id: 0 },
      { title: 'roofing', id: 0 },
      { title: 'kitchen', id: 0 },
      { title: 'light', id: 0 },
      { title: 'floor', id: 0 },
      { title: 'window', id: 0 },
      { title: 'interior', id: 0 },
      { title: 'landscape', id: 0 },
      { title: 'security', id: 0 },
      { title: 'house_clean', id: 0 },
      { title: 'pest_control', id: 0 }
      ], //주
      '40': [{ title: 'dealership', id: 0 },
      { title: 'repairshop', id: 0 },
      { title: 'carwash', id: 0 },
      { title: 'bodyshop', id: 0 },
      { title: 'car_rent', id: 0 },
      { title: 'towing', id: 0 },
      { title: 'tire', id: 0 },
      { title: 'rv', id: 0 }
      ], //자동차
      '50': [{ title: 'government', id: 0 },
      { title: 'religion', id: 0 },
      { title: 'volunteer', id: 0 },
      { title: 'media', id: 0 },
      { title: 'korean_community', id: 0 },
      { title: 'education', id: 0 }
      ], //기관/단체
      '60': [{ title: 'billiard', id: 0 },
      { title: 'karaoke', id: 0 },
      { title: 'travel_agent', id: 0 },
      { title: 'bar', id: 0 },
      { title: 'site', id: 0 }
      ], //놀거리
      '70': [{ title: 'medical_equip', id: 0 },
      { title: 'supplement', id: 0 },
      { title: 'jewelry', id: 0 },
      { title: 'cosmetic', id: 0 },
      { title: 'bag', id: 0 },
      { title: 'ice_cream', id: 0 },
      { title: 'bidet', id: 0 },
      { title: 'water_filter', id: 0 },
      { title: 'dollar_shop', id: 0 },
      { title: 'hearing_aid', id: 0 },
      { title: 'liquor', id: 0 },
      { title: 'tobacco', id: 0 },
      { title: 'farm', id: 0 },
      { title: 'restaurant_equip', id: 0 },
      { title: 'electronics', id: 0 },
      { title: 'printer_ink', id: 0 },
      { title: 'furniture', id: 0 },
      { title: 'bookstore', id: 0 },
      { title: 'flower', id: 0 },
      { title: 'sports_leisure', id: 0 },
      { title: 'bedding', id: 0 },
      { title: 'giftshop', id: 0 },
      { title: 'pet_product', id: 0 },
      { title: 'musical_instrument', id: 0 },
      { title: 'wig', id: 0 },
      { title: 'beauty_material', id: 0 },
      { title: 'kitchenware', id: 0 },
      ], //상품구매/상점
      '80': [{ title: 'lawyer', id: 0 },
      { title: 'accountant', id: 0 },
      { title: 'immigration', id: 0 },
      { title: 'translator', id: 0 },
      { title: 'construction', id: 1100 },
      { title: 'finance', id: 1200 },
      { title: 'realty', id: 1300 },
      { title: 'realty_mng', id: 0 },
      { title: 'medical_service', id: 1400 },
      { title: 'nursing_home', id: 0 },
      { title: 'funeral', id: 0 },
      { title: 'psychology_counselling', id: 0 },
      { title: 'health_counselling', id: 0 },
      { title: 'debt', id: 0 },
      { title: 'photo_studio', id: 0 },
      { title: 'design_ad', id: 0 },
      { title: 'print', id: 0 },
      { title: 'sw_dev', id: 0 },
      { title: 'av_solution', id: 0 },
      { title: 'pos_network', id: 0 },
      { title: 'security', id: 0 },
      { title: 'fire_equip', id: 0 },
      { title: 'pest_control', id: 0 },
      { title: 'machine_manufacture', id: 0 },
      { title: 'home_staging', id: 0 },
      ], //전문/자격 서비스
      '90': [{ title: 'piano_tuning', id: 0 },
      { title: 'billiard', id: 0 },
      { title: 'karaoke', id: 0 },
      { title: 'travel_agent', id: 0 },
      { title: 'daycare', id: 0 },
      { title: 'private_lesson', id: 1500 },
      { title: 'beauty', id: 1600 },
      { title: 'pet_grooming', id: 0 },
      { title: 'laundry', id: 0 },
      { title: 'matching', id: 0 },
      { title: 'event', id: 0 },
      { title: 'moving', id: 0 },
      { title: 'internet', id: 0 },
      { title: 'elec_repair', id: 0 },
      { title: 'landscape', id: 0 },
      { title: 'house_clean', id: 0 },
      { title: 'delivery', id: 0 },
      { title: 'exercise', id: 1700 },
      { title: 'ride', id: 0 },
      { title: 'housekeeping', id: 0 },
      { title: 'internet_cafe', id: 0 },
      { title: 'locksmith', id: 0 },
      { title: 'shoes_repair', id: 0 },
      { title: 'wedding', id: 0 },
      { title: 'sajoo', id: 0 },
      { title: 'video_rent', id: 0 },
      { title: 'embroidery', id: 0 },
      { title: 'trophy', id: 0 },
      { title: 'storage', id: 0 },
      { title: 'junk', id: 0 },
      { title: 'proxy_buy', id: 0 },
      { title: 'sleep_training', id: 0 },
      ], //생활/교육 서비스
      '100': [{ title: 'manufacturing', id: 0 },
      { title: 'dental_lab', id: 0 },
      { title: 'elec_part', id: 0 },
      { title: 'trading', id: 0 },
      { title: 'winery', id: 0 },
      { title: 'beekeeper', id: 0 },
      { title: 'branch_office', id: 0 },
      ], //기업체
    };
    var myid = this.props.route.params.id;
    this.setState({
      data: arrayData[myid],
    });
  }

  componentDidMount() {
    this.props.navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={"'" + i18n.t(this.props.route.params.title) + "' " + i18n.t('directory_top')} />,
      headerTitleAlign: 'left',
      headerRight: () => <HeaderMenuImage onPress={() => this.props.navigation.navigate('MenuList')} />,
    })

    this._setArrayData();

    const onFocus = this.props.navigation.addListener('focus', () => { console.log('will focus at YpageMidList'); this._setArrayData(); });

  }
}

const earth_radius = 6371.0; // km
const degrees_to_radians = Math.PI / 180.0;
const radians_to_degrees = 180.0 / Math.PI;

const distance_in_latitude = (km) => {
  return (km / earth_radius) * radians_to_degrees;
}

const distance_in_longitude = (latitude, km) => {
  let r = earth_radius * Math.cos(latitude * degrees_to_radians);
  return (km / r) * radians_to_degrees;
}

/*
 *  Show the list of places according to the value of id.
 *    id = -1 => using Tag 
 *    id = 0  => using low-level Category
 *    id > 0  => using high-level Category
 */
export const YpageSublistScreen = (props) => {
  const [loading, setLoading] = useState(true);
  const [places, setPlaces] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(i18n.t('view_all'));
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [provinceIdx, setProvinceIdx] = useState(props.route.params.provinceIdx ?? 0);
  const [localityIdx, setLocalityIdx] = useState(props.route.params.localityIdx ?? 0);
  const [modalMsg, setModalMsg] = useState('');

  var { myLocale, myProvinceIdx, myLocalityIdx, myGpsLatitude, myGpsLongitude, myApolloQueryTime, updateMyContext } = useContext(MyContext);

  console.log("[ YpageSublistScreen ] is called... provinceIdx:", provinceIdx, ", localityIdx:", localityIdx, ", modal:", searchModalVisible,
    ",", loading, ",", selectedCategory, ",", keyword, ",", modalMsg, ",", places ? places.length : 0);

  const category_map = {
    '1000': [
      'korean_food',
      'japanese_food',
      'chinese_food',
      'vietnamese_food',
      'chicken',
      'street_food',
      'pizza',
      'bar',
      'pork_cutlet',
      'western_food',
      'light_food',
      'skewer',
      'allyoucaneat',
    ], //식당
    '1100': [
      'architect',
      'renovation',
      'plumbing',
      'electric',
      'roofing',
      'kitchen',
      'light',
      'floor',
      'window',
      'interior',
      'landscape',
      'disaster',
    ], //주택/건축
    '1200': [
      'mortgage',
      'bank',
      'loan',
      'insurance'
    ], //금융
    '1300': [
      'realtor',
      'home_inspector',
      'lawyer',
      'appraiser'
    ], //부동산/거래
    '1400': [
      'oriental_clinic',
      'hospital',
      'pharmacy',
      'dentist',
      'eyeglasses',
      'nursing',
      'physiotherapy',
      'pet_clinic'
    ], //의료/병원
    '1500': [
      'martial_lesson',
      'tutoring',
      'driving_lesson',
      'art_lesson',
      'music_lesson',
      'language_lesson',
      'sport_lesson',
      'sw_lesson',
      'barister_lesson',
      'cooking_lesson',
      'korean_culture_lesson',
      'calligraphy',
      'sewing',
      'craftwork',
      'debate',
    ], //학원교습
    '1600': [
      'hair_salon',
      'spa',
      'tattoo',
      'yoga',
      'dance'
    ], //뷰티/몸가꾸기
    '1700': [
      'golf',
      'yoga',
      'dance',
      'danjeon',
      'fishing',
    ], //운동/체력단련
  };

  const myid = props.route.params.id ?? '0';
  const mytitle = props.route.params.title ?? i18n.t('none');
  var filter_param = "";

  if (myid == '0' || myid == '-1') {
    filter_param = '"' + i18n.t(mytitle, { locale: 'ko' }) + '"';
  } else {
    for (let i = 0; i < category_map[myid].length; i++) {
      filter_param = filter_param + '"' + i18n.t(category_map[myid][i], { locale: 'ko' }) + '", ';
    }
  }

  //console.log("[ YpageSublistScreen ] Filter_param:" + filter_param);

  var where_cond = '';
  var area_radius = 25;
  var center_latitude = 51.0447;
  var center_longitude = -114.0719;
  var lat_delta = 0.45; // radius 50km
  var lng_delta = 0.45; // radium 50km in calgary
  var provinceList = [];
  var localityList = [];
  var localityLabelList = '';
  var myFetchPolicy = 'cache-first';

  for (let i = 0; i < areaList.length; i++) {
    provinceList = [...provinceList, { label: i18n.t(areaList[i].label), value: areaList[i].value }];
  }

  for (let i = 0; i < areaList[provinceIdx].cities.length; i++) {
    localityList = [...localityList, { label: i18n.t(areaList[provinceIdx].cities[i].label), value: areaList[provinceIdx].cities[i].value }];
    localityLabelList = localityLabelList + `"${areaList[provinceIdx].cities[i].label}", `;
  }

  if (keyword) {
    where_cond = `_and: [ { _or: [ {name_contains: "${keyword}"}, {name_en_contains: "${keyword}"} ] }, `;
  } else {
    where_cond = `_and: [ `;
  }

  // We do not use myProvinceIdx and myLocalityIdx context for setting search.
  // Instead we use a sort of local copies of the context variable in order to
  // the change of searching criteria would not affect the user's setting values.
  if (provinceIdx === 0) {
    switch (localityIdx) {
      case 0: area_radius = 5; break;
      case 1: area_radius = 15; break;
      case 2: area_radius = 25; break;
      case 3: area_radius = 50; break;
      default: area_radius = 15; break;
    }
    lat_delta = distance_in_latitude(area_radius);
    lng_delta = distance_in_longitude(myGpsLatitude, area_radius);
    center_latitude = myGpsLatitude;
    center_longitude = myGpsLongitude;

    //    where_cond = where_cond + `geo_lat_lte: ${myGpsLatitude + lat_delta} , geo_lat_gte: ${myGpsLatitude - lat_delta}, geo_lng_lte: ${myGpsLongitude + lng_delta}, geo_lng_gte: ${myGpsLongitude - lng_delta}, `;
    where_cond = where_cond + `{ _or: [ { lstatus: "online"}, { geo_lat_lte: ${myGpsLatitude + lat_delta} , geo_lat_gte: ${myGpsLatitude - lat_delta}, geo_lng_lte: ${myGpsLongitude + lng_delta}, geo_lng_gte: ${myGpsLongitude - lng_delta} } ] } ] `;

    console.log("@@@@@@", myApolloQueryTime);
    if (Date.now() - myApolloQueryTime > 1000 * 60 * 10) {
      myFetchPolicy = 'network-only'; // ignore cache after 10 min from the last fetch.
    }
  }
  else {
    center_latitude = areaList[provinceIdx].cities[localityIdx].lat;
    center_longitude = areaList[provinceIdx].cities[localityIdx].lng;

    //    where_cond = where_cond + `province: "${areaList[provinceIdx].label}", geo_lat_gt: "0", `;
    where_cond = where_cond + `{ _or: [ { lstatus: "online" } , { province: "${areaList[provinceIdx].label}", geo_lat_gt: "0", `;

    switch (areaList[provinceIdx].cities[localityIdx].value) {
      case '100': where_cond = where_cond + `locality_nin: [ ${localityLabelList} ] } ] } ] `;
        break;
      case '1000': where_cond = where_cond + ` } ] } ] `;// no need to include locality.
        break;
      default: where_cond = where_cond + `locality: "${areaList[provinceIdx].cities[localityIdx].label}" } ] } ] `;
        break;
    }
  }

  var sort_cond = i18n.locale === 'ko' ? "name:asc, name_en:asc" : "name_en:asc,name:asc";

  console.log("[ YpageSublistScreen ] where_cond: ", where_cond);

  const _getData = () => {
    // If provinceIdx is not initialized, do not get data from server.
    // If this screen is not focused, do not get data from server.

    console.log("[ YpageSublistScreen ] (_getData) is called. sort_cond: ", sort_cond, ", filter_param: ", filter_param, ", where_cond: ", where_cond);

    if (Platform.OS === 'android' || Platform.OS === 'ios')
      analytics().logEvent('YpageMain', {
        flag: myid,
        param: filter_param,
        provinceIdx: provinceIdx,
        localityIdx: localityIdx,
      });

    if (provinceIdx === 0 && (myGpsLongitude < LeftmostLng || myGpsLongitude > RightmostLng)) {
      setModalMsg(i18n.t('out_of_canada_coordinate')); // Alert.alert(i18n.t('selected_nothing'));
      setLoading(false);
      return;
    }

    setLoading(true);

    if (myid == '-1') {
      var GET_MASTERS = gql`
        query getMasters {
          masters (sort: "${sort_cond}", where: { tags: { name_in: [${filter_param}] }, ${where_cond} }) {
            id,
            name,
            name_en,
            remark,
            remark_en,
            note,
            note_en,
            formatted_address,
            phone1,
            phone2,
            email1,
            web,
            geo_lat,
            geo_lng,
            categories { name },
            tags { name }
          }
        }
      `;
    } else {
      var GET_MASTERS = gql`
        query getMasters {
          masters (sort: "${sort_cond}", where: { categories: { name_in: [${filter_param}] }, ${where_cond} }) {
            id,
            name,
            name_en,
            remark,
            remark_en,
            note,
            note_en,
            formatted_address,
            phone1,
            phone2,
            email1,
            web,
            geo_lat,
            geo_lng,
            categories { name },
            tags { name }
          }
        }
      `;
    }

    newclient.query({
      query: GET_MASTERS,
      fetchPolicy: myFetchPolicy
    })
      .then(res => {
        //console.log(res);
        if (res) {
          //console.log("[ YpageSublistScreen ] masters: ", JSON.stringify(res.data.masters));

          var final_res = [];

          if (res.data.masters.length > 250) {
            setModalMsg(i18n.t('warning_list_long'));
          }
          /* ??? why is the keyword used in filtering address and phone1 
          const filteredData = res.data.masters.filter(el => {
            for (let i = 0; i < res.data.masters.length; i++) {
              if (el.name.includes(keyword) || el.formatted_address.includes(keyword) || (el.phone1 && el.phone1.includes(keyword))) return true;
            }
          });
          */
          const filteredData = [...res.data.masters];

          if (myid == '0' || myid == '-1') {
            final_res.push({ 'sub_id': myid, 'title': i18n.t(mytitle), 'data': filteredData });
            setSelectedCategory(i18n.t(mytitle));
          } else {
            final_res.push({ 'sub_id': 100000, 'title': i18n.t('view_all'), 'data': '' });
            for (let i = 0; i < category_map[myid].length; i++) {
              let subData = filteredData.filter(el => {
                for (let j = 0; j < el.categories.length; j++) {
                  if (el.categories[j].name == i18n.t(category_map[myid][i], { locale: 'ko' })) return true;
                };
              });
              final_res.push({ 'sub_id': myid, 'title': i18n.t(category_map[myid][i]), 'data': subData });
              //if (i == 0) setSelectedCategory(i18n.t(category_map[myid][i]));
            }
          }

          //console.log("Final_RES: " + JSON.stringify(final_res));
          setPlaces(final_res);

        }
        setLoading(false);
        if (myFetchPolicy === 'network-only')
          updateMyContext({ myApolloQueryTime: Date.now() });
      })
      .catch(err => {
        sendLog(i18n.t('search_err') + '[e11]' + JSON.stringify(err));
        Alert.alert(i18n.t('search_err') + ' ' + i18n.t('internet_err'));
        console.log("[ YpageSublistScreen ] _getData: ", err)
        setLoading(false);
      });
  }

  const _showSearchModal = () => {
    setSearchModalVisible(true);
    console.log('Showing searchModal ...: ', searchModalVisible);
  }

  const _hideSearchModal = (f) => {
    console.log('Hiding Modal ... flag: ', f);
    setSearchModalVisible(false);
    if (f) _getData();

  }


  // useEffect should come before the following if (loading) ... statement.
  // If useEffect is located after that, useEffect would not be called, which induces the error of
  // "Rendered more hooks than during the previous render".
  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={i18n.t('directory_sublist')} />,
      headerTitleAlign: 'left',
      headerRight: () => <HeaderOptionsImage onPress={_showSearchModal} />,
    })

    console.log("[ YpageSublistScreen ] useEffect() is called. filtering: " + keyword, ", params provinceIdx: ", props.route.params.provinceIdx);

    if (searchModalVisible === false) {
      _getData();
    }

  }, [provinceIdx, localityIdx]);

  return (
    <SafeAreaView style={{ flex: 1 }}
      stickyHeaderIndices={[1]}
    >

      <Modal
        visible={modalMsg ? true : false}
        transparent={true}>
        <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
          <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
            <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
              <Text style={styles.modalText}>{modalMsg}</Text>
              <MyButton
                onPress={() => setModalMsg('')}
                children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
              />
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        visible={searchModalVisible}
        transparent={true}
      >
        <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
          <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
            <View style={{ marginHorizontal: 15 }}>
              <Text style={{ fontSize: 17 * fontScale, fontWeight: 'bold', color: '#c1262d', textAlign: 'left', marginTop: 20, marginBottom: 10 }}>{i18n.t('options')} </Text>
              <View style={{ marginVertial: 20 }}>
                <Text style={{ fontSize: 14 * fontScale }}>{i18n.t('filtering_letters')}</Text>
                <TextInput style={{ borderColor: '#272727', marginVertical: 10, height: 32 * fontScale, borderWidth: 0.5, fontSize: 14 * fontScale }}
                  value={keyword}
                  onChangeText={(text) => { setKeyword(text.trim()) }}
                  placeholder={i18n.t('only_one_word')}
                  placeholderTextColor="#d0d0d0"
                  autoCapitalize="none"
                  autoCorrect={false}
                />
              </View>
              <View style={{ marginVertical: 20 }}>
                <Text style={{ fontSize: 14 * fontScale }}>{i18n.t('area_for_search')}</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10 }} >
                  <RNPickerSelect
                    style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
                    value={provinceList[provinceIdx].value}
                    useNativeAndroidPickerStyle={false}
                    fixAndroidTouchableBug={true}
                    placeholder={{}}
                    onValueChange={(value, index) => {
                      //console.log("...RNPickerSelect province... index: ", index);
                      setProvinceIdx(index);
                      setLocalityIdx(0);
                    }}
                    Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
                    items={provinceList}
                  />
                  <RNPickerSelect
                    style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
                    value={localityList[localityIdx].value}
                    useNativeAndroidPickerStyle={false}
                    fixAndroidTouchableBug={true}
                    placeholder={{}}
                    onValueChange={(value, index) => {
                      //console.log("...RNPickerSelect locality... index: ", index);
                      setLocalityIdx(index);
                    }}
                    Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
                    items={localityList}
                  />
                </View>
              </View>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginVertical: 20 }}>
              <MyButton
                onPress={() => _hideSearchModal(false)}
                children={() => <Text style={styles.appButtonText}>{i18n.t('cancel_m')}</Text>}
              />

              <MyButton
                onPress={() => _hideSearchModal(true)}
                children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
              />
            </View>
          </View>

        </View>
      </Modal>

      <StatusBar barStyle="default" hidden={false} />
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: 'white' }}>
        <Text style={{ fontSize: 12 * fontScale }}>{i18n.t(areaList[provinceIdx].label)} / {i18n.t(areaList[provinceIdx].cities[localityIdx].label)}{keyword ? `, ${i18n.t('filtering')}: '${keyword}' ` : ` `}</Text>
      </View>
      <View style={{
        paddingHorizontal: 10, paddingVertical: 5, backgroundColor: '#272727',
        borderTopWidth: 1, borderTopColor: '#fff'
      }}>
        <FlatList
          horizontal
          data={places}
          keyExtractor={(item, index) => index.toString()}
          //numColumns={4}
          //ListHeaderComponent={this.renderHeader}
          ListFooterComponent={
            places.length && places[0].data.length && (myid == '-1' || myid == '0') ? // tag or category
              <TouchableOpacity
                onPress={() => props.navigation.navigate('SearchYpageMap', {
                  lat: center_latitude,
                  lng: center_longitude,
                  lat_delta: lat_delta,
                  lng_delta: lng_delta,
                  markers: places[0].data,
                  provinceIdx: provinceIdx,
                  localityIdx: localityIdx
                })}
                style={{ marginTop: 10, marginLeft: 10, backgroundColor: 'rgba(255, 255, 255, 0.3)', borderRadius: 5 }}
              >
                  <Text style={{ color: 'white', fontSize: 12 * fontScale, padding: 5 }}>
                  {i18n.t('mapview')}  <FontAwesome name="map-marker" size={12} /> 
                  </Text>
              </TouchableOpacity>
              :
              false
          }

          renderItem={({ item }) => {
            //console.log(item);
            if ((item.title).trim() != "") {
              return (
                <TouchableOpacity
                  onPress={() => {
                    console.log('Change the selected category: ' + item.title + ', ' + selectedCategory);
                    setSelectedCategory(item.title);
                  }}
                  style={{ marginVertical: 5 }}
                >
                  <View style={[styles.ypageSublistCatItem, { backgroundColor: item.title == selectedCategory ? '#f6c417' : 'black' }]}>
                    <Text style={[styles.ypageSublistCatTitle, { color: item.title == selectedCategory ? '#202020' : '#e0e0e0' }]}>{item.title} </Text>
                  </View>
                </TouchableOpacity>
              )
            }
          }}
        />
      </View>

      {loading ? <View style={{ marginTop: 50 }}><Text style={{ fontSize: 16 * fontScale, textAlign: 'center', color: 'grey', marginBottom: 10 }}>{i18n.t("comm_server")}</Text><ActivityIndicator color='grey' /></View>
        :
        <FlatList
          data={places}
          keyExtractor={(item, index) => index.toString()}
          //horizontal={true}
          //ListHeaderComponent={this.renderHeader}
          style={{ backgroundColor: '#e2e2e2' }}
          bounces={true}

          renderItem={({ item }) => {
            //console.log(item.title);
            //console.log('rendering: ' + item.title + ', ' + selectedCategory);
            if (((item.title).trim() != "" && (item.title).trim() != i18n.t('view_all')) &&
              (selectedCategory == i18n.t('view_all') || selectedCategory == item.title)) {
              return (
                <View style={{ paddingBottom: 20 }}>
                  <View style={[styles.ypageSublistCatItem2, { display: places.length > 1 ? "flex" : "none", flexDirection: 'row', justifyContent: 'space-between' }]}>
                    <Text style={styles.ypageSublistCatTitle2}>{item.title}</Text>
                    {item.data.length ?
                      <TouchableOpacity
                        onPress={() => props.navigation.navigate('SearchYpageMap', {
                          lat: center_latitude,
                          lng: center_longitude,
                          lat_delta: lat_delta,
                          lng_delta: lng_delta,
                          markers: item.data,
                          provinceIdx: provinceIdx,
                          localityIdx: localityIdx
                        })}
                        style={styles.ypageSublistMapButton}
                      >
                        <Text style={{ fontSize: 12 * fontScale, color: 'white', paddingHorizontal: 10 }}>
                          {i18n.t('mapview')}  <FontAwesome name="map-marker" size={14} />
                        </Text>
                      </TouchableOpacity>
                      :
                      false
                    }
                  </View>

                  <FlatList
                    data={item.data}
                    keyExtractor={(item, index) => index.toString()}
                    initialNumToRender={30}
                    ListEmptyComponent={
                      <View style={{ padding: 10, backgroundColor: '#e2e2e2' }}>
                        {loading === false ? <Text style={styles.basic}>{i18n.t('nothing_to_show')}</Text> : false}
                      </View>
                    }

                    renderItem={({ item }) => {
                      //console.log(item);
                      let title;
                      let title_full;
                      let remark;
                      let note;
                      if (i18n.locale === 'en' && item.name_en) {
                        title = item.name_en;
                        title_full = item.name_en;
                      }
                      else {
                        title = item.name;
                        title_full = item.name + (item.name_en ? ' / ' + item.name_en : '');
                      }
                      if (i18n.locale === 'en') {
                        remark = item.remark_en ? item.remark_en.trim() : '';
                        note = item.note_en ? item.note_en.trim() : '';
                      }
                      else {
                        remark = item.remark ? item.remark.trim() : '';
                        note = item.note ? item.note.trim() : '';
                      }
                      return (
                        <TouchableOpacity
                          style={styles.ypageSublistItemContentContainer}
                          onPress={() => props.navigation.push('YpageArticle',
                            {
                              title: title_full,
                              formatted_address: item.formatted_address,
                              phone1: item.phone1,
                              phone2: item.phone2,
                              email: item.email1,
                              web: item.web,
                              lat: item.geo_lat,
                              lng: item.geo_lng,
                              itemId: item.id,
                              provinceIdx: provinceIdx,
                              localityIdx: localityIdx,
                              tags: item.tags,
                              note: note,
                            })}
                        >
                          <View
                            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                          >
                            <Text style={styles.ypageSublistItemContent1}>{title}</Text>
                            <TouchableOpacity style={{ width: '36%' }} onLongPress={() => Communications.phonecall(item.phone1, true)}>
                              <Text style={styles.ypageSublistItemContent2}>{item.phone1 ? item.phone1.replace(/[1]*(\d{3})(\d{3})(\d{4})/i, '$1-$2-$3') : ''}</Text>
                            </TouchableOpacity>
                          </View>
                          {remark ? <View><Text numberOfLines={1} style={{ marginHorizontal: 10, marginTop: 3, color: '#a0a070' }}>{remark}</Text></View> : false}
                        </TouchableOpacity>
                      )
                    }}

                    style={{ backgroundColor: '#ffffff' }}
                  />
                </View>
              )
            }
          }}
        />
      }
    </SafeAreaView>
  );
}

const RateStars = ({ rating }) => {
  let yellowStarCount = Math.floor(rating);
  let halfStarCount = rating - yellowStarCount ? 1 : 0;
  let grayStarCount = 5;

  //console.log("** RateStarts: ", yellowStarCount, ", ", halfStarCount, ", ", grayStarCount);

  return (
    <View>
      <View style={{ flexDirection: 'row' }}>
        {Array(grayStarCount).fill().map((e, i) => <FontAwesome key={i.toString()} name="star" size={16} color="#cdcdd0" />)}
      </View>
      <View style={{ flexDirection: 'row', position: 'absolute', top: 0, left: 0 }}>
        {Array(yellowStarCount).fill().map((e, i) => <FontAwesome key={i.toString()} name="star" size={16} color="#f6c417" />)}
        {Array(halfStarCount).fill().map((e, i) => <FontAwesome key={i.toString()} name="star-half" size={16} color="#f6c417" />)}
      </View>
    </View>
  )
}

export class YpageArticleScreen extends React.Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);
    this.state = {
      bookmarks: [],
      reported: false,
      modalMsg: '',
    };
  }

  _checkReportStatus = async () => {
    const client_no_jwt = new ApolloClient({
      uri: ApiKeys.StrapiConfig.URI,
      cache: new InMemoryCache(),
    });

    const GET_REPORTS = gql`
      query getReports {
        reports (where: { mid: ${this.props.route.params.itemId}, status: 0 } ) {
          id,
          email,
          mid
        },
      }
    `;

    try {
      const res = await client_no_jwt.query({
        query: GET_REPORTS,
        fetchPolicy: 'no-cache',
      });

      //console.log(res);
      if (res) {
        let reports = res.data.reports;
        console.log("reports: ", JSON.stringify(reports));
        if (reports.length > 0) this.setState({ reported: true });
      }

    } catch (err) {
      if (Platform.OS === 'android' || Platform.OS === 'ios')
        Sentry.Native.captureException(err);
      Alert.alert(i18n.t('access_report_err')); console.log("_checkReportStatus: ", err)
    }

  }

  _showupMap = (addr) => {
    openMap({ end: addr, provider: "google" });
  }

  _toggleBookmark = (id) => {
    /* allow the bookmarking without login.
    if (!this.context.myEmail) {
      Alert.alert(i18n.t('please_login'));
      return;
    }*/
    console.log('Toggling Bookmark id: ' + id + ' ... Existing Bookmarks: ' + this.state.bookmarks);
    // Check whether this is currently bookmarked.

    AsyncStorage.getItem('Bookmarks-Ypages')
      .then(res => {
        let stored_bookmarks;
        //console.log('result of AsyncStorage(Bookmarks-Ypages) getItem: ' + res);
        if (res != null)
          stored_bookmarks = JSON.parse(res);

        console.log('Get Ypage bookmarks from Asyncstorage: ' + stored_bookmarks);

        let new_bookmarks;

        if (stored_bookmarks && stored_bookmarks.indexOf(id) != -1) { // already have
          console.log('...Bookmark found');
          new_bookmarks = stored_bookmarks.filter(i => i != id);
          console.log('After removing a ypage => bookmarks: ' + new_bookmarks);
        }
        else {
          console.log('... Bookmark not found');
          if (stored_bookmarks && stored_bookmarks.length >= 5) {
            new_bookmarks = stored_bookmarks;
            this.setState({ modalMsg: i18n.t('bookmarks_overflow') });
            console.log('bookmarks cannot exceed 5.');
          } else {
            new_bookmarks = stored_bookmarks ? [...stored_bookmarks, id] : [id];

            if (Platform.OS === 'android' || Platform.OS === 'ios')
              analytics().logEvent('Bookmark', {
                id: id
              });

            console.log('After adding a ypage => bookmarks: ' + new_bookmarks);
          }
        }

        AsyncStorage.setItem('Bookmarks-Ypages', JSON.stringify(new_bookmarks))
          .then(res => {
            console.log('result of AsyncStorage(Bookmarks-Ypages) setItem: Success');
            this.setState({ bookmarks: new_bookmarks });
          })
          .catch(err => {
            if (Platform.OS === 'android' || Platform.OS === 'ios')
              Sentry.Native.captureException(err);
            console.log('Error: Fail to set Ypage bookmarks into Asyncstorage.' + err);
            return;
          })
      })
      .catch(err => {
        if (Platform.OS === 'android' || Platform.OS === 'ios')
          Sentry.Native.captureException(err);
        console.log('Error: Fail to get Ypages bookmarks from Asyncstorage.' + err);
        return;
      })

  }

  _readAsyncStorage() {
    AsyncStorage.getItem('Bookmarks-Ypages')
      .then(res => {
        let stored_bookmarks;
        console.log('result of AsyncStorage(Bookmarks-Ypages) getItem: Success');
        if (res != null)
          stored_bookmarks = JSON.parse(res);

        console.log('Get Ypage bookmarks from Asyncstorage: ' + stored_bookmarks);

        this.setState({ bookmarks: stored_bookmarks });
      })
      .catch(err => {
        if (Platform.OS === 'android' || Platform.OS === 'ios')
          Sentry.Native.captureException(err);
        console.log('Error: Fail to get Ypages bookmarks from Asyncstorage.' + err);
        return;
      })
  }

  _showupShareYpage = async (id, title, content) => {
    if (Platform.OS === "web") {
      alert(i18n.t('only_mobileapp'));
      return;
    }

    if (Platform.OS === 'android' || Platform.OS === 'ios')
      await analytics().logEvent('Share', {
        title: title
      });

    try {
      const result = Share.share({
        message: 'CAKORY ' + i18n.t('share_place') + '\n\n' + title + '\n' + content + '\n\n' + i18n.t('share_cakory_footer'),
        title: 'CAKORY ' + i18n.t('share_place')
      }, {
        // Android only:
        dialogTitle: 'CAKORY ' + i18n.t('share_place'),
        // iOS only:
        subject: 'CAKORY ' + i18n.t('share_place'),
      })
    } catch (err) {
      if (Platform.OS === 'android' || Platform.OS === 'ios')
        Sentry.Native.captureException(err);
      Alert.alert(i18n.t('sharing_err'));
    }
  }

  componentDidMount() {
    this.props.navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={i18n.t('detailed')} />,
      headerTitleAlign: 'center',
      headerRight: () => <HeaderMenuImage onPress={() => this.props.navigation.navigate('MenuList')} />,
    })

    if (this.context.myEmail) checkJwtValidity(this.context.myEmail, this.context.myJwt, this.context.updateMyContext);

    this._checkReportStatus();
    this._readAsyncStorage();
    const onFocus = this.props.navigation.addListener('focus', () => { console.log('will focus at YpageArticle'); this._checkReportStatus(); this._readAsyncStorage() });
  }

  render() {
    var title = this.props.route.params.title ?? 'No Title';
    var formatted_address = this.props.route.params.formatted_address ?? 'Not available';
    var phone1 = this.props.route.params.phone1 ?? '';
    var phone2 = this.props.route.params.phone2 ?? '';
    var email = this.props.route.params.email ?? '';
    var lat = this.props.route.params.lat ?? '';
    var lng = this.props.route.params.lng ?? '';
    var provinceIdx = this.props.route.params.provinceIdx ?? 0;
    var localityIdx = this.props.route.params.localityIdx ?? 0;
    var itemId = this.props.route.params.itemId ?? '';
    var tags = this.props.route.params.tags ?? [];
    var note = this.props.route.params.note ?? '';

    var web = '';
    if (this.props.route.params.web) {
      let pattern = /https?:\/\//;
      if (pattern.test(this.props.route.params.web)) web = this.props.route.params.web;
      else web = "http://" + this.props.route.params.web;
    };

    return (
      <ScrollView style={{ flex: 1, width: "100%", alignSelf: 'center', paddingHorizontal: 10 }}>
        <StatusBar barStyle="default" hidden={false} />

        <Modal
          visible={this.state.modalMsg ? true : false}
          transparent={true}>
          <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
            <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
              <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
                <Text style={styles.modalText}>{this.state.modalMsg}</Text>
                <MyButton
                  onPress={() => this.setState({ modalMsg: '' })}
                  children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
                />
              </View>
            </View>
          </View>
        </Modal>

        <View style={{ height: 100, justifyContent: 'center', marginLeft: 5 }}>
          <Text style={{
            fontSize: 20 * fontScale, fontWeight: 'bold', maxWidth: '70%'
          }}>
            {title}
          </Text>
        </View>
        <TouchableOpacity
          style={{ position: 'absolute', top: 20, right: 45 * fontScale }}
          onPress={() => this._showupShareYpage(itemId, title, i18n.t('phonenum') + ': ' + phone1 +
            '\n' + i18n.t('address') + ': ' + formatted_address +
            '\nWeb: ' + web)}
        >
          <Ionicons name="share-outline" size={24 * fontScale} color="black" />
        </TouchableOpacity>
        <TouchableOpacity
          style={{ position: 'absolute', top: 20, right: 10 }}
          onPress={() => this._toggleBookmark(itemId)}
        >
          {this.state.bookmarks && this.state.bookmarks.indexOf(itemId) != -1 ?
            <Ionicons name="bookmark" size={24 * fontScale} color="#2f95dc" />
            :
            <Ionicons name="bookmark-outline" size={24 * fontScale} color="black" />
          }
        </TouchableOpacity>

        {this.state.reported ?
          <TouchableOpacity
            style={{ backgroundColor: 'darkgray', padding: 5, borderRadius: 5, position: 'absolute', top: 28 + 24 * fontScale, right: 12 }}
          >
            <Text style={{ fontSize: 13 * fontScale, color: 'white', textAlign: 'right' }}>{i18n.t('under_review')}</Text>
          </TouchableOpacity>
          :
          <TouchableOpacity
            style={{ backgroundColor: 'transparent', padding: 5, borderRadius: 5, position: 'absolute', top: 28 + 24 * fontScale, right: 12, borderWidth: 1, borderColor: 'black' }}
            onPress={() => {
              if (!this.context.myEmail) {
                this.setState({ modalMsg: i18n.t('please_login') }); //Alert.alert(i18n.t('please_login'));
              } else {
                this.props.navigation.navigate("ReportError", { id: itemId, name: title })
              }
            }}
          >
            <Text style={{ fontSize: 13 * fontScale, color: 'black' }}>{i18n.t('report_error')}</Text>
          </TouchableOpacity>
        }

        <View style={{ flexDirection: 'row', fontSize: 15 * fontScale, marginTop: 0, alignItems: 'center' }}>
          <View style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#c07000', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
            <Ionicons name="location" size={24} color="white" />
          </View>
          <Text style={{ flex: 1, fontSize: 15 * fontScale }}>{formatted_address}</Text>
        </View>
        {phone1 ?
          <View style={{ flexDirection: 'row', fontSize: 15 * fontScale, marginTop: 10, alignItems: 'center' }}>
            <View style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#c07000', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
              <Ionicons name="call" size={22} color="white" />
            </View>
            <TouchableOpacity onPress={() => Communications.phonecall(phone1, true)}>
              <Text style={{ fontSize: 15 * fontScale }}>{phone1.replace(/[1]*(\d{3})(\d{3})(\d{4})/i, '$1-$2-$3')}</Text>
            </TouchableOpacity>
          </View>
          :
          false
        }
        {phone2 ?
          <View style={{ flexDirection: 'row', fontSize: 15 * fontScale, marginTop: 10, alignItems: 'center' }}>
            <View style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#c07000', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
              <Ionicons name="call" size={22} color="white" />
            </View>
            <TouchableOpacity onPress={() => Communications.phonecall(phone2, true)}>
              <Text style={{ fontSize: 15 * fontScale }}>{phone2.replace(/[1]*(\d{3})(\d{3})(\d{4})/i, '$1-$2-$3')}</Text>
            </TouchableOpacity>
          </View>
          :
          false
        }
        {email ?
          <View style={{ flexDirection: 'row', fontSize: 15 * fontScale, marginTop: 10, alignItems: 'center' }}>
            <View style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#c07000', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
              <Ionicons name="mail" size={22} color="white" />
            </View>
            <TouchableOpacity onPress={() => Linking.openURL('mailto:' + email)}>
              <Text style={{ fontSize: 15 * fontScale }}>{email}</Text>
            </TouchableOpacity>
          </View>
          :
          false
        }
        {web ?
          <View style={{ flexDirection: 'row', fontSize: 15 * fontScale, marginTop: 10, alignItems: 'center' }}>
            <View style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#c07000', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
              <Ionicons name="earth" size={22} color="white" />
            </View>
            <Text style={{ fontSize: 15 * fontScale }} onPress={() => { Linking.openURL(web) }} >{web}</Text>
          </View>
          :
          false
        }
        <View style={{ flexDirection: 'row', fontSize: 15 * fontScale, marginTop: 10, alignItems: 'center' }}>
          <View style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#c07000', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
            <Ionicons name="logo-google" size={22} color="white" />
          </View>
          <Text style={{ fontSize: 15 * fontScale }} onPress={() => { Linking.openURL("https://google.com/search?q=" + title + "+" + formatted_address) }}>{i18n.t('google_search')} </Text>
          <Ionicons style={{ marginLeft: 10 }} name="open-outline" size={20} color="grey" onPress={() => { Linking.openURL("https://google.com/search?q=" + title + "+" + formatted_address) }} />
        </View>
        {note ?
          <View style={{ marginTop: 20, paddingVertical: 20, paddingHorizontal: 10, borderRadius: 5, backgroundColor: 'white' }}>
            <Text style={{ fontSize: 15 * fontScale }} ><Markdown>{note}</Markdown></Text>
          </View>
          :
          false
        }
        <View style={{ flexDirection: 'column', justifyContent: 'flex-start', marginTop: 20, marginBottom: 10 }}>
          <ScrollView
            contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {
              tags.map((item, index) => (
                <MyButton key={index}
                  style={{ margin: 5 }}
                  onPress={() => this.props.navigation.push('YpageSublist',
                    {
                      title: item.name,
                      id: -1,
                      provinceIdx: provinceIdx,
                      localityIdx: localityIdx,
                    })}
                  children={() => <Text style={[styles.basic, { color: 'white' }]}>{i18n.t(item.name)}</Text>}
                />
              ))
            }
          </ScrollView>
        </View>
        <MapView
          provider={ PROVIDER_GOOGLE }
          style={{
            width: windowWidth,
            height: windowWidth - 50,
            marginTop: 0,
            marginBottom: 20,
          }}
          initialRegion={{
            latitude: Number(lat),
            longitude: Number(lng),
            latitudeDelta: 0.0690, //0.0922,
            longitudeDelta: 0.0315, //0.0421
          }}
        >
          <Marker
            key={0}
            coordinate={{ latitude: Number(lat), longitude: Number(lng) }}
            title={title}
            description={formatted_address}
          />
        </MapView>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
          <MyButton
            style={{ width: '45%', justifyContent: 'center' }}
            onPress={() => this._showupMap(formatted_address)}
            children={() => <Text style={styles.appButtonText}>{i18n.t('directions')}</Text>}
          />
          <Text style={{ width: '55%', marginLeft: 20, marginRight: 5, fontSize: 12 * fontScale, flex: 1, alignSelf: 'center' }}>{i18n.t('notice_caution')}</Text>
        </View>
      </ScrollView>
    );
  }
}

export function ReportErrorScreen({ navigation, route }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [inputText, setInputText] = useState('');
  const [modalMsg, setModalMsg] = useState('');
  const { myId, myEmail, myJwt, updateMyContext } = useContext(MyContext);

  const inputTextFiltered = inputText.replace(/\r?\n|\r/g, '');

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={i18n.t('report_error')} />,
      headerTitleAlign: 'center',
    })
  });

  if (myId == -1) {
    return (
      <View style={{ flex: 1, textAlign: 'center' }}><Text>{i18n.t('please_login')}</Text></View>
    )
  }

  console.log("(ReportErrorScreen): myId ", myId, ", myEmail ", myEmail, ", mid ", route.params.id);

  const httpLink = new HttpLink({ uri: ApiKeys.StrapiConfig.URI });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${myJwt}`,
      }
    }));

    return forward(operation);
  })

  const client_w_jwt = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
  });

  const CREATE_REPORT = gql`
    mutation createReport {
      createReport (input: { data: { uid: ${myId}, email: "${myEmail}", mid: ${route.params.id}, rpt_no: ${selectedIndex}, extra_desc: "${inputTextFiltered}" , status: 0 } } ) {
        report {
          id
          uid
          mid
        }
      },
    }
  `;

  const _onPressReportError = async () => { console.log("*** _onPressReportError");
    try {
      const res = await client_w_jwt.mutate({
        mutation: CREATE_REPORT
      });

      console.log(res);
      //if (res) {
      //  let report = res.data.report;
      //  console.log("createReport... : ", JSON.stringify(report));
      //}
      setModalMsg(i18n.t('succeed_in_report')); //Alert.alert(i18n.t('succeed_in_report'));
    } catch (err) {
      if (Platform.OS === 'android' || Platform.OS === 'ios')
        Sentry.Native.captureException(err);
      Alert.alert(i18n.t('fail_in_report')); console.log("_onPressReportError: ", err)
      navigation.goBack();
    }
  }

  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      <View style={[styles.container, { paddingHorizontal: 10 }]}>
        <Modal
          visible={modalMsg ? true : false}
          transparent={true}>
          <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
            <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
              <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
                <Text style={styles.modalText}>{modalMsg}</Text>
                <MyButton
                  onPress={() => { setModalMsg(''); navigation.goBack(); }}
                  children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
                />
              </View>
            </View>
          </View>
        </Modal>

        <Text style={{ fontSize: 17, marginVertical: 20 }}>
          {i18n.t('report_err_msg1')}
        </Text>
        <Text style={{ fontSize: 17 }}>
          {i18n.t('place_name')}: {route.params.name}
        </Text>
        <RadioButtonGroup
          selected={selectedIndex}
          onSelected={index => setSelectedIndex(index)}
          containerStyle={{ marginVertical: 20 }}
          radioBackground="#3366ff"
          radioStyle={{ marginVertical: 5 }}
          size={20}
        >
          <RadioButtonItem value={0} label={<Text>{i18n.t('report_err_item0')}</Text>}/>
          <RadioButtonItem value={1} label={<Text>{i18n.t('report_err_item1')}</Text>}/>
          <RadioButtonItem value={2} label={<Text>{i18n.t('report_err_item2')}</Text>}/>
          <RadioButtonItem value={3} label={<Text>{i18n.t('report_err_item3')}</Text>}/>
        </RadioButtonGroup>
        <Text>{i18n.t('Etc')}</Text>
        <TextInput
          multiline={true}
          placeholder=''
          style={{ marginBottom: 40, marginTop: 10, minHeight: 62, backgroundColor: 'white', borderWidth: 1, borderColor: '#a0a0a0', padding: 5 }}
          onChangeText={v => setInputText(v)}
        />
        <MyButton
          onPress={_onPressReportError}
          children={() => <Text style={styles.appButtonText}>{i18n.t('finish')}</Text>}
        />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  basic: {
    fontFamily: 'CustomHangul',
    fontSize: 14 * fontScale,
  },
  ypageListItemContainer: {
    width: '33%',
  },
  ypageListItem: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100 * fontScale,
    backgroundColor: '#ffffff',
    marginVertical: 5,
    marginHorizontal: 5,
    padding: 5,
    borderRadius: 5,
    shadowColor: 'rgb(126, 126, 126)',
    shadowOffset: { width: 0.4, height: 0.4 },
    shadowOpacity: 0.8,
    shadowRadius: 1,
    elevation: 5
  },
  ypageListIcon: {
    width: 38 * fontScale,
    height: 38 * fontScale,
    marginBottom: 10,
  },
  ypageListTitle: {
    fontFamily: 'CustomHangul',
    fontSize: 14 * fontScale,
    marginHorizontal: 5,
    textAlign: 'center',
    color: '#2b2e34',
  },
  ypageSublistCatItem: {
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 5,
    marginRight: 10,
    borderRadius: 5,
  },
  ypageSublistCatTitle: {
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#2b2e34',
    textAlign: 'center',
  },
  ypageSublistCatItem2: {
    backgroundColor: '#f6c417',
    paddingHorizontal: 20,
    height: 37 * fontScale,
  },
  ypageSublistCatItem2b: {
    marginTop: 5,
    backgroundColor: '#c1262d',
  },
  ypageSublistCatTitle2: {
    alignSelf: 'flex-start',
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    lineHeight: 36 * fontScale,
    color: '#272727',
  },
  ypageSublistMapButton: {
    justifyContent: 'center', alignItems: 'center',
    borderRadius: 10,
    margin: 5,
    backgroundColor: 'rgba(40, 40, 40, 0.4)',
  },
  ypageSublistItemContainer: {
    backgroundColor: 'rgb(226, 226, 226)',
  },
  ypageSublistItemContentContainer: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomColor: '#e2e2e2',
    borderBottomWidth: 1,
  },
  ypageSublistItemContent1: {
    width: '60%',
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#595b60',
    marginLeft: 10,
  },
  ypageSublistItemContent2: {
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#595b60',
    textAlign: 'right',
    marginRight: 10,
  },
  buttonContainer: Platform.select({
    ios: {},
    android: {
      marginBottom: 20,
    },
  }),
  button: Platform.select({
    ios: {},
    android: {
      color: '#2196F3',
    },
  }),
  modalView: {
    backgroundColor: 'rgba(255,255,255, 1)',
    position: 'absolute',
    width: windowWidth,
    top: 60,
    height: windowHeight - 120,
  },
  modalContainer: {
    width: windowWidth,
    height: windowHeight,
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
  },
  adbannerContainer: {
    height: windowWidth * 0.3,
    backgroundColor: '#e2e2e2',
    justifyContent: 'center',
  },
  adbanner: {
    width: windowWidth,
    height: windowWidth * 0.21,
  },
  adbannerTag: {
    color: 'white',
    backgroundColor: '#c1262d',
    position: 'relative',
    textAlign: 'center',
    bottom: windowWidth * 0.21,
    left: 0,
    fontSize: 7,
    width: 12,
    height: 10,
  },
  modalText: {
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#272727',
    marginBottom: 25,
    textAlign: 'center'
  },
  appButtonText: {
    fontFamily: 'CustomHangul',
    fontSize: 14 * fontScale,
    color: "#fff",
    alignSelf: "center",
    textTransform: "uppercase"
  }
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontFamily: 'CustomHangul',
    width: 130 * fontScale,
    fontSize: 16 * fontScale,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    fontFamily: 'CustomHangul',
    width: 130 * fontScale,
    fontSize: 16 * fontScale,
    paddingHorizontal: 5,
    paddingVertical: 1,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    backgroundColor: 'rgb(242,242,242)',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputWeb: {
    fontFamily: 'CustomHangul',
    width: 150 * fontScale,
    fontSize: 16 * fontScale,
    paddingHorizontal: 5,
    paddingVertical: 1,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    backgroundColor: 'rgb(242,242,242)',
    paddingRight: 30, // to ensure the text is never behind the icon
  }
});