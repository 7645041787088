import React, { useEffect, useRef } from 'react';
import { Dimensions, ActivityIndicator, Platform, View, Image } from 'react-native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import TabBarIcon, { SearchIcon, ListIcon, BookmarkIcon, MetricIcon } from '../components/TabBarIcon';
import NotificationListScreen from '../screens/NotificationScreen';
//import CouponListScreen, { CouponArticleScreen } from '../screens/CouponScreen';
//import EventListScreen, { EventArticleScreen } from '../screens/EventScreen';
//import NewsListScreen, { NewsArticleScreen } from '../screens/NewsScreen';
import YpageListScreen, { YpageArticleScreen, YpageSublistScreen, YpageMidlistScreen, ReportErrorScreen } from '../screens/YpageScreen';
import { FoodListScreen } from '../screens/FoodScreen';
import { MetricConversionScreen } from '../screens/MetricScreen';
import { SearchYpageMainScreen, SearchYpageListScreen, SearchYpageMapScreen } from '../screens/SearchScreen';
import MypageListScreen from '../screens/MypageScreen';
import MenuScreen, { SettingsScreen, ChangePasswordScreen, DeleteAccountScreen, AboutScreen } from '../screens/MenuScreen';
import SignupScreen from '../screens/auth/SignupScreen';
import i18n from 'i18n-js';
import { Ionicons } from '@expo/vector-icons';
import { showMessage } from 'react-native-flash-message';
import * as Notifications from 'expo-notifications';

const { width, height } = Dimensions.get('window');
const fontScale = width > 480 ? 1.5 : 1;
const paddingTabLabel = width > 480 ? 15 : 0;

const HomeStack = ({ navigation }) => {
  const Stack = createStackNavigator();

  const notificationListener = useRef();
  const responseListener = useRef();

  const showNotification = ({ content }) => {
    showMessage({
      message: content.title,
      titleStyle: { fontSize: 14 * fontScale, fontWeight: 'bold', color: '#555555' },
      textStyle: { color: '#252525'}, 
      description: content.body,
      icon: 'info',
      type: 'default',
      floating: true,
      duration: 3500,
      style: { backgroundColor: '#e0e0f0' },
      onPress: () => navigation.navigate('NotificationList'),
      renderFlashMessageIcon: () => <Image source={require('../assets/images/cakory-app-icon_1024.png')} 
                                           style={{ width:40, height:40, marginRight: 15 }}/>
    })
  }

  useEffect(() => {
    // Expo push notification receive functions are here. But, registration is performend in Settings screen.

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      console.log("!!!! Got a notification. ", notification.request);
      showNotification({ content: notification.request.content });
    });

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log("!!! This is a responseListener of Push noti. ", response);
      showNotification({ content: response.notification.request.content });
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };

  }, []);

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: 'white',
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
        },
        headerTitle: () => <ActivityIndicator  color='grey'/>,
        headerMode: 'float',
        headerBackTitleVisible: false,
        headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="#272727" />,
        ...TransitionPresets.SlideFromRightIOS, // This makes screen switching in Android be like IOS style.
      }}
    >
      <Stack.Screen name="SearchMain" component={SearchYpageMainScreen} />
      <Stack.Screen name="SearchList" component={SearchYpageListScreen} />
      <Stack.Screen name="YpageSublist" component={YpageSublistScreen} />
      <Stack.Screen name="YpageArticle" component={YpageArticleScreen} />
      <Stack.Screen name="FoodList" component={FoodListScreen} />
      <Stack.Screen name="SearchYpageMap" component={SearchYpageMapScreen} />
      <Stack.Screen name="ReportError" component={ReportErrorScreen} />
      <Stack.Screen name="MenuList" component={MenuScreen} />
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen name="About" component={AboutScreen} />
      <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
      <Stack.Screen name="DeleteAccount" component={DeleteAccountScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="NotificationList" component={NotificationListScreen} />
    </Stack.Navigator>
  )
}

const YpageStack = ({ navigation }) => {
  const Stack = createStackNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: 'white',
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
          //height: 88 * fontScale,
        },
        headerTitle: () => <ActivityIndicator color='grey' />,
        headerBackTitleVisible: false,
        headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="#272727" />,
        ...TransitionPresets.SlideFromRightIOS,
      }}
    >
      <Stack.Screen name="YpageList" component={YpageListScreen} />
      <Stack.Screen name="YpageMidlist" component={YpageMidlistScreen} />
      <Stack.Screen name="YpageSublist" component={YpageSublistScreen} />
      <Stack.Screen name="YpageArticle" component={YpageArticleScreen} />
      <Stack.Screen name="SearchYpageMap" component={SearchYpageMapScreen} />
      <Stack.Screen name="ReportError" component={ReportErrorScreen} />
      <Stack.Screen name="MenuList" component={MenuScreen} />
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen name="About" component={AboutScreen} />
      <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
      <Stack.Screen name="DeleteAccount" component={DeleteAccountScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="NotificationList" component={NotificationListScreen} />
    </Stack.Navigator>
  )
}

const MetricStack = ({ navigation }) => {
  const Stack = createStackNavigator();

  return (
    <Stack.Navigator
      initialRouteName='MetricConversion'
      screenOptions={{
        headerStyle: {
          backgroundColor: 'white',
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
          //height: 88 * fontScale,
        },
        headerTitle: () => <ActivityIndicator  color='grey'/>,
        headerBackTitleVisible: false,
        headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="#272727" />,
        ...TransitionPresets.SlideFromRightIOS,
      }}
    >
      <Stack.Screen name="MetricConversion" component={MetricConversionScreen} />
      <Stack.Screen name="MenuList" component={MenuScreen} />
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen name="About" component={AboutScreen} />
      <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
      <Stack.Screen name="DeleteAccount" component={DeleteAccountScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="NotificationList" component={NotificationListScreen} />
    </Stack.Navigator>
  )
}

const MypageStack = ({ navigation }) => {
  const Stack = createStackNavigator();

  return (
    <Stack.Navigator
      initialRouteName='MypageList'
      screenOptions={{
        headerStyle: {
          backgroundColor: 'white',
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
          //height: 88 * fontScale,
        },
        headerTitle: () => <ActivityIndicator  color='grey'/>,
        headerBackTitleVisible: false,
        headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="#272727" />,
        ...TransitionPresets.SlideFromRightIOS,
      }}
    >
      <Stack.Screen name="MypageList" component={MypageListScreen} />
      <Stack.Screen name="YpageArticle" component={YpageArticleScreen} />
      <Stack.Screen name="MenuList" component={MenuScreen} />
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen name="About" component={AboutScreen} />
      <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
      <Stack.Screen name="DeleteAccount" component={DeleteAccountScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="NotificationList" component={NotificationListScreen} />
    </Stack.Navigator>
  )
}

const MainTabNavigator = () => {
  const Tab = createBottomTabNavigator();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#f57272',
        tabBarInactiveTintColor: '#272727',
        tabBarLabelStyle: {
          fontSize: 12 * fontScale,
        },
        headerShown: false
      }}
    >
      <Tab.Screen
        name="HomeStack"
        component={HomeStack}
        options={{
          tabBarLabel: i18n.t('mt_search'),
          tabBarIcon: ({ focused }) => <SearchIcon focused={focused} />,
        }}
      />
      <Tab.Screen
        name="YpageStack"
        component={YpageStack}
        options={{
          tabBarLabel: i18n.t('mt_list'),
          tabBarIcon: ({ focused }) => <ListIcon focused={focused} />,
        }}
      />
      <Tab.Screen
        name="MypageStack"
        component={MypageStack}
        options={{
          tabBarLabel: i18n.t('mt_bookmark'),
          tabBarIcon: ({ focused }) => <BookmarkIcon focused={focused} />,
        }}
      />
      <Tab.Screen
        name="MetricStack"
        component={MetricStack}
        options={{
          tabBarLabel: i18n.t('mt_unit_conversion'),
          tabBarIcon: ({ focused }) => <MetricIcon focused={focused} />,
        }}
      />
    </Tab.Navigator>
  );
}

export default MainTabNavigator;