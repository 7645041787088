export default {
	FirebaseConfig: {
        /* ttgo canada */
        apiKey: "AIzaSyC5eaxR5xZZnwAjXJavGKKijeod83Zefss",
        authDomain: "ttgo-app.firebaseapp.com",
        databaseURL: "https://ttgo-app.firebaseio.com",
        projectId: "ttgo-app",
        storageBucket: "ttgo-app.appspot.com",
        messagingSenderId: "573548059809"
    
        /* towardgoodsoft
		apiKey: " AIzaSyCQ2EJGsR6X2o80-KX0uQ-81wNC7ZS5hQg ",
        authDomain: "towardgoodsoft.firebaseapp.com",
        databaseURL: "https://towardgoodsoft.firebaseio.com",
        projectId: "towardgoodsoft",
        storageBucket: "towardgoodsoft.appspot.com",
        messagingSenderId: "727826086595"
        */
	},
    StrapiConfig: {
        URI: "https://cakory-ds.com/cakory-graphql-ep",
//        URI: "http://192.168.0.109:1337/cakory-graphql-ep",
    }
};