import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
    StyleSheet, Text, View, TextInput, Alert, Switch, Linking, AppState, Dimensions,
    StatusBar, Platform, TouchableOpacity, Modal
} from 'react-native';
import Constants from 'expo-constants';
import { HeaderCenterImage, HeaderMenuImage } from '../components/CustomHeader';
import { Ionicons } from '@expo/vector-icons';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import { gql, useQuery, useMutation, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
import { areaList } from '../assets/data/geo';
import MyContext from '../components/MyContext';
import ApiKeys from '../constants/ApiKeys';
import * as Notifications from 'expo-notifications';
import { createStrapiExpoToken, removeStrapiPushToken, sendLog } from '../components/StrapiInterface';
import * as Sentry from 'sentry-expo';
import { MyButton } from '../components/MyComponents';
import RadioButtonGroup, { RadioButtonItem } from 'expo-radio-button';

const newclient = new ApolloClient({
    uri: ApiKeys.StrapiConfig.URI,
    cache: new InMemoryCache(),
});
  
const { width: windowFullWidth, height } = Dimensions.get('window');
const windowWidth = windowFullWidth > 800 ? 800 : windowFullWidth;
const fontScale = windowWidth > 480 ? 1.5 : 1;

export default function MenuScreen({ navigation }) {
    const [point1, setPoint1] = useState(0);
    const { myEmail, myJwt, updateMyContext } = useContext(MyContext);

    console.log("[ MenuScreen ] is called... ", myEmail, myJwt);

    useEffect(() => {
        navigation.setOptions({
            headerStyle: {
                backgroundColor: '#272727',
            },
            headerTitle: () => <HeaderCenterImage />,
            headerTitleAlign: 'center',
            headerTintColor: '#ffffff',
            headerBackTitle: '',
            headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="white" />,
        })
    }, [])

    useEffect(() => {
        console.log("[ MenuScreen ] useEffect is called. email: ", myEmail, ", jwt: ", myJwt);

        if (myEmail) {
            _getReports();
            checkJwtValidity(myEmail, myJwt, updateMyContext);
        }
    }, [myEmail, myJwt]);

    const _onSignoutPress = () => {
        updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
    }

    const _getReports = async () => {
        const GET_REPORTS = gql`
            query getReports {
            reports (where: { email: "${myEmail}", status: 1 } ) {
                id,
                email,
                mid,
                status
            },
            }
        `;

        try {
            const res = await newclient.query({
                query: GET_REPORTS, fetchPolicy: 'no-cache'
            });

            if (res) {
                console.log("[ MenuScreen ] get reports: ", res.data.reports.length);
                setPoint1(res.data.reports.length);
            }
        } catch (err) {
            if (Platform.OS === 'android' || Platform.OS === 'ios')
                Sentry.Native.captureException(err);
            console.log("[ MenuScreen ] getReports Error: ", err);
        }
    }

    return (
        <View style={{ flex: 1, alignItems: 'center' }}>
            <StatusBar barStyle="light-content" hidden={false} />

            <Text style={styles.settingTitle} >{i18n.t('user_info_m')}</Text>
            {myEmail != '' ? <View>
                <Text style={styles.settingtext} > {i18n.t('id_m')}: {myEmail} </Text>
                <Text style={styles.settingtext} > {i18n.t('point')}: {point1} </Text>
            </View>
                :
                <Text style={styles.settingtext} >{i18n.t('not_logged')}</Text>
            }
            <View>
                {myEmail ?
                    <View>
                        <MyButton
                            onPress={() => { _onSignoutPress(); navigation.goBack(); }}
                            style={styles.appButtonContainer}
                            children={() => <Text style={styles.appButtonText} >{i18n.t('logout_m')}</Text>} />

                        <MyButton
                            onPress={() => navigation.navigate('ChangePassword')}
                            style={styles.appButtonContainer}
                            children={() => <Text style={styles.appButtonText} >{i18n.t('change_pwd_m')}</Text>} />

                        <MyButton
                            onPress={() => navigation.navigate('DeleteAccount')}
                            style={styles.appButtonContainer}
                            children={() => <Text style={styles.appButtonText} >{i18n.t('delete_account_m')}</Text>} />
                    </View>
                    :
                    <View>
                        <MyButton
                            onPress={() => navigation.navigate('LoginStack')}
                            style={styles.appButtonContainer}
                            children={() => <Text style={styles.appButtonText} >{i18n.t('login_m')}</Text>} />

                        <MyButton
                            onPress={() => navigation.navigate('Signup')}
                            style={styles.appButtonContainer}
                            children={() => <Text style={styles.appButtonText}>{i18n.t('create_account_m')}</Text>}
                        />
                    </View>
                }
                <MyButton
                    onPress={() => { navigation.navigate('Settings') }}
                    style={styles.appButtonContainer}
                    children={() => <Text style={styles.appButtonText} >{i18n.t('setting_m')}</Text>} />

            </View>
        </View>
    )
}

export const registerForPushNotificationsAsync = async () => {
    let token = '';
    if (Constants.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            sendLog(i18n.t('registering_expopush_err') + '[e01]');
            Alert.alert(i18n.t('registering_expopush_err'));
            return;
        }
        token = (await Notifications.getExpoPushTokenAsync()).data;
        console.log("(registerForPushNotificationsAsync) token: ", token);
    } else {
        sendLog(i18n.t('registering_expopush_err') + '[e03]');
        Alert.alert(i18n.t('registering_expopush_err'));
    }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return token;
};

export class SettingsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pushAllowYpage: false,
            deviceLocaleNum: -1,
            modalMsg: '',
        }
    }

    static contextType = MyContext;

    _handleAppStateChange = (nextAppState) => {
        if (nextAppState === 'active') {
            console.log('[ SettingsScreen ] AppState has come to active.');
        }
    }

    _checkForPushNotificationsAsync = async () => {
        console.log("[ SettingsScreen ] _checkForPushNotificationsAsync is called.")
        let token = '';
        // initialize expo notification
        try {
            token = await registerForPushNotificationsAsync();
            if (!token) {
                sendLog(i18n.t('registering_expopush_err') + '[e05]');
                Alert.alert(i18n.t('registering_expopush_err'));
                return false;
            }
            if (this.context.myPushToken && this.context.myPushToken != token) {
                console.log("[ SettingsScreen ] (_checkForPushNotificationsAsync) Error!: PushToken Mismatch....");
                sendLog(i18n.t('registering_expopush_err') + '[e07]');
                Alert.alert(i18n.t('registering_expopush_err'));
                return false;
            }
            this.context.updateMyContext({ myPushToken: token });
        } catch (err) {
            sendLog(i18n.t('registering_expopush_err') + '[e09]' + JSON.stringify(err));
            Alert.alert(i18n.t('registering_expopush_err'));
            console.log("Error: ", err);
            return false;
        };

        await createStrapiExpoToken({ myPushToken: token, myId: this.context.myId });

        if (this.context.mySettingId >= 0)
            this._updateStrapiUserSettings({ id: this.context.mySettingId, expoPush: true })

    }

    _clearPushToken = async () => {
        console.log("[ SettingsScreen ] _clearPushToken is called.");

        try {
            const res = await removeStrapiPushToken({ myPushToken: this.context.myPushToken });
            if (res) {
                this.context.updateMyContext({ myPushToken: '' });
                if (this.context.mySettingId >= 0)
                    this._updateStrapiUserSettings({ id: this.context.mySettingId, expoPush: false })
            }
        } catch (err) {
            if (Platform.OS === 'android' || Platform.OS === 'ios')
                Sentry.Native.captureException(err);
            //throw err;
        }
    }
    //
    _onAboutPress = () => {
        this.props.navigation.navigate('About');
    }

    _reloadStrapiUserSettings = async () => {
        console.log("[ SettingsScreen ] _reloadStrapiUserSettings is called. ", this.context.myEmail);

        const client_no_jwt = new ApolloClient({
            uri: ApiKeys.StrapiConfig.URI,
            cache: new InMemoryCache(),
        });

        const httpLink = new HttpLink({ uri: ApiKeys.StrapiConfig.URI });

        const authMiddleware = new ApolloLink((operation, forward) => {
            // add the authorization to the headers
            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    authorization: `Bearer ${this.context.myJwt}`,
                }
            }));

            return forward(operation);
        })

        const client_w_jwt = new ApolloClient({
            cache: new InMemoryCache(),
            link: concat(authMiddleware, httpLink),
        });

        const GET_USERSETTINGS = gql`
            query getUserSettings {
            usersettings (where: { email: "${this.context.myEmail}" } ) {
                id,
                email,
                locale,
                province_idx,
                locality_idx,
            },
            }
        `;

        try {
            const res = await client_no_jwt.query({
                query: GET_USERSETTINGS
            });

            //console.log(res);
            if (res) {
                let settings = res.data.usersettings;
                console.log("[ SettingsScreen ] (_reloadStrapiUserSettings) usersettings: ", JSON.stringify(settings));

                switch (settings.length) {
                    case 0:
                        sendLog(i18n.t('usersetting_conflict') + '[e01]');
                        Alert.alert(i18n.t('usersetting_conflict') + ' ' + i18n.t('please_login_again'));
                        this.context.updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
                        break;
                    case 1:
                        if (settings[0].locale !== this.context.myLocale) {
                            sendLog(i18n.t('usersetting_conflict') + '[e03]');
                            Alert.alert(i18n.t('usersetting_conflict') + ' ' + i18n.t('please_login_again'));
                            this.context.updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
                        }
                        if (settings[0].province_idx !== this.context.myProvinceIdx) {
                            sendLog(i18n.t('usersetting_conflict') + '[e05]');
                            Alert.alert(i18n.t('usersetting_conflict') + ' ' + i18n.t('please_login_again'));
                            this.context.updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
                        }
                        if (settings[0].locality_idx !== this.context.myLocalityIdx) {
                            sendLog(i18n.t('usersetting_conflict') + '[e07]');
                            Alert.alert(i18n.t('usersetting_conflict') + ' ' + i18n.t('please_login_again'));
                            this.context.updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
                        }
                        if (settings[0].id !== this.context.mySettingId) {
                            sendLog(i18n.t('usersetting_conflict') + '[e09]');
                            Alert.alert(i18n.t('usersetting_conflict') + ' ' + i18n.t('please_login_again'));
                            this.context.updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
                        }
                        console.log("[ SettingsScreen ] (_reloadStrapiUserSettings) mySettingId is ", settings[0].id);
                        break;
                    default:
                        sendLog(i18n.t('fetch_usersetting_err') + '[e11]');
                        Alert.alert(i18n.t('fetch_usersetting_err'));
                        break;
                }
            }
        } catch (err) {
            sendLog(i18n.t('fetch_usersetting_err') + '[e13]' + JSON.stringify(err));
            Alert.alert(i18n.t('fetch_usersetting_err')); console.log("[ SettingsScreen ] (_reloadStrapiUserSettings) Error: ", err)
        }

    }

    _updateStrapiUserSettings = async ({ id, locale, provinceIdx, localityIdx, expoPush }) => {
        console.log("[ SettingsScreen ] _updateStrapiUserSettings: id ", id, ", locale ", locale, ", provinceIdx ", provinceIdx, ", localityIdx, ", localityIdx);

        const httpLink = new HttpLink({ uri: ApiKeys.StrapiConfig.URI });

        const authMiddleware = new ApolloLink((operation, forward) => {
            // add the authorization to the headers
            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    authorization: `Bearer ${this.context.myJwt}`,
                }
            }));

            return forward(operation);
        })

        const client_w_jwt = new ApolloClient({
            cache: new InMemoryCache(),
            link: concat(authMiddleware, httpLink),
        });

        const new_locale = locale ?? this.context.myLocale;
        const new_provinceIdx = provinceIdx ?? this.context.myProvinceIdx;
        const new_localityIdx = localityIdx ?? this.context.myLocalityIdx;
        var new_expoPush;
        if (typeof expoPush === 'undefined') new_expoPush = this.context.myPushToken == '' ? false : true;
        else new_expoPush = expoPush;

        console.log("[ SettingsScreen ] (_updateStrapiUserSettings) locale: ", new_locale,
            ", provinceIdx: ", new_provinceIdx,
            ", localityIdx: ", new_localityIdx,
            ", expoPush: ", new_expoPush);

        const UPDATE_USERSETTING = gql`
            mutation updateUserSetting {
            updateUsersetting (input: { where: {id: ${id}}, data: {locale: "${new_locale}", province_idx: ${new_provinceIdx}, locality_idx: ${new_localityIdx}, expo_push: ${new_expoPush} } } ) {
                usersetting {
                id,
                email,
                province_idx,
                locality_idx
                }
            },
            }
        `;

        try {
            let result = await client_w_jwt.mutate({
                mutation: UPDATE_USERSETTING
            });
            console.log("[ SettingsScreen ] (_updateStrapiUserSettings) Success: ", result.data.updateUsersetting.usersetting);
        } catch (err) {
            sendLog(i18n.t('update_usersetting_err') + '[e01]' + JSON.stringify(err));
            Alert.alert(i18n.t('update_usersetting_err'));
            console.log("[ SettingsScreen ] (_updateStrapiUserSettings) Error: ", err);
        }
    }

    _changeLocale = async (i) => {
        this.setState({ deviceLocaleNum: i });

        let selectedLocale = 'en';

        switch (i) {
            case 0: console.log("Ko button is pressed."); selectedLocale = 'ko';
                break;
            case 1: console.log("En button is pressed.");
                break;
            default: console.log("Wrong button is pressed.");
                break;
        }
        this.context.updateMyContext({ myLocale: selectedLocale })
        if (this.context.mySettingId >= 0)
            this._updateStrapiUserSettings({ id: this.context.mySettingId, locale: selectedLocale });

        this.setState({ modalMsg: i18n.t('locale_change_msg_m') });
    }

    _changeProvince = async (index) => {
        console.log("[ SettingsScreen ] _changeProvince is called with ", index);
        this.context.updateMyContext({ myProvinceIdx: index });
        if (this.context.mySettingId >= 0)
            this._updateStrapiUserSettings({ id: this.context.mySettingId, provinceIdx: index })

    }

    _changeLocality = async (index) => {
        console.log("[ SettingsScreen ] _changeLocality is called with ", index);
        this.context.updateMyContext({ myLocalityIdx: index });
        if (this.context.mySettingId >= 0)
            this._updateStrapiUserSettings({ id: this.context.mySettingId, localityIdx: index })
    }

    render() {
        var provinceList = [];
        var localityList = [];

        for (let i = 0; i < areaList.length; i++) {
            provinceList = [...provinceList, { label: i18n.t(areaList[i].label), value: areaList[i].value }];
        }

        for (let i = 0; i < areaList[this.context.myProvinceIdx].cities.length; i++) {
            localityList = [...localityList, { label: i18n.t(areaList[this.context.myProvinceIdx].cities[i].label), value: areaList[this.context.myProvinceIdx].cities[i].value }];
        }

        console.log("[ SettingsScreen ] render: locale [" + this.context.myLocale + "], provinceIdx [" + this.context.myProvinceIdx + "], localityIdx [" + this.context.myLocalityIdx + "], expoToken: ", this.context.myPushToken);
        return (
            <View style={{ flex: 1, paddingVertical: 10, paddingHorizontal: 10, }}>
                <StatusBar barStyle="light-content" hidden={false} />

                <Modal
                    visible={this.state.modalMsg ? true : false}
                    transparent={true}>
                    <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
                        <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
                            <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
                                <Text style={styles.modalText}>{this.state.modalMsg}</Text>
                                <MyButton
                                    onPress={() => this.setState({ modalMsg: '' })}
                                    children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>} />

                            </View>
                        </View>
                    </View>
                </Modal>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20 }}>
                    <Text style={[styles.settingtext, { marginTop: 8 }]} >{i18n.t('locale_m')}</Text>
                    <View style={{ width: '50%', alignItems: 'flex-start' }}>
                        <RadioButtonGroup
                            containerStyle={{ }}
                            selected={this.state.deviceLocaleNum}
                            onSelected={i => this._changeLocale(i)}
                            radioBackground="#3366ff"
                            size={20}
                        >
                            <RadioButtonItem value={0} label={<Text style={{ fontFamily: 'CustomHangul', fontSize: 16 * fontScale, margin: 6 }}>한국어</Text>} />
                            <RadioButtonItem value={1} label={<Text style={{ fontFamily: 'CustomHangul', fontSize: 16 * fontScale, margin: 6 }}>English</Text>} />
                        </RadioButtonGroup>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20 }}>
                    <Text style={styles.settingtext} >{i18n.t('area_for_search')}</Text>
                    <View style={{ width: '50%' }}>
                        <RNPickerSelect
                            style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
                            value={provinceList[this.context.myProvinceIdx].value}
                            useNativeAndroidPickerStyle={false}
                            fixAndroidTouchableBug={true}
                            placeholder={{}}
                            onValueChange={(value, index) => {
                                if (this.context.myProvinceIdx === index) return;
                                this._changeProvince(index);
                                this._changeLocality(0);
                            }}
                            Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
                            items={provinceList}
                        />
                        <View style={{ height: 20 }}></View>
                        <RNPickerSelect
                            style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
                            value={localityList[this.context.myLocalityIdx].value}
                            useNativeAndroidPickerStyle={false}
                            fixAndroidTouchableBug={true}
                            placeholder={{}}
                            onValueChange={(value, index) => {
                                if (this.context.myLocalityIdx === index) return;
                                this._changeLocality(index);
                            }}
                            Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
                            items={localityList}
                        />
                    </View>
                </View>

                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20 }}>
                    <Text style={[styles.settingtext, { marginTop: 8 }]} >{i18n.t('push_notification')}</Text>
                    <View style={{ width: '50%', alignItems: 'flex-start' }}>
                        <RadioButtonGroup
                            selected={this.context.myPushToken != '' ? 1 : 0}
                            onSelected={(value) => {
                                console.log("[ SettingsScreen ] notification switch changed.", value);
                                //Linking.openURL('app-settings:');
                                if (value) this._checkForPushNotificationsAsync();
                                else this._clearPushToken();
                            }}
                            radioBackground="#3366ff"
                            size={20}
                        >
                            <RadioButtonItem value={0} label={<Text style={{ fontFamily: 'CustomHangul', fontSize: 16 * fontScale, margin: 6 }}>{i18n.t('no_thanks')}</Text>} />
                            <RadioButtonItem value={1} label={<Text style={{ fontFamily: 'CustomHangul', fontSize: 16 * fontScale, margin: 6 }}>{i18n.t('ok_thanks')}</Text>} />
                        </RadioButtonGroup>
                    </View>
                </View>
                {/*
                <Text style={styles.sectionTitle} >알림 정보 수신 종류</Text>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={styles.textPush} > 쿠폰 </Text>
                    <View style={styles.settingWwitchPush}>
                    <Switch 
                        value = {this.state.pushAllowYpage}
                        onValueChange = {(value) => {
                        let uid = firebase.auth().currentUser.uid;
                        this.setState({pushAllowYpage: value});
                        firebase.database().ref("users/"+uid).child("pushAllow").update({ ypage: value });
                        } }
                        style={styles.switch}
                        />
                    </View>
                </View>
                */}

                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <MyButton
                        onPress={() => { this.props.navigation.goBack(); }}
                        style={{ width: '50%' }}
                        children={() => <Text style={styles.appButtonText}>{i18n.t('done_m')}</Text>}
                    />
                </View>

                <View style={{ flex: 1.5, justifyContent: 'space-between', marginTop: 30, paddingVertical: 10, marginHorizontal: 20, borderColor: '#c0c0c0', borderTopWidth: 1, }}>
                    <View>
                        <TouchableOpacity style={styles.settingButton}
                            onPress={() => Linking.openURL('https://cakory.com/privacy.html').catch(err => console.error('[ SettingsScreen ] An error occurred', err))} >
                            <Text style={styles.settingtext} >{i18n.t('privacy_policy_m')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.settingButton}
                            onPress={() => Linking.openURL('mailto:support@cakory.com')} >
                            <Text style={styles.settingtext} >{i18n.t('contact_us_m')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.settingButton} onPress={this._onAboutPress} >
                            <Text style={styles.settingtext} >{i18n.t('app_ver_m')}</Text>
                        </TouchableOpacity>
                    </View>
                    <View>
                    <Text style={{ fontSize: 10 * fontScale, textAlign: 'center' }}>CAKORY (c) 2022 All Rights Reserved</Text>
                    </View>
                </View>
            </View>
        );
    }

    componentDidMount() {
        //console.log("[ SettingsScreen ] componentDidMount is called.");

        this.props.navigation.setOptions({
            headerStyle: {
                backgroundColor: '#272727',
            },
            headerTitle: () => <HeaderCenterImage />,
            headerTitleAlign: 'center',
            headerTintColor: '#ffffff',
            headerBackTitle: '',
            headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="white" />,
        })

        if (this.context.myEmail) this._reloadStrapiUserSettings();

        if (this.context.myLocale == 'ko') this.setState({ deviceLocaleNum: 0 });
        else if (this.context.myLocale == 'en') this.setState({ deviceLocaleNum: 1 });
        else {
            let templ = 'en';
            if (Localization.locale.substring(0, 2) == 'ko') templ = 'ko';

            // On the first time setting, the area is set to within 15km from current location.
            this.context.updateMyContext({ myLocale: templ, myProvinceIdx: 0, myLocalityIdx: 2 });
            if (this.context.mySettingId >= 0)
                this._updateStrapiUserSettings({ id: this.context.mySettingId, locale: templ, provinceIdx: 0, localityIdx: 1 })

            if (templ == 'ko') this.setState({ deviceLocaleNum: 0 });
            else if (templ == 'en') this.setState({ deviceLocaleNum: 1 });
        }

        //console.log('AppState: ' + AppState.currentState);
        //AppState.addEventListener('change', this._handleAppStateChange);
    }

    componentWillUnmount() {
        //AppState.removeEventListener('change', this._handleAppStateChange);
    }

}

export class AboutScreen extends React.Component {
    static contextType = MyContext;

    clearAsyncstorage = async () => {
        const asyncStorageKeys = await AsyncStorage.getAllKeys();
        if (asyncStorageKeys.length > 0) {
            if (Platform.OS === 'android') {
                await AsyncStorage.clear();
            }
            if (Platform.OS === 'ios') {
                await AsyncStorage.multiRemove(asyncStorageKeys);
            }
            if (Platform.OS === 'web') {
                await AsyncStorage.multiRemove(asyncStorageKeys);
            }
        }
    }

    render() {
        const version = Constants.manifest.version;
        return (
            <View style={{ alignItems: 'center', paddingTop: 20 }}>
                <Text style={{ fontSize: 14 * fontScale }}> CAKORY Mobile App</Text>
                <Text> </Text>
                <Text style={{ fontSize: 14 * fontScale }}> {version} </Text>
                <Text> </Text>
                <Text style={{ fontSize: 14 * fontScale }}> Device Locale: {Localization.locale} </Text>
                <Text> </Text>
                <Text style={{ fontSize: 14 * fontScale }}> ({this.context.myGpsLatitude.toFixed(6)}, {this.context.myGpsLongitude.toFixed(6)}) </Text>

                <MyButton
                    onPress={() => { this.clearAsyncstorage(); Alert.alert(i18n.t("ok_clear_setting")) }}
                    style={styles.appButtonContainer}
                    children={() => <Text style={styles.appButtonText} >{i18n.t('clear_setting')}</Text>} />
                <Text style={{ fontSize: 15 * fontScale, color: 'darkred', marginHorizontal:50, marginVertical:20 }}>
                    {i18n.t('warning_reset_setting')}
                </Text>
            </View>
        )
    }

    componentDidMount() {
        this.props.navigation.setOptions({
            headerStyle: {
                backgroundColor: '#272727',
            },
            headerTitle: () => <HeaderCenterImage />,
            headerTitleAlign: 'center',
            headerTintColor: '#ffffff',
            headerBackTitle: '',
            headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="white" />,
        })
    }
}

export function ChangePasswordScreen({ navigation }) {
    const [modalMsg, setModalMsg] = useState('');
    const { myEmail } = useContext(MyContext);

    useEffect(() => {
        navigation.setOptions({
            headerStyle: {
                backgroundColor: '#272727',
            },
            headerMode: 'float',
            headerTitle: () => <HeaderCenterImage />,
            headerTitleAlign: 'center',
            headerBackTitleVisible: false,
            headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="white" />
        })
    });

    const FORGOT_PASSWORD_MUTATION = gql`
        mutation forgotPasswordMutation($email: String!) {
            forgotPassword (email: $email) {
                ok,
            }
        }
    `;

    const [mutateFunction, { loading, error, data }] = useMutation(FORGOT_PASSWORD_MUTATION);

    if (loading) console.log(`Trying to send change_password email ...`);
    if (error) console.log(`Sending change_password email error! ${error.message}`);
    if (data) console.log('Sending change_password email suceeded! ' + JSON.stringify(data));

    const onChangePasswordPress = () => {
        console.log("onChangePasswordPress is called. email: " + myEmail);
        mutateFunction({ variables: { email: myEmail } })
            .then(res => { setModalMsg(i18n.t('noti_change_pwd_m')); })
            .catch(error => {
                console.log("error..." + error.graphQLErrors[0].extensions.code + ": " + error.graphQLErrors[0].extensions.exception.data.message[0].messages[0].message);
                sendLog(i18n.t('change_password_err') + '[e01]' + JSON.stringify(error));
                Alert.alert(i18n.t('change_password_err'));
            });
    }

    return (
        <View style={{ flex: 1, alignItems: "center", width: '90%', alignSelf: 'center' }}>
            <StatusBar barStyle="light-content" hidden={false} />

            <Modal
                visible={modalMsg ? true : false}
                transparent={true}>
                <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
                    <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
                        <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
                            <Text style={styles.modalText}>{modalMsg}</Text>
                            <MyButton
                                onPress={() => { setModalMsg(''); navigation.goBack(); }}
                                children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
                            />
                        </View>
                    </View>
                </View>
            </Modal>

            <Text style={{ fontSize: 19, fontWeight: 'bold', color: '#2b2e34', marginTop: 20, marginBottom: 10 }}>{i18n.t('change_pwd_m')}</Text>
            <Text style={{ fontSize: 18 }}>
                {i18n.t('change_pwd_guide_m')}
            </Text>

            <MyButton 
                onPress={onChangePasswordPress} style={{ marginTop: 20 }}
                children={<Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
            />
        </View>
    );
}

export function DeleteAccountScreen({ navigation }) {
    const [modalMsg, setModalMsg] = useState('');
    const { myId, myJwt, myEmail, updateMyContext } = useContext(MyContext);

    console.log("[ DeleteAccountScreen ] is called ...", myId, myEmail);

    useEffect(() => {
        navigation.setOptions({
            headerStyle: {
                backgroundColor: '#272727',
            },
            headerMode: 'float',
            headerTitle: () => <HeaderCenterImage />,
            headerTitleAlign: 'center',
            headerBackTitleVisible: false,
            headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="white" />
        })
    });

    const onDeleteAccountPress = async () => {
        console.log("[ DeleteAccountScreen ] onDeleteAccountPress is called. myId: " + myId);

        const DELETE_USER = gql`
            mutation deleteUserMutation {
                deleteUser (input: { where: { id: ${myId} } }) {
                    user { id, username, email }
                }
            }
        `;

        const client_no_jwt = new ApolloClient({
            uri: ApiKeys.StrapiConfig.URI,
            cache: new InMemoryCache(),
        });

        const httpLink = new HttpLink({ uri: ApiKeys.StrapiConfig.URI });

        const authMiddleware = new ApolloLink((operation, forward) => {
            // add the authorization to the headers
            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    authorization: `Bearer ${myJwt}`,
                }
            }));

            return forward(operation);
        })

        const client_w_jwt = new ApolloClient({
            cache: new InMemoryCache(),
            link: concat(authMiddleware, httpLink),
        });

        try {
            const GET_USERSETTINGS = gql`
                query getUserSettings {
                    usersettings (where: { email: "${myEmail}" } ) {
                        id,
                        email,
                    }
                }
            `;
            
            let result2 = await client_no_jwt.query({
                query: GET_USERSETTINGS
            });

            if (result2.data.usersettings.length) {
                console.log("[ DeleteAccountScreen ] (onDeleteAccountPress) userSettings Success: ", result2.data.usersettings[0].id);
                
                const DELETE_USERSETTING = gql`
                    mutation deleteUsersettingMutation {
                        deleteUsersetting (input: { where: { id: ${result2.data.usersettings[0].id} } }) {
                            usersetting { id, email }
                        }
                    }
                `;

                let result3 = await client_w_jwt.mutate({
                    mutation: DELETE_USERSETTING
                });

                console.log("[ DeleteAccountScreen ] (onDeleteAccountPress) deleteUsersetting Success: ", result3.data.deleteUsersetting.usersetting.id);
            }

            let result = await client_w_jwt.mutate({
                mutation: DELETE_USER
            });
            console.log("[ DeleteAccountScreen ] (onDeleteAccountPress) deleteUser Success: ", result.data.deleteUser.user);

            if ( result.data.deleteUser.user && result.data.deleteUser.user.email !== myEmail) {
                console.log("[ DeleteAccountScreen ] (onDeleteAccountPress) Oops!!! Email mismatch !!!");
                sendLog("[ DeleteAccountScreen ] (onDeleteAccountPress) Oops!!! Email mismatch !!! [" + myEmail + "],[" + result.data.deleteUser.user.email + "]");
            }

            Alert.alert(i18n.t('delete_account_success'));
            updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
            navigation.goBack();
        } catch (err) {
            //sendLog(i18n.t('delete_account_err') + '[e01]' + JSON.stringify(err));
            Alert.alert(i18n.t('delete_account_err'));
            console.log("[ DeleteAccountScreen ] (onDeleteAccountPress) Error: ", err);
        }

    }

    return (
        <View style={{ flex: 1, alignItems: "center", width: '90%', alignSelf: 'center' }}>
            <StatusBar barStyle="light-content" hidden={false} />

            <Modal
                visible={modalMsg ? true : false}
                transparent={true}>
                <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
                    <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
                        <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
                            <Text style={styles.modalText}>{modalMsg}</Text>
                            <MyButton
                                onPress={() => { setModalMsg(''); navigation.goBack(); }}
                                children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
                            />
                        </View>
                    </View>
                </View>
            </Modal>

            <Text style={{ fontSize: 19, fontWeight: 'bold', color: '#2b2e34', marginTop: 20, marginBottom: 10 }}>{i18n.t('delete_account_m')}</Text>
            <View style={{ textAlign: 'left', marginVertical: 10}}>
                <Text style={{ fontSize: 18 }}>
                {i18n.t('delete_account_guide_m')}
            </Text>
            </View>
            <View style={{ textAlign: 'left', marginVertical: 20}}>
                <Text style={{ fontSize: 18 }}>
                {i18n.t('delete_account_confirm_m')}
            </Text>
            </View>

            <MyButton 
                onPress={onDeleteAccountPress} style={{ marginTop: 20 }}
                children={<Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
            />
        </View>
    );
}

export const checkJwtValidity = async (myEmail, myJwt, updateMyContext) => {
    var result = true;

    const ME_QUERY = gql`
      query {
        me {
          id
          email
        }
      }
    `;

    const httpLink = new HttpLink({ uri: ApiKeys.StrapiConfig.URI });

    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                authorization: `Bearer ${myJwt}`,
            }
        }));

        return forward(operation);
    })

    const client_w_jwt = new ApolloClient({
        cache: new InMemoryCache(),
        link: concat(authMiddleware, httpLink),
    });

    try {
        const me_data = await client_w_jwt.query({
            query: ME_QUERY
        });
        if (myEmail && me_data.data.me.email != myEmail) {
            console.log("(checkJwtValidity) Failed! email mismatch: ", myEmail, " vs ", me_data.data.me.email);

            sendLog(i18n.t('me_query_err') + '[e01]');
            Alert.alert(i18n.t('me_query_err') + ' ' + i18n.t('please_login_again'));
            updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
            result = false;
        } else
            console.log('Checking login status - OK! ');
    } catch (err) {
        console.log("(checkJwtValidity) Error: Me Query ... ", err);
        sendLog(i18n.t('me_query_err') + '[e03]' + JSON.stringify(err));
        Alert.alert(i18n.t('me_query_err') + ' ' + i18n.t('please_login_again'));
        updateMyContext({ myId: -1, myEmail: '', mySettingId: -1, myJwt: '' });
        result = false;
    }

    return result;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'rgb(226, 226, 226)',
    },
    categoryTitle: {
        marginHorizontal: 15,
        fontSize: 18 * fontScale,
        fontWeight: 'bold',
        color: '#212121',
        backgroundColor: '#e0e0e0',
    },
    categoryTitle2: {
        fontSize: 15 * fontScale,
        color: '#0046d3',
        marginRight: 10,
    },
    ypageSublistCatItem2: {
        backgroundColor: '#f6c417',
    },
    ypageSublistCatItem2b: {
        marginTop: 5,
        backgroundColor: '#c1262d',
    },
    ypageSublistCatTitle2: {
        alignSelf: 'flex-start',
        fontFamily: 'CustomHangul',
        fontSize: 16 * fontScale,
        lineHeight: 36 * fontScale,
        color: '#272727',
        marginLeft: 25,
    },
    ypageItemContainer: {
        flex: 1,
        marginBottom: 5,
        backgroundColor: 'yellow'
    },
    ypageItemContainerInner: {
        alignSelf: 'center',
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    ypageDate: {
        fontFamily: 'CustomHangul',
        fontSize: 10 * fontScale,
        fontWeight: 'normal',
        lineHeight: 12 * fontScale,
        color: '#848484',
    },
    ypageImage: {
        width: 135 * fontScale,
        height: 110 * fontScale,
        marginRight: 10,
    },
    ypageBenefits: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 60 * fontScale,
        height: 21 * fontScale,
        backgroundColor: '#c1262d',
        color: '#fff',
        fontSize: 11 * fontScale,
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: 20 * fontScale,
    },
    ypageText: {
        flex: 1,
        justifyContent: 'space-between',
        fontFamily: 'CustomHangul',
        marginBottom: 5,
        marginRight: 10,
    },
    ypageTitle: {
        fontFamily: 'CustomHangul',
        fontSize: 20 * fontScale,
        lineHeight: 23 * fontScale,
        color: '#212121',
        marginTop: 11,
        width: windowWidth - 200,
    },
    ypageVenue: {
        fontSize: 13,
        lineHeight: 15,
        color: '#595b60',
        marginTop: 3,
        marginBottom: 9,
    },
    eventItemContainer: {
        flex: 1,
        marginBottom: 12,
        backgroundColor: '#fff'
    },
    eventItemContainerInner: {
        alignSelf: 'center',
    },
    eventDate: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
        fontWeight: 'bold',
        lineHeight: 20 * fontScale,
        color: '#c1262d',
        marginTop: 12,
        marginBottom: 12,
        marginLeft: 20,
    },
    eventTitle: {
        flex: 1,
        fontFamily: 'CustomHangul',
        fontSize: 18 * fontScale,
        lineHeight: 20 * fontScale,
        color: '#2b2e34',
        marginTop: 11,
        marginLeft: 20,
    },
    eventVenue: {
        fontSize: 13 * fontScale,
        lineHeight: 15 * fontScale,
        color: '#595b60',
        marginTop: 3,
        marginBottom: 9,
        marginLeft: 20,
    },
    eventArticle: {
        flex: 1,
        alignSelf: 'center',
    },
    eventArticleImage: {
        width: '100%',
        alignSelf: 'center',
        height: 210,
        marginTop: 22,
        marginBottom: 16,
    },
    eventArticleDate: {
        fontSize: 15,
        lineHeight: 17,
        color: '#595b60',
        marginLeft: 35,
    },
    eventArticleTitle: {
        fontSize: 24,
        lineHeight: 27,
        letterSpacing: 0,
        marginTop: 16,
        marginBottom: 4,
        marginLeft: 35,
        marginRight: 35,
    },
    eventArticleVenue: {
        fontSize: 15,
        lineHeight: 17,
        color: '#85868a',
        marginLeft: 35,
    },
    eventArticleFootnote: {
        fontSize: 10,
        lineHeight: 21,
        color: '#212121',
        textAlign: 'center',
    },
    ypageArticle: {
        flex: 1,
        alignSelf: 'center',
    },
    ypageArticleImage: {
        width: '100%',
        alignSelf: 'center',
        height: 210,
        marginTop: 22,
        marginBottom: 16,
    },
    ypageArticleBenefits: {
        position: 'absolute',
        top: 10,
        left: 40,
        width: 51,
        height: 50,
        backgroundColor: '#c1262d',
        color: '#fff',
        fontSize: 18,
        textAlign: 'center',
    },
    ypageArticleDate: {
        fontSize: 15,
        lineHeight: 17,
        color: '#595b60',
        marginTop: 35,
        marginBottom: 20,
        marginLeft: 35,
    },
    ypageArticleTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 27,
        letterSpacing: 0,
        marginTop: 16,
        marginBottom: 4,
        marginLeft: 35,
        marginRight: 35,
        color: '#2b2e34',
    },
    ypageArticleSubtitle: {
    },
    ypageArticleVenue: {
        fontSize: 15,
        lineHeight: 17,
        color: '#85868a',
        marginLeft: 35,
    },
    ypageArticleFootnote: {
        fontSize: 10,
        lineHeight: 21,
        color: '#212121',
        textAlign: 'center',
    },
    ypageSublistItemContainer: {
        backgroundColor: 'rgb(226, 226, 226)',
    },
    ypageSublistItemContentContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomColor: '#e2e2e2',
        borderBottomWidth: 1,
    },
    ypageSublistItemContent1: {
        fontFamily: 'CustomHangul',
        fontSize: 16 * fontScale,
        color: '#595b60',
        marginLeft: 10,
        marginVertical: 5,
    },
    ypageSublistItemContent2: {
        fontFamily: 'CustomHangul',
        fontSize: 16 * fontScale,
        color: '#595b60',
        textAlign: 'right',
        marginRight: 10,
    },
    settingTitle: {
        color: "#c1262d", fontFamily: "CustomHangul", fontSize: 22 * fontScale, paddingVertical: 15 * fontScale,
    },
    settingtext: {
        color: '#595b60', fontFamily: "CustomHangul", fontSize: 20 * fontScale, paddingTop: 5, paddingBottom: 5,
    },
    settingButton: Platform.select({
        ios: {
            textAlign: 'left', fontSize: 20 * fontScale, paddingTop: 0, paddingBottom: 0, color: '#595b60', alignSelf: 'flex-start'
        },
        android: {
            textAlign: 'left', fontSize: 20 * fontScale, paddingTop: 0, paddingBottom: 0, color: '#595b60', alignSelf: 'flex-start'
        },
    }),
    settingSwitchPush: {
        flex: 1, paddingTop: 1, paddingBottom: 5, marginLeft: 60
    },
    switch: {
        alignSelf: 'flex-start',
    },
    settingTextInput: {
        width: 200, height: 40, borderWidth: 1, borderRadius: 4, marginVertical: 5, padding: 10, alignSelf: "center", backgroundColor: '#ffffff',
    },
    buttonContainer: Platform.select({
        ios: {
            marginVertical: 10,
        },
        android: {
            marginVertical: 10,
        },
    }),
    modalView: {
        backgroundColor: 'rgba(255,255,255, 1)',
        position: 'absolute',
        width: windowWidth,
        top: 60,
        height: height - 120,
    },
    modalContainer: {
        width: windowWidth,
        height: height,
        backgroundColor: 'rgba(20, 20, 20, 0.8)',
    },
    modalText: {
        fontFamily: 'CustomHangul',
        fontSize: 16 * fontScale,
        color: '#272727',
        marginBottom: 25,
        textAlign: 'center',
    },
    appButtonContainer: {
        marginTop: 30 * fontScale
    },
    appButtonText: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
        color: "#fff",
        alignSelf: "center",
        textTransform: "uppercase"
    }
});

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        fontFamily: 'CustomHangul',
        width: '100%',
        fontSize: 16 * fontScale,
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputAndroid: {
        fontFamily: 'CustomHangul',
        width: '100%',
        fontSize: 16 * fontScale,
        paddingHorizontal: 5,
        paddingVertical: 1,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 8,
        color: 'black',
        backgroundColor: 'rgb(242, 242, 242)',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputWeb: {
        fontFamily: 'CustomHangul',
        width: '100%',
        fontSize: 16 * fontScale,
        paddingHorizontal: 5,
        paddingVertical: 1,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 8,
        color: 'black',
        backgroundColor: 'rgb(242, 242, 242)',
        paddingRight: 30, // to ensure the text is never behind the icon
    },
});