import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, Dimensions, Platform, ImageBackground } from 'react-native';
import i18n from 'i18n-js';

export default class SliderEntry extends Component {

    render () {
        const { data: { name, name_en, description, description_en, image }, onPress } = this.props;
        let imgUrl = image ? { uri: 'https://cakory-ds.com'+image.url} : require('../assets/images/no-image.png');

        return (
            <TouchableOpacity
              activeOpacity={0.8}
              style={styles.slideInnerContainer}
              onPress={onPress}
              >
                <View style={styles.shadow} />
                <View style={styles.imageContainer}>
                    <Image source={ imgUrl } style={styles.image} />
                    <View style={styles.radiusMask} />
                </View>
                <View style={styles.textContainer}>
                    <Text
                        style={styles.title}
                        numberOfLines={2}
                    >
                        { i18n.locale === 'ko' ? name : name_en }
                    </Text>
                    <Text
                      style={styles.subtitle}
                      numberOfLines={2}
                    >
                        { i18n.locale === 'ko' ? description: description_en }
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }
}

export class SliderEntry2 extends Component {

    render () {
        const { data: { name, name_en, description, description_en, image }, onPress } = this.props;
        //console.log("SliderEntry2: ", name, ", ", image);
        let imgUrl = image;

        return (
            <TouchableOpacity
              activeOpacity={0.8}
              style={styles.slideInnerContainer}
              onPress={onPress}
              >
                <View style={styles.shadow} />
                <View style={styles.imageContainer}>
                    <Image source={ imgUrl } style={styles.image} />
                </View>
                <View style={styles.textContainer}>
                    <Text
                        style={styles.title}
                        numberOfLines={2}
                    >
                        { i18n.locale === 'ko' ? name : name_en }
                    </Text>
                    <Text
                      style={styles.subtitle}
                      numberOfLines={2}
                    >
                        { i18n.locale === 'ko' ? description: description_en }
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }
}

const IS_IOS = Platform.OS === 'ios';
const { width: viewportWidth, height: viewportHeight } = Dimensions.get('window');

function wp (percentage) {
    const value = (percentage * viewportWidth) / 100;
    return Math.round(value);
}

const slideHeight = viewportHeight * 0.35;
const slideWidth = viewportWidth > 480 ? 300 : wp(60);
const itemHorizontalMargin = wp(4);

export const sliderWidth = viewportWidth;
export const itemWidth = slideWidth + itemHorizontalMargin * 2;

const entryBorderRadius = 8;

const styles = StyleSheet.create({
    slideInnerContainer: {
        width: itemWidth,
        height: slideHeight,
        paddingHorizontal: itemHorizontalMargin,
        paddingBottom: 18 // needed for shadow
    },
    shadow: {
        position: 'absolute',
        top: 0,
        left: itemHorizontalMargin,
        right: itemHorizontalMargin,
        bottom: 18,
        shadowColor: '#1a1917',
        shadowOpacity: 0.25,
        shadowOffset: { width: 0, height: 10 },
        shadowRadius: 10,
        borderRadius: entryBorderRadius
    },
    imageContainer: {
        flex: 1,
        marginBottom: IS_IOS ? 0 : -1, // Prevent a random Android rendering issue
        backgroundColor: 'white',
        borderTopLeftRadius: entryBorderRadius,
        borderTopRightRadius: entryBorderRadius
    },
    image: {
        //...StyleSheet.absoluteFillObject,
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
        //borderRadius: IS_IOS ? entryBorderRadius : 0,
        borderTopLeftRadius: entryBorderRadius,
        borderTopRightRadius: entryBorderRadius
    },
    // image's border radius is buggy on iOS; let's hack it!
    radiusMask: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: entryBorderRadius,
        backgroundColor: 'white'
    },
    textContainer: {
        justifyContent: 'center',
        paddingTop: 20 - entryBorderRadius,
        paddingBottom: 20,
        paddingHorizontal: 16,
        backgroundColor: '#f4f4f4',
        borderBottomLeftRadius: entryBorderRadius,
        borderBottomRightRadius: entryBorderRadius
    },
    title: {
        color: '#1a1917',
        fontSize: 13,
        fontWeight: 'bold',
        letterSpacing: 0.5
    },
    subtitle: {
        marginTop: 6,
        color: '#888888',
        fontSize: 12,
        fontStyle: 'italic'
    },
});
