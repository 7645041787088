import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, Alert, StatusBar, Dimensions, ActivityIndicator } from 'react-native';
import i18n from 'i18n-js';
import { gql, useMutation } from '@apollo/client';
import { MyButton } from '../../components/MyComponents';

const { width, height } = Dimensions.get('window');
const fontScale = width > 480 ? 1.5 : 1;

const ForgotPasswordScreen = ({ navigation }) => {
    const [email, setEmail] = useState('');

    const FORGOT_PASSWORD_MUTATION = gql`
        mutation forgotPasswordMutation($email: String!) {
            forgotPassword (email: $email) {
                ok,
            }
        }
    `;

    const [mutateFunction, { loading, error, data }] = useMutation(FORGOT_PASSWORD_MUTATION);

    if (loading) console.log(`Trying to send forgot_password email ...`);
    if (error) console.log(`Sending forgot_password email error! ${error.message}`);
    if (data) console.log('Sending forgot_password email suceeded! ' + JSON.stringify(data));

    const onResetPasswordPress = () => {
        console.log("onResetPasswordPress is called. email: " + email);
        mutateFunction({ variables: { email: email } })
            .then(res => {
                Alert.alert(i18n.t('noti_change_pwd_m'));
                navigation.goBack();
            })
            .catch(error => {
                console.log("error..." + error.graphQLErrors[0].extensions.code + ": " + error.graphQLErrors[0].extensions.exception.data.message[0].messages[0].message);
                Alert.alert(i18n.t('error_change_pwd_m'));
            });
    }

    return (
        <View style={styles.container}>
            <StatusBar barStyle="light-content" hidden={false} />
            <Text style={{ fontSize: 19 * fontScale, fontWeight: 'bold', color: '#2b2e34', marginVertical: 25 * fontScale }}>{i18n.t('reset_pwd_m')}</Text>
            <Text style={{ fontSize: 18 * fontScale, marginBottom: 20 * fontScale }}>
                {i18n.t('reset_pwd_guide_m')}
            </Text>

            <TextInput style={styles.textInput}
                value={email}
                onChangeText={(text) => { setEmail(text) }}
                placeholder={" " + i18n.t('email')}
                placeholderTextColor="#d0d0d0"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
            />

            {loading ?
                <ActivityIndicator style={{ marginTop: 20 * fontScale }}/>
                :
                <MyButton onPress={onResetPasswordPress} style={{ marginTop: 20 * fontScale }} children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>} />
            }
        </View>
    );
}

export default ForgotPasswordScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1, alignItems: "center", width: '90%', alignSelf: 'center',
    },
    textInput: {
        fontSize: 14 * fontScale,
        width: 200 * fontScale, height: 40 * fontScale, borderWidth: 1, borderRadius: 4,
    },
    button: {
        marginVertical: 10 * fontScale,
        paddingHorizontal: 15 * fontScale
    },
    appButtonText: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
        color: "#fff",
        alignSelf: "center",
        textTransform: "uppercase"
    }
});