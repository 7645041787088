import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  Image, Alert, StyleSheet, Text, TouchableOpacity, View, FlatList, AppState,
  StatusBar, Dimensions, TextInput, ActivityIndicator, Keyboard, SafeAreaView, Modal, Platform, Linking
} from 'react-native';

import { HeaderCenterText, HeaderNotificationImage, HeaderMenuImage } from '../components/CustomHeader';
import { Ionicons } from '@expo/vector-icons';
import Communications from 'react-native-communications';
import { gql, useQuery } from '@apollo/client';
import i18n from 'i18n-js';
import RNPickerSelect from 'react-native-picker-select';
import { areaList, LeftmostLng, RightmostLng } from '../assets/data/geo';
import * as Location from 'expo-location';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import MapView, { Marker, Callout, PROVIDER_GOOGLE } from 'react-native-maps';
import MyContext from '../components/MyContext';
import ApiKeys from '../constants/ApiKeys';
import { useIsFocused } from '@react-navigation/core';
import { useFocusEffect } from '@react-navigation/native';
import { sendLog } from '../components/StrapiInterface';
import SliderEntry, { sliderWidth, itemWidth, SliderEntry2 } from '../components/SliderEntry';
import analytics from '@react-native-firebase/analytics';
import * as Notifications from 'expo-notifications';
import * as Sentry from 'sentry-expo';
import { ko, en } from '../assets/data/i18n'
import { MyButton } from '../components/MyComponents';
import Checkbox from 'expo-checkbox';

const newclient = new ApolloClient({
  uri: ApiKeys.StrapiConfig.URI,
  cache: new InMemoryCache(),
});

const { width: windowFullWidth, height } = Dimensions.get('window');
const windowWidth = windowFullWidth > 800 ? 800 : windowFullWidth;
const fontScale = windowWidth > 480 ? 1.5 : 1;

const cardNames = ['"비빔밥"', '"불고기"', '"갈비"', '"잡채"', '"삼계탕"', '"냉면"', '"부대찌개"'];
const cardData = [
  {
    name: '불고기',
    name_en: 'Bulgogi',
    description: '',
    description_en: 'Sliced beef that has been marinated in soy sauce, honey, minced green onion, garlic, sesame seeds, and pepper, and then grilled.',
    image: require('../assets/images/food/bulgogi-1638823593905.jpeg'),
  },
  {
    name: '부대찌개',
    name_en: 'Budae jjigae (a.k.a. Army stew)',
    description: '',
    description_en: 'A fusion dish made with ham, sausage, kimchi, pork, and bean curd. Spicy. Usually comes with ramyeon noodles.',
    image: require('../assets/images/food/budaejjigae-1638836742331.jpeg'),
  },
  {
    name: '비빔밥',
    name_en: 'Bibimbap',
    description: '',
    description_en: 'Steamed sushi rice with variety of vegetables. Usually comes with fried egg or stir-fried ground beef and mixed with gochujang (red chili paste).',
    image: require('../assets/images/food/bibimbap-1738580_640_427.jpg'),
  },
  {
    name: '갈비',
    name_en: 'Galbi',
    description: '',
    description_en: 'One of the most popular Korean dishes. Beef or pork. The beef ribs are marinated in soy sauce, while the pork ribs could be marinated in seasoned gochujang.',
    image: require('../assets/images/food/galbi-1638823458245.jpeg'),
  },
  {
    name: '김치 (판매용)',
    name_en: 'Kimchi (for sale)',
    description: '',
    description_en: 'Traditional Korean side dish of salted and fermented vegetables.',
    image: require('../assets/images/food/kimchi-1645043431788-min.jpg'),
  },
  {
    name: '잡채',
    name_en: 'Japchae',
    description: '',
    description_en: 'Glass noodles stir-fried with beef and assorted mushrooms and vegetables.',
    image: require('../assets/images/food/japchae-chop-suey-g2e94c708f_640.jpg'),
  },
  {
    name: '삼계탕',
    name_en: 'Samgyetang',
    description: '',
    description_en: 'Ginseng Chicken Soup',
    image: require('../assets/images/food/samgyetang-6481564_640_424.jpg'),
  },
  {
    name: '냉면',
    name_en: 'Naengmyeon',
    description: '',
    description_en: 'Cold Buckwheat Noodles. There are two versions. One is served in chilled non-spicy beef broth. The other is with hot spicy sauce.',
    image: require('../assets/images/food/nangmyeon-1638838002931-min.jpeg'),
  },
  {
    name: '양념치킨',
    name_en: 'Yangnyeom chicken',
    description: '',
    description_en: 'Fried Chicken coated with Syrup or Seasoning',
    image: require('../assets/images/food/yangnyeom-chicken-g59690aeb0_640.jpg'),
  },
];

const tag_alts = {
  "곱창전골": "곱창",
  "갈비탕": "갈비",
  "순대국": "순대",
  "도시락": "컵밥",
  "그로서리": "식품점",
  "닭발": "불닭",
  "돼지국밥": "국밥",
  "수육": "보쌈",
  "양곱창": "곱창",
  "양념갈비": "갈비",
  "한국음식점": "한식",
  "한국식품점": "식품점",
  "한국식품": "식품점",
  "kbbq": "galbi",
  "bbq": "galbi",
  "돈가스": "돈까스",
  "카센타": "자동차",
  "카센터": "자동차",
};

const earth_radius = 6371.0; // km
const degrees_to_radians = Math.PI / 180.0;
const radians_to_degrees = 180.0 / Math.PI;

const distance_in_latitude = (km) => {
  return (km / earth_radius) * radians_to_degrees;
}

const distance_in_longitude = (latitude, km) => {
  let r = earth_radius * Math.cos(latitude * degrees_to_radians);
  return (km / r) * radians_to_degrees;
}

export function SearchYpageMainScreen({ navigation }) {
  const [geoLoc, setGeoLoc] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [provinceIdx, setProvinceIdx] = useState(0);
  const [localityIdx, setLocalityIdx] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [provinceGps, setProvinceGps] = useState('Outofbound');
  const [bannerIndex, setBannerIndex] = useState(0);

  const bannerFlRef = useRef(null);
  const bannersRef = useRef(banners);

  //console.log("[ SearchYpageMainScreen ] is called... ", geoLoc, ",", keyword, ",", provinceIdx, ",", localityIdx, ",", bannerIndex, ",", data ? data.length : 0);

  var { myLocale, myProvinceIdx, myLocalityIdx, myGpsLatitude, myGpsLongitude, myBannerRefreshTime, updateMyContext } = useContext(MyContext);
  //console.log("[ SearchYpageMainScreen ] locale: ", i18n.locale, ", myProvinceIdx: ", myProvinceIdx, ", myLocalityIdx: ", myLocalityIdx);

  if (myLocale && myLocale != i18n.locale) {
    console.log("[ SearchYpageMainScreen] setting_locale_err");
    i18n.locale = myLocale;
  }

  var provinceList = [];
  var localityList = [];
  var localityLabelList = '';

  for (let i = 0; i < areaList.length; i++) {
    provinceList = [...provinceList, { label: i18n.t(areaList[i].label), value: areaList[i].value }];
  }

  for (let i = 0; i < areaList[provinceIdx].cities.length; i++) {
    localityList = [...localityList, { label: i18n.t(areaList[provinceIdx].cities[i].label), value: areaList[provinceIdx].cities[i].value }];
    localityLabelList = localityLabelList + `"${areaList[provinceIdx].cities[i].label}", `;
  }

  // We use myProvinceIdx and myLocalityIdx context for getting banners.
  var area_cond = '';
  var area_radius = 25;
  var center_latitude = 51.0447;
  var center_longitude = -114.0719;
  var lat_delta = 0.45; // radius 50km
  var lng_delta = 0.45; // radium 50km in calgary
  var myFetchPolicy = 'cache-first';

  if (provinceIdx === 0) {
    switch (localityIdx) {
      case 0: area_radius = 5; break;
      case 1: area_radius = 15; break;
      case 2: area_radius = 25; break;
      case 3: area_radius = 50; break;
      default: area_radius = 15; break;
    }
    lat_delta = distance_in_latitude(area_radius);
    lng_delta = distance_in_longitude(myGpsLatitude, area_radius);
    center_latitude = myGpsLatitude;
    center_longitude = myGpsLongitude;

    area_cond = area_cond + `geo_lat_lte: ${myGpsLatitude + lat_delta} , geo_lat_gte: ${myGpsLatitude - lat_delta}, geo_lng_lte: ${myGpsLongitude + lng_delta}, geo_lng_gte: ${myGpsLongitude - lng_delta}, `;
    area_cond = `_or:[ { ${area_cond} }, { province: "All" }, { province: "${provinceGps}", locality: "All"} ]`;

    myFetchPolicy = 'network-only'; // ignore cache with current location
  }
  else {
    center_latitude = areaList[provinceIdx].cities[localityIdx].lat;
    center_longitude = areaList[provinceIdx].cities[localityIdx].lng;

    area_cond = area_cond + `province: "${areaList[provinceIdx].label}", geo_lat_gt: "0", `;

    switch (areaList[provinceIdx].cities[localityIdx].value) {
      case '100': area_cond = area_cond + `locality_nin: [ ${localityLabelList} ], `;
        break;
      case '1000': // no need to include locality.
        break;
      default: area_cond = area_cond + `locality: "${areaList[provinceIdx].cities[localityIdx].label}", `;
        break;
    }
    area_cond = `_or:[ { ${area_cond} }, { province: "All" }, { province: "${areaList[provinceIdx].label}", locality: "All"} ]`;
  }


  useEffect(() => {
    navigation.setOptions({
      headerShown: false
    })

    _getTags();

    _getNotificationTimestamp();

    const bannerInterval = setInterval(_bannerScrolling, 3000);

    return () => {
      clearInterval(bannerInterval);
    }
  }, []);

  useEffect(() => {
    // If this is the first running, then go to the setting page.
    if (myLocale == '') navigation.navigate('Settings');
  }, [myLocale]);

  // When users change the settings of province and locality, the search screen should be affected.
  useEffect(() => {
    console.log("[ SearchYpageMainScreen ] useEffect is called. myProvinceIdx:", myProvinceIdx, ", myLocalityIdx:", myLocalityIdx);
    setProvinceIdx(myProvinceIdx);
    setLocalityIdx(myLocalityIdx);
  }, [myProvinceIdx, myLocalityIdx]);

  // Refresh banners state when location related variables change.
  useEffect(() => {
    _getBanners();
  }, [provinceIdx, localityIdx, provinceGps, myBannerRefreshTime]);

  // Location permission access should be performed only once.
  useFocusEffect(
    useCallback(() => {
      console.log("[ SearchYpageMainScreen ] useFocusEffect is called. myProvinceIdx:", myProvinceIdx, ", myLocalityIdx:", myLocalityIdx, ", myBannerRefreshTime:", myBannerRefreshTime);
      setKeyword('');
      //setProvinceIdx(myProvinceIdx); 
      //setLocalityIdx(myLocalityIdx);
      //!!! important!!!! useFocusEffect does not come with re-rendering. Therefore this remembers old context.
      //                  Therefore, myProvinceIdx and myLocalityIdx could be different the actual current values.

      // Do I need to refetch geolocation whenever this page is focused?
      Location.requestForegroundPermissionsAsync()
        .then(async res => {
          //console.log("Location: ", JSON.stringify(res))
          if (res.status !== 'granted') {
            sendLog(i18n.t("geolocation_err") + '[e00]' + JSON.stringify(err));
            Alert.alert(i18n.t("geolocation_err"));
            console.log('Permission to access location was denied');
            setGeoLoc(true);
            return;
          }

          console.log("[ SearchYPageMainScreen ] useFocusEffect. Inside the requestForegroundPermissionsAsync.");
          var accuracyObj = {};
          if (Platform.OS == "android") {
            accuracyObj = {};
          } else {
            accuracyObj = { accuracy: Location.Accuracy.Balanced };
          }

          // Use promise.race to process the timeout of getCurrentPositionAsync.
          // getCurrentPositionAsync tends to hang up on some Android phones.
          // Because it does not suppport a timeout parameter, I have to use promise.race.
          await Promise.race([
            //            Location.getCurrentPositionAsync(accuracyObj),
            Location.getLastKnownPositionAsync(),
            new Promise((resolve, reject) => {
              setTimeout(() => {
                reject(new Error("Promise execution timed out."));
              }, 7000); // Timeout of 7 seconds.
            })
          ]).then(res => {
            console.log("[ SearchYpageMainScreen ] Geolocation Position: ", res.coords, ", myLocale: ", myLocale, ", ", Date.now());
            updateMyContext({ myGpsLatitude: res.coords.latitude });
            updateMyContext({ myGpsLongitude: res.coords.longitude });
            _getAreaForAd(res.coords.latitude, res.coords.longitude);
            setGeoLoc(true);
            if (Date.now() - myBannerRefreshTime > 1000 * 60 * 10) { // minimum interval is 10 min.
              myBannerRefreshTime = Date.now(); // myBannerRefreshTime is not reflected with the change of context because
              // this is a callback function body having its own scope.
              updateMyContext({ myBannerRefreshTime: Date.now() });
            }
          })
            .catch(err => {
              sendLog(i18n.t("geolocation_err") + '[e01]' + JSON.stringify(err));
              Alert.alert(i18n.t("geolocation_err"));
              console.log("Location Position Error: ", JSON.stringify(err));
              setGeoLoc(true);
            })
        })
        .catch(err => {
          sendLog(i18n.t("geolocation_err") + '[e02]' + JSON.stringify(err));
          Alert.alert(i18n.t("geolocation_err"));
          console.log("Location Permission Error: ", JSON.stringify(err));
          setGeoLoc(true);
        })

      const subscription = AppState.addEventListener('change', _handleAppStateChange);

      return () => {
        subscription.remove();
      }
    }, [])
  );

  const _getNotificationTimestamp = async () => {
    const GET_EXPONOTICATIONS = gql`
      query getExpoNotifications {
          exponotifications (sort: "id:desc", limit: 1, where: { status: "finished" }) {
              id,
              status,
              created_at
          },
      }
    `;

    try {
      const res = await newclient.query({
        query: GET_EXPONOTICATIONS, fetchPolicy: 'no-cache'
      });

      console.log("[ SearchScreen.js ] get myNotiArriveTime.", res.data.exponotifications[0]);

      if (res) {
        const date = new Date(res.data.exponotifications[0].created_at);
        console.log("...", date.getTime());
        updateMyContext({ myNotiArriveTime: date.getTime() });
      }
    } catch (err) {
      if (Platform.OS === 'android' || Platform.OS === 'ios')
        Sentry.Native.captureException(err);
      console.log(" [SearchScreen.js ] get_exponotifications Error: ", err);
    }
  }

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'active') {
      console.log('[ SearchYpageMainScreen] AppState has come to active.');
      _getNotificationTimestamp();
    }
  }

  const _pressSearch = () => {
    console.log("[ SearchYpageMainScreen] _pressSearch is called.");

    Keyboard.dismiss();

    if (keyword.trim()) {
      navigation.navigate("SearchList", {
        keyword: keyword,
        provinceIdx: provinceIdx,
        localityIdx: localityIdx,
      });
    }
  }

  const geolinesForAd = [
    { // center of BC
      lng: -124.171759,
      points: [
        { lat: 54.284135, lng: -124.566329, name: 'British Columbia' }
      ]
    },
    { // center of AB
      lng: -113.445400,
      points: [
        { lat: 53.743604, lng: -120.080100, name: 'British Columbia' },
        { lat: 53.728696, lng: -119.538224, name: 'Alberta' },
        { lat: 50.848855, lng: -115.763844, name: 'British Columbia' },
        { lat: 50.867982, lng: -115.486816, name: 'Alberta' },
        { lat: 49.096645, lng: -114.396704, name: 'British Columbia' },
        { lat: 49.071885, lng: -113.943040, name: 'Alberta' }
      ]
    },
    { // center of SK
      lng: -105.815633,
      points: [
        { lat: 53.241302, lng: -110.086900, name: 'Alberta' },
        { lat: 53.196029, lng: -109.923077, name: 'Saskatchewan' }
      ]
    },
    { // center of MT
      lng: -95.889349,
      points: [
        { lat: 55.608493, lng: -102.084778, name: 'Saskatchewan' },
        { lat: 55.537248, lng: -101.782336, name: 'Manitoba' }
      ]
    },
    { // center of ON
      lng: -86.494830,
      points: [
        { lat: 52.820951, lng: -95.290134, name: 'Manitoba' },
        { lat: 52.767806, lng: -95.026462, name: 'Ontario' },
        { lat: 56.817353, lng: -89.181735, name: 'Manitoba' },
        { lat: 56.745128, lng: -88.962009, name: 'Ontario' }
      ]
    },
    { // end of QC
      lng: -68.659275,
      points: [
        { lat: 47.537521, lng: -79.623630, name: 'Ontario' },
        { lat: 47.448442, lng: -79.381930, name: 'Quebec' },
        { lat: 45.788512, lng: -77.250583, name: 'Ontario' },
        { lat: 45.972069, lng: -77.008884, name: 'Quebec' },
        { lat: 45.360866, lng: -75.826585, name: 'Ontario' },
        { lat: 45.383508, lng: -75.828734, name: 'Quebec' },
        { lat: 45.451379, lng: -75.665432, name: 'Ontario' },
        { lat: 45.463746, lng: -75.689417, name: 'Quebec' },
        { lat: 45.554476, lng: -74.403539, name: 'Ontario' },
        { lat: 45.567366, lng: -74.364087, name: 'Quebec' },
        { lat: 45.296046, lng: -74.485074, name: 'Ontario' },
        { lat: 45.305296, lng: -74.448252, name: 'Quebec' }
      ]
    },
    { // end of Canada
      lng: -51.762302,
      points: [
        { lat: 51.018864, lng: -62.353122, name: 'Nova Scotia' }
      ]
    }
  ];

  const _getAreaForAd = (lat, lng) => {
    console.log("[ SearchYpageMainScreen] _getAreaForAd is called.");

    if (lng < LeftmostLng || lng > RightmostLng) {
      console.log("!! Oops.. The location is out of canada.");
      setProvinceGps("Outofbound");
      return;
    }

    var idx = 0;
    while (idx < geolinesForAd.length && geolinesForAd[idx].lng < lng) {
      idx++;
    }

    console.log("***", idx);
    var nearestPoint = 0;
    var nearestDistance = 10000.0;
    for (let i = 0; i < geolinesForAd[idx].points.length; i++) {
      let distance = Math.sqrt(
        (lat - geolinesForAd[idx].points[i].lat) * (lat - geolinesForAd[idx].points[i].lat) +
        (lng - geolinesForAd[idx].points[i].lng) * (lng - geolinesForAd[idx].points[i].lng)
      );
      console.log("!! distance: ", distance);
      if (distance < nearestDistance) {
        nearestDistance = distance;
        nearestPoint = i;
      }
    }
    setProvinceGps(geolinesForAd[idx].points[nearestPoint].name);
    console.log("!! Found: idx ", nearestPoint, ", ", geolinesForAd[idx].points[nearestPoint].name);
  }

  const searchViewData = [
    {
      id: '0',
      element: <View style={{ flexDirection: 'row', alignSelf: 'flex-end', top: Platform.OS === 'ios' ? 1.5 : 7.5 }}>
        {geoLoc ? <View style={{ flexDirection: 'row' }}>
          <HeaderNotificationImage onPress={() => navigation.navigate('NotificationList')} />
          <HeaderMenuImage onPress={() => navigation.navigate('MenuList')} />
        </View>
          :
          <Text style={{ height: 41, fontStyle: 'italic', color: '#a0a0a0' }}></Text>}

      </View>
    },
    {
      id: '1',
      element: <View style={{ alignSelf: 'flex-start', marginLeft: 20, marginVertical: 20 * fontScale }}>
        <HeaderCenterText title={i18n.t('what_are_you_looking_for')} onPress={() => navigation.navigate('SearchList')} />
      </View>
    },
    {
      id: '2',
      element:
        <View style={{ backgroundColor: '#f8f8f8', marginHorizontal: 10 * fontScale }}>
          <View style={{ backgroundColor: '#f8f8f8' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 5 * fontScale, marginHorizontal: 10 * fontScale }}>
              <TextInput style={styles.textInput}
                value={keyword}
                onChangeText={(text) => { setKeyword(text.trim()); }}
                onSubmitEditing={_pressSearch}
                placeholder={i18n.t("enter_search")}
                placeholderTextColor="#d0d0d0"
                autoCapitalize="none"
                autoCorrect={false}
              />
              <TouchableOpacity onPress={_pressSearch}><Ionicons name="search" size={22} color="#272727" style={{ top: 5, left: 5 }} /></TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 15 * fontScale, marginHorizontal: 10 * fontScale }}>
            <Text style={[styles.basic, { marginRight: 7 * fontScale }]}>{i18n.t('area_for_search')}</Text>
            <RNPickerSelect
              style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
              value={provinceList[provinceIdx].value}
              useNativeAndroidPickerStyle={false}
              fixAndroidTouchableBug={true}
              placeholder={{}}
              onValueChange={(value, index) => {
                //console.log("...RNPickerSelect province... index: ", index);
                setProvinceIdx(index);
                setLocalityIdx(0);
              }}
              Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
              items={provinceList}
            />
            <View style={{ marginHorizontal: 3 * fontScale }}></View>
            <RNPickerSelect
              style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
              value={localityList[localityIdx].value}
              useNativeAndroidPickerStyle={false}
              fixAndroidTouchableBug={true}
              placeholder={{}}
              onValueChange={(value, index) => {
                //console.log("...RNPickerSelect locality... index: ", index);
                setLocalityIdx(index);
              }}
              Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
              items={localityList}
            />
          </View>
        </View>
    },
  ];

  const _bannerScrolling = () => {
    setBannerIndex(current => {
      let nextIndex = current + 1;
      //console.log("_bannerScrolling: ", current, bannersRef.current.length);
      if (bannersRef.current && nextIndex >= bannersRef.current.length) {
        nextIndex = 0;
      }

      if (bannerFlRef && bannersRef.current.length) {
        //console.log("_bannerScrolling: scrollToIndex: ", nextIndex);
        bannerFlRef.current.scrollToIndex({ index: nextIndex, animated: true })
      }

      return nextIndex;
    });
  }

  const _getTags = async () => {
    console.log("[ SearchYpageMainScreen ] (_getTags) is called.");

    var filter_param = '"한식", "일식", "중식", "디저트", "치킨", "분식", "길거리음식", "서양식"';
    // Do not fetch from server because it seems to be too slow.


    var GET_TAGS = gql`
        query getTags {
          tags (where: { categories: { name_in: [${filter_param}] } }) {
            id,
            name,
            name_en,
            description,
            description_en,
            image { id, url },
            categories { name }
          }
        }
      `;

    newclient.query({
      query: GET_TAGS
    })
      .then(res => {
        //console.log(res);
        if (res) {
          console.log("[ SearchScreen.js ] tags: ", JSON.stringify(res.data.tags[0]));
          if (res.data.tags) {
            res.data.tags.map(e => {
              if (ko[e.name]) console.log('K->K:', e.name, ko[e.name]);
              else {
                ko[e.name] = e.name;
              }
              if (en[e.name]) console.log('K->E:', e.name, en[e.name]);
              else {
                en[e.name] = e.name_en;
              }
              if (ko[e.name_en.toLowerCase()]) console.log('E->K:', e.name_en, ko[e.name_en.toLowerCase()]);
              else {
                ko[e.name_en.toLowerCase()] = e.name;
              }
              if (en[e.name_en.toLowerCase()]) console.log('E->E:', e.name_en, en[e.name_en.toLowerCase()]);
              else {
                en[e.name_en.toLowerCase()] = e.name;
              }
            })
            i18n.translations = { ko, en };
          }
        }
        setLoading(false);
      })
      .catch(err => {
        sendLog('Error on getting Tags data:' + JSON.stringify(err));
        //Alert.alert(i18n.t('search_err') + '[e21]');
        console.log("[ SearchScreen.js ] _getTags: ", err)
        setLoading(false);
      });

    // set data for image carosel.
    setData([...cardData, { id: 100, name: "gotolist" }]);

    setLoading(false);
  }

  const _getBanners = () => {
    const today = new Date();
    today.setHours(23, 59, 59, 0); // without this, this query is always cache-missed.
    const where_cond = `valid_thru_gt: "${today.toISOString()}", `;

    console.log("[ SearchYpageMainScreen ] (_getBanners) is called. where: ", where_cond + area_cond);
    var GET_BANNERS = gql`
          query getBanners {
            banners (where: { ${where_cond + area_cond}} ) {
              id,
              category,
              ref_id,
              desc,
              desc_en,
              url
            }
          }
        `;

    newclient.query({
      query: GET_BANNERS,
      fetchPolicy: myFetchPolicy
    })
      .then(res => {
        //console.log(res);
        if (res) {
          console.log("[ SearchScreen.js ] banners: ", JSON.stringify(res.data.banners));
          if (res.data.banners) { setBanners(res.data.banners); bannersRef.current = res.data.banners; }
        }
      })
      .catch(err => {
        sendLog(i18n.t('banner_err') + '[e01]' + JSON.stringify(err));
        //Alert.alert(i18n.t('banner_err') + ' ' + i18n.t('internet_err'));
        console.log("[ SearchScreen.js ] _getBanners: ", err)
      });
  }

  const _renderItem = ({ item }) => {
    //console.log("**", i18n.t(item.name));
    if (item.name === 'gotolist') {
      return (
        <TouchableOpacity
          onPress={() => navigation.navigate('FoodList')}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <View style={{ backgroundColor: '#f0f0f0', borderRadius: 8, padding: 50, marginHorizontal: 30 }}>
            <Text style={{ fontSize: 13 * fontScale }}>{i18n.t('load_more')} </Text>
          </View>
        </TouchableOpacity>
      )
    }
    else return (
      <SliderEntry2
        data={item}
        onPress={() => navigation.navigate('YpageSublist',
          {
            title: item.name,
            id: -1,
            provinceIdx: provinceIdx,
            localityIdx: localityIdx,
          })}
      />
    )
  }

  const _renderBanner = ({ item }) => {
    //console.log("_renderBanner: ", item);
    var description = i18n.locale === 'en' ? item.desc_en : item.desc;
    if (item.category === 'place') {
      return (
        <TouchableOpacity
          onPress={() => _jumpToSelected(item)}
          style={styles.bannerItemContainer}
        >
          <Text style={[styles.basic, { fontSize: 15 * fontScale, lineHeight: 18 * fontScale }]}><Text style={{ color: 'rgba(193, 38, 45, 1)' }}>{i18n.t("new_place")} | </Text>{description}</Text>
        </TouchableOpacity>
      )
    }
    else if (item.category == 'event') {
      return (
        <TouchableOpacity
          onPress={() => item.url ? Linking.openURL(item.url) : false}
          style={styles.bannerItemContainer}
        >
          <Text style={[styles.basic, { fontSize: 15 * fontScale, lineHeight: 18 * fontScale }]}><Text style={{ color: 'rgba(193, 38, 45, 1)' }}>{i18n.t("new_event")} | </Text>{description}</Text>
        </TouchableOpacity>
      )
    }
    else if (item.category == 'ad') {
      return (
        <TouchableOpacity
          onPress={() => item.url ? Linking.openURL(item.url) : false}
          style={styles.bannerItemContainer}
        >
          <Text style={[styles.basic, { fontSize: 15 * fontScale, lineHeight: 18 * fontScale }]}><Text style={{ color: 'rgba(193, 38, 45, 1)' }}>{i18n.t("new_ad")} | </Text>{description}</Text>
        </TouchableOpacity>
      )
    }
    else if (item.category == 'notice') {
      return (
        <TouchableOpacity
          onPress={() => item.url ? Linking.openURL(item.url) : false}
          style={styles.bannerItemContainer}
        >
          <Text style={[styles.basic, { fontSize: 15 * fontScale, lineHeight: 18 * fontScale }]}><Text style={{ color: 'rgba(193, 38, 45, 1)' }}>{i18n.t("new_notice")} | </Text>{description}</Text>
        </TouchableOpacity>
      )
    }
    else {
      return (
        <></>
      );
    }
  }

  const _jumpToSelected = async (item) => {
    console.log("@@@@@@", item);
    if (item.ref_id && item.category === 'place') {

      const GET_DATA = gql`
        query getData {
          masters ( where: { id:  ${item.ref_id} } ) {
            id,
            name,
            name_en,
            formatted_address,
            phone1,
            phone2,
            email1,
            web,
            geo_lat,
            geo_lng,
            categories { name },
            tags { name }
          }
        }
      `;

      try {
        const res = await newclient.query({ query: GET_DATA });

        if (res) {
          let item = res.data.masters[0];
          let title;
          let title_full;
          let remark;
          let note;
          if (i18n.locale === 'en' && item.name_en) {
            title = item.name_en;
            title_full = item.name_en;
          }
          else {
            title = item.name;
            title_full = item.name + (item.name_en ? ' / ' + item.name_en : '');
          }
          if (i18n.locale === 'en') {
            remark = item.remark_en ? item.remark_en.trim() : '';
            note = item.note_en ? item.note_en.trim() : '';
          }
          else {
            remark = item.remark ? item.remark.trim() : '';
            note = item.note ? item.note.trim() : '';
          }

          navigation.navigate('YpageArticle',
            {
              title: title_full,
              formatted_address: item.formatted_address,
              phone1: item.phone1,
              phone2: item.phone2,
              email: item.email1,
              web: item.web,
              lat: item.geo_lat,
              lng: item.geo_lng,
              itemId: item.id,
              provinceIdx: provinceIdx,
              localityIdx: localityIdx,
              tags: item.tags,
              note: note,
            });

        }
      } catch (err) {
        sendLog(i18n.t('banner_err') + '[e03]' + JSON.stringify(err));
        Alert.alert(i18n.t('banner_err') + ' ' + i18n.t('internet_err'));
        console.log("_getData: ", err);
      }

    }
  }

  const [imageIdx, setImageIdx] = useState(0)
  const flatListRef = useRef();

  const onSwiped = (direction) => {
    const change = direction === 'BACK' ? '-1' : '1';
    const adjustedIdx = imageIdx + Number(change);
    let newIdx;
    if (adjustedIdx >= data.length) {
      newIdx = imageIdx;
    } else if (adjustedIdx < 0) {
      newIdx = 0;
    } else {
      newIdx = adjustedIdx;
    }
    setImageIdx(newIdx);
    flatListRef.current.scrollToIndex({ index: newIdx });
  }

  const _onViewableItemsChanged = useCallback(({ viewableItems, changed }) => {
    if (viewableItems.length === 1) {
      //console.log("Visible item is", viewableItems[0].index);
      setImageIdx(viewableItems[0].index);
    }
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, justifyContent: 'space-between', backgroundColor: '#f8f8f8' }}>
      <StatusBar barStyle="default" hidden={false} />
      <FlatList style={{ flexGrow: 0 }}
        data={searchViewData}
        stickyHeaderIndices={[2]}
        keyExtractor={item => item.id}
        renderItem={({ item }) => { return (item.element) }}
      />
      {banners.length > 0 ?
        <View style={styles.bannerContainer}>
          <FlatList
            ref={bannerFlRef}
            data={banners}
            renderItem={_renderBanner}
            vertical={true}
            keyExtractor={item => item.id}
            showsVerticalScrollIndicator={false}
            onScrollToIndexFailed={() => bannerFlRef.current?.scrollToIndex({ index: 0, animated: true })}
          />
        </View>
        :
        false
      }

      {/* geoLoc ? */
        <View style={{ backgroundColor: 'white', borderTopWidth: 1, borderTopColor: '#f0f0f0' }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20, paddingTop: 10 }}>
            <Text style={[styles.basic, { fontSize: 16 * fontScale, textAlign: 'center', color: '#404040' }]}>{i18n.t('korean_food_list')}</Text>
            <TouchableOpacity onPress={() => navigation.navigate('FoodList')} style={{ flexDirection: 'row', alignItems: 'center' }} >
              <Text style={[styles.basic, { fontSize: 16 * fontScale, textAlign: 'center', color: '#404040', marginRight: 5 }]}>
                {i18n.t('view_all')}
              </Text>
              <Ionicons name="grid" size={14} />
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            {Platform.OS === 'web' &&
              <TouchableOpacity onPress={() => onSwiped('BACK')} style={{ width: 60 }}>
                {imageIdx > 0 &&
                  <Ionicons name="chevron-back" size={60} color="#a0a0a0" />
                }
              </TouchableOpacity>
            }
            <FlatList
              ref={flatListRef}
              style={{ marginTop: 25, overflow: Platform.OS === 'web' ? 'hidden' : 'visible' }}
              data={data}
              renderItem={_renderItem}
              keyExtractor={(item, index) => item.name + index}
              horizontal={true}
              snapToAlignment='center'
              decelerationRate={'fast'}
              snapToInterval={itemWidth}
              onViewableItemsChanged={_onViewableItemsChanged}
            />
            {Platform.OS === 'web' && imageIdx < data.length - 1 &&
              <TouchableOpacity onPress={() => onSwiped('FORWARD')} style={{ width: 60 }}>
                {imageIdx < data.length - 1 &&
                  <Ionicons name="chevron-forward" size={60} color="#a0a0a0" />
                }
              </TouchableOpacity>
            }
          </View>
        </View>
        /*   :
          <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 18 * fontScale, fontStyle: 'italic', color: '#a0a0a0' }}><ActivityIndicator color='rgb(193, 38, 45)' /> {i18n.t('find_geolocation')}</Text>
          </View> */
      }
    </SafeAreaView>
  );
}

export function SearchYpageListScreen({ navigation, route }) {
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState(route.params ? route.params.keyword : '');
  const [provinceIdx, setProvinceIdx] = useState(route.params ? route.params.provinceIdx : 0);
  const [localityIdx, setLocalityIdx] = useState(route.params ? route.params.localityIdx : 0);
  const [checkedSfCategory, setCheckedSfCategory] = useState(true);
  const [checkedSfTag, setCheckedSfTag] = useState(true);
  const [checkedSfName, setCheckedSfName] = useState(true);
  const [checkedSfAddress, setCheckedSfAddress] = useState(false);
  const [places, setPlaces] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchOffset, setSearchOffset] = useState(0);
  const [modalMsg, setModalMsg] = useState('');

  var { myLocale, myProvinceIdx, myLocalityIdx, myGpsLatitude, myGpsLongitude, updateMyContext } = useContext(MyContext);
  console.log("[ SearchYPageListScreen ] is called. locale: ", i18n.locale, ", myProvinceIdx: ", myProvinceIdx, ", myLocalityIdx: ", myLocalityIdx, loading, ", ", modalMsg);

  if (myLocale && myLocale != i18n.locale) {
    console.log("[ SearchYpageListScreen] setting_locale_err");
    i18n.locale = myLocale;
  }

  const search_limit = 50;

  var area_cond = '';
  var area_radius = 25;
  var center_latitude = 51.0447;
  var center_longitude = -114.0719;
  var lat_delta = 0.45; // radius 50km
  var lng_delta = 0.45; // radium 50km in calgary
  var provinceList = [];
  var localityList = [];
  var localityLabelList = '';
  var myFetchPolicy = 'cache-first';

  for (let i = 0; i < areaList.length; i++) {
    provinceList = [...provinceList, { label: i18n.t(areaList[i].label), value: areaList[i].value }];
  }

  for (let i = 0; i < areaList[provinceIdx].cities.length; i++) {
    localityList = [...localityList, { label: i18n.t(areaList[provinceIdx].cities[i].label), value: areaList[provinceIdx].cities[i].value }];
    localityLabelList = localityLabelList + `"${areaList[provinceIdx].cities[i].label}", `;
  }

  // We do not use myProvinceIdx and myLocalityIdx context for setting search.
  // Instead we use a sort of local copies of the context variable in order to
  // the change of searching criteria would not affect the user's setting values.
  if (provinceIdx === 0) {
    switch (localityIdx) {
      case 0: area_radius = 5; break;
      case 1: area_radius = 15; break;
      case 2: area_radius = 25; break;
      case 3: area_radius = 50; break;
      default: area_radius = 15; break;
    }
    lat_delta = distance_in_latitude(area_radius);
    lng_delta = distance_in_longitude(myGpsLatitude, area_radius);
    center_latitude = myGpsLatitude;
    center_longitude = myGpsLongitude;

    area_cond = `_or: [ { lstatus: "online" }, { geo_lat_lte: ${myGpsLatitude + lat_delta} , geo_lat_gte: ${myGpsLatitude - lat_delta}, geo_lng_lte: ${myGpsLongitude + lng_delta}, geo_lng_gte: ${myGpsLongitude - lng_delta} }] `;

    myFetchPolicy = 'network-only'; // ignore cache when searching with current location
  }
  else {
    center_latitude = areaList[provinceIdx].cities[localityIdx].lat;
    center_longitude = areaList[provinceIdx].cities[localityIdx].lng;

    area_cond = `_or: [ { lstatus: "online" }, { province: "${areaList[provinceIdx].label}", geo_lat_gt: "0", `;

    switch (areaList[provinceIdx].cities[localityIdx].value) {
      case '100': area_cond = area_cond + `locality_nin: [ ${localityLabelList} ] }] `;
        break;
      case '1000': area_cond = area_cond + `}] `; // no need to include locality.
        break;
      default: area_cond = area_cond + `locality: "${areaList[provinceIdx].cities[localityIdx].label}" }] `;
        break;
    }
  }

  //console.log("SearchYpageListScreen... keyword: ", keyword );

  // Location permission access should be performed only once.
  useEffect(() => {
    navigation.setOptions({
      headerShown: false
    })

    const subscription = AppState.addEventListener('change', _handleAppStateChange);

    return () => {
      subscription.remove();
    }
  }, []);

  useEffect(() => {
    // If this is the first running, then go to the setting page.
    console.log("[ searchYPageListScreen ] useEffect: myLocale ", myLocale, ", keyword ", keyword);

    if (myLocale == '') navigation.navigate('Settings');

    _pressSearch();

  }, [myLocale, provinceIdx, localityIdx, checkedSfName, checkedSfAddress, checkedSfCategory, checkedSfTag]);

  const _getData = async ({ refreshing }) => {
    if (Platform.OS === 'android' || Platform.OS === 'ios')
      await analytics().logEvent('SearchMain', {
        keyword: keyword,
        provinceIdx: provinceIdx,
        localityIdx: localityIdx,
      });

    let where_cond = '';

    if (keyword) {
      if (checkedSfName && checkedSfAddress)
        where_cond = `_or: [ {name_contains: "${keyword}"}, {name_en_contains: "${keyword}"}, {formatted_address_contains: "${keyword}"} ] `;
      else if (checkedSfName && !checkedSfAddress)
        where_cond = `_or: [ {name_contains: "${keyword}"}, {name_en_contains: "${keyword}"} ] `;
      else if (!checkedSfName && checkedSfAddress)
        where_cond = `formatted_address_contains: "${keyword}" `;
      else {
        where_cond = `name_contains: "!@#$" `;
      }
    }

    where_cond = `_and: [ {` + where_cond + `}, {` + area_cond + `} ]`;
    console.log("[ SearchYpageListScreen ] _getData() is called. refreshing: ", refreshing, ", where_cond: ", where_cond);
    setLoading(true);

    var start_offset;

    if (refreshing) start_offset = 0;
    else start_offset = searchOffset;

    const GET_DATA = gql`
      query getData {
        masters (sort: "name:asc", limit: ${search_limit}, start: ${start_offset}, where: { ${where_cond} } ) {
          id,
          name,
          name_en,
          remark,
          remark_en,
          note,
          note_en,
          formatted_address,
          phone1,
          phone2,
          email1,
          web,
          geo_lat,
          geo_lng,
          categories { name },
          tags { name }
        }
      }
    `;

    try {
      const res = await newclient.query({
        query: GET_DATA,
        fetchPolicy: myFetchPolicy
      });

      //console.log(res);
      if (res) {
        //console.log("masters: ", JSON.stringify(res.data.masters));
        //if (res.data.masters.length === 0) setModalMsg(i18n.t('adjust_search_conditions')); // Alert.alert(i18n.t('adjust_search_conditions'));

        setSearchOffset(start_offset + res.data.masters.length);
        if (refreshing) setPlaces(res.data.masters);
        else setPlaces([...places, ...res.data.masters]);
      }
      setLoading(false);
    } catch (err) {
      sendLog(i18n.t('search_err') + '[e01]' + JSON.stringify(err));
      Alert.alert(i18n.t('search_err') + ' ' + i18n.t('internet_err'));
      console.log("_getData: ", err);
    }

    let where_cond2 = '';
    let where_cond3 = '';

    if (keyword) {
      if (checkedSfCategory)
        where_cond2 = `_or: [ {name_contains: "${keyword}"}, {name_en_contains: "${keyword}"}]`;
      else {
        where_cond2 = `name_contains: "!@#$"`;
      }

      if (checkedSfTag)
        where_cond3 = `_or: [ {name_contains: "${keyword}"}, {name_en_contains: "${keyword}"}]`;
      else {
        where_cond3 = `name_contains: "!@#$"`;
      }
    }

    const GET_4_CATE = gql`
        query get4cate {
          categoriesInArea (where: { ${where_cond2} }, area: { ${area_cond} } ) {
            id,
            name,
          }
        }
      `;

    try {
      const res = await newclient.query({ query: GET_4_CATE });

      //console.log(res);
      if (res) {
        console.log("categoriesInArea: ", res.data.categoriesInArea);

        if (res.data.categoriesInArea.length === 0 && checkedSfCategory) {
          setCategories([{ name: 'nothing_searched' }]);
        } else {
          setCategories(res.data.categoriesInArea);
        }
      }
      setLoading(false);
    } catch (err) {
      sendLog(i18n.t('search_err') + '[e02]' + JSON.stringify(err));
      Alert.alert(i18n.t('search_err') + ' ' + i18n.t('internet_err'));
      console.log("_getData: ", err);
    }

    const GET_4_TAG = gql`
        query get4tag {
          tagsInArea (where: { ${where_cond3} }, area: { ${area_cond} } ) {
            id,
            name,
          }
        }
      `;

    try {
      const res = await newclient.query({ query: GET_4_TAG });

      //console.log(res);
      if (res) {
        console.log("tagsInArea: ", res.data.tagsInArea);

        if (res.data.tagsInArea.length === 0 && checkedSfTag) {
          setTags([{ name: 'nothing_searched' }]);
        } else {
          setTags(res.data.tagsInArea);
        }
      }
      setLoading(false);
    } catch (err) {
      sendLog(i18n.t('search_err') + '[e03]' + JSON.stringify(err));
      Alert.alert(i18n.t('search_err') + ' ' + i18n.t('internet_err'));
      console.log("_getData: ", err);
    }

  };

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'active') {
      console.log('[ SearchYPageListScreen] AppState has come to active.');
    }
  }

  const _pressSearch = () => {
    console.log("[ SearchYPageListScreen] _pressSearch is called.", keyword);

    Keyboard.dismiss();

    if (keyword.trim()) {
      if (!checkedSfName && !checkedSfAddress && !checkedSfCategory && !checkedSfTag) {
        console.log("_pressSearch: selected_nothing.");
        setModalMsg(i18n.t('selected_nothing')); // Alert.alert(i18n.t('selected_nothing'));
        return;
      }

      if (provinceIdx === 0 && (myGpsLongitude < LeftmostLng || myGpsLongitude > RightmostLng)) {
        console.log("_pressSearch: out of canada.");
        //        setModalMsg(i18n.t('out_of_canada_coordinate')); // This does not work because when the provinceIdx is changed through picker, double rendering occurs.
        setCategories([{ name: 'out_of_canada_coordinate' }]);
        setTags([{ name: 'out_of_canada_coordinate' }])
        return;
      }
      setPlaces([]);
      setCategories([]);
      setTags([]);
      _getData({ refreshing: true });
    } else {
      setPlaces([]);
      setCategories([]);
      setTags([]);
    }
  }

  const _renderFooter = () => {
    if (places.length && (places.length % search_limit === 0)) {
      return (
        //Footer View with Load More button
        <View style={styles.footer}>
          <Text style={{ marginRight: 10 }}> {i18n.t("upto_now1")} {places.length} {i18n.t("upto_now2")} </Text>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => _getData({ refreshing: false })}
            //On Click of button load more data
            style={styles.loadMoreBtn}>
            <Text style={styles.btnText}>{i18n.t("load_more")}</Text>
            {loading ? (
              <ActivityIndicator
                color="white"
                style={{ marginLeft: 8 }} />
            ) : null}
          </TouchableOpacity>
        </View>
      );
    }
    return (
      <View style={{ height: 40, backgroundColor: '#f0f0f0' }}></View>
    )
  };

  const searchViewData = [
    {
      id: '0',
      element:
        <View style={{ backgroundColor: '#f0f0f0' }}>
          <View style={{ backgroundColor: '#f0f0f0' }}>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 15 * fontScale, marginHorizontal: 10 * fontScale }}>
            <Text style={[styles.basic, { marginRight: 7 * fontScale }]}>{i18n.t('area_for_search')}</Text>
            <RNPickerSelect
              style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
              value={provinceList[provinceIdx].value}
              useNativeAndroidPickerStyle={false}
              fixAndroidTouchableBug={true}
              placeholder={{}}
              onValueChange={(value, index) => {
                //console.log("...RNPickerSelect province... index: ", index);
                setProvinceIdx(index);
                setLocalityIdx(0);
              }}
              Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
              items={provinceList}
            />
            <View style={{ marginHorizontal: 3 * fontScale }}></View>
            <RNPickerSelect
              style={{ ...pickerSelectStyles, iconContainer: { top: 5 } }}
              value={localityList[localityIdx].value}
              useNativeAndroidPickerStyle={false}
              fixAndroidTouchableBug={true}
              placeholder={{}}
              onValueChange={(value, index) => {
                //console.log("...RNPickerSelect locality... index: ", index);
                setLocalityIdx(index);
              }}
              Icon={() => { if (Platform.OS !== 'web') return <Ionicons name="caret-down" size={20} color="gray" /> }}
              items={localityList}
            />
          </View>
        </View>
    },
  ];

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <StatusBar barStyle="default" hidden={false} />
      <Modal
        visible={modalMsg ? true : false}
        transparent={true}>
        <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
          <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
            <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
              <Text style={styles.modalText}>{modalMsg}</Text>
              <MyButton onPress={() => setModalMsg('')}>
                <Text style={[styles.basic, { color: 'white' }]}>{i18n.t('confirm_m')}</Text>
              </MyButton>
            </View>
          </View>
        </View>
      </Modal>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: Platform.OS === 'ios' ? 0 : 7 }}>
        <TouchableOpacity
          style={{ marginTop: 2, marginRight: 20, marginLeft: Platform.OS === 'ios' ? 0 : 11 }}
          onPress={() => navigation.goBack()}
        >
          <Ionicons name="chevron-back-sharp" size={36} color="#272727" />
        </TouchableOpacity>
        <TextInput
          style={{ width: 250 * fontScale, height: 36 * fontScale, borderBottomWidth: 1, paddingHorizontal: 10, borderRadius: 4, borderColor: '#808080', fontSize: 18 * fontScale }}
          value={keyword}
          onChangeText={(text) => { setKeyword(text.trim()); }}
          onSubmitEditing={_pressSearch}
          placeholder={i18n.t("enter_search")}
          autoCapitalize="none"
          autoCorrect={false}
        />
        <TouchableOpacity onPress={_pressSearch}><Ionicons name="search" size={22 * fontScale} color="#272727" style={{ top: 10, marginRight: 20 }} /></TouchableOpacity>
      </View>
      <FlatList
        data={searchViewData}
        stickyHeaderIndices={[0]}
        ListFooterComponent={
          <View>
            <View style={{ backgroundColor: 'white' }} >
              <View style={[styles.searchResultsHeaderItem, { flexDirection: "row", alignItems: 'center' }]}>
                <Checkbox
                  value={checkedSfCategory}
                  onValueChange={() => { setCheckedSfCategory(!checkedSfCategory); setLoading(true) }} // set loading to true here in order to avoid glitch message in search results.
                  style={{ marginLeft: 10, width: 18, borderWidth: 1 }} />
                <Text style={styles.searchResultsHeaderTitle}>{i18n.t('among_categories')}</Text>
              </View>
              <FlatList
                data={categories}
                keyExtractor={(item, index) => index.toString()}
                horizontal={true}
                ListEmptyComponent={
                  <View style={{ padding: 25, fontFamily: 'CustomHangul' }}>
                    {loading === true && checkedSfCategory ? <View style={{ flexDirection: 'row' }}><Text style={{ color: 'grey', marginRight: 5 }}>{i18n.t("comm_server")}</Text><ActivityIndicator color='grey' /></View> : false}
                  </View>
                }
                renderItem={({ item }) => {
                  if ((item.name).trim() === "nothing_searched") {
                    return (
                      <Text style={[styles.basic, { padding: 20 }]}>{i18n.t('nothing_searched')}</Text>
                    );
                  }
                  else if ((item.name).trim() === "out_of_canada_coordinate") {
                    return (
                      <Text style={[styles.basic, { padding: 20 }]}>{i18n.t('out_of_canada_coordinate')}</Text>
                    );
                  }
                  else if ((item.name).trim() != "") {
                    return (
                      <View style={{ padding: 10 }}>
                        <MyButton
                          onPress={() => navigation.navigate('YpageSublist',
                            {
                              title: item.name,
                              id: 0,
                              provinceIdx: provinceIdx,
                              localityIdx: localityIdx,
                            })}
                          children={() => <Text style={[styles.basic, { color: 'white' }]}>{i18n.t(item.name)}</Text>}
                        />
                      </View>
                    )
                  }

                }
                }
              />
            </View>

            <View style={{ backgroundColor: 'white' }} >
              <View style={[styles.searchResultsHeaderItem, { flexDirection: "row", alignItems: 'center' }]}>
                <Checkbox
                  value={checkedSfTag}
                  onValueChange={() => { setCheckedSfTag(!checkedSfTag); setLoading(true) }} // set loading to true here in order to avoid glitch message in search results.
                  style={{ marginLeft: 10, width: 18, borderWidth: 1 }} />
                <Text style={styles.searchResultsHeaderTitle}>{i18n.t('among_tags')}</Text>
              </View>
              <FlatList
                data={tags}
                keyExtractor={(item, index) => index.toString()}
                horizontal={true}
                ListEmptyComponent={
                  <View style={{ padding: 25, fontFamily: 'CustomHangul' }}>
                    {loading === true && checkedSfTag ? <View style={{ flexDirection: 'row' }}><Text style={{ color: 'grey', marginRight: 5 }}>{i18n.t("comm_server")}</Text><ActivityIndicator color='grey' /></View> : false}
                  </View>
                }
                renderItem={({ item }) => {
                  if ((item.name).trim() === "nothing_searched") {
                    if (tag_alts[keyword]) {
                      return (
                        <View style={{ padding: 20 }}>
                          <Text style={[styles.basic]}>{i18n.t("not_tags")}</Text>
                          <Text style={[styles.basic]}>{i18n.t("use_tag_alts_1")} '{tag_alts[keyword]}' {i18n.t("use_tag_alts_2")}</Text>
                        </View>
                      );
                    } else {
                      return (
                        <Text style={[styles.basic, { padding: 20 }]}>{i18n.t('nothing_searched')}</Text>
                      );
                    }
                  }
                  else if ((item.name).trim() === "out_of_canada_coordinate") {
                    return (
                      <Text style={[styles.basic, { padding: 20 }]}>{i18n.t('out_of_canada_coordinate')}</Text>
                    );
                  }
                  else if ((item.name).trim() != "") {
                    return (
                      <View style={{ padding: 10 }}>
                        <MyButton
                          onPress={() => navigation.navigate('YpageSublist',
                            {
                              title: item.name,
                              id: -1,
                              provinceIdx: provinceIdx,
                              localityIdx: localityIdx,
                            })}
                          children={() => <Text style={[styles.basic, { color: 'white' }]}>{i18n.t(item.name)}</Text>}
                        />
                      </View>
                    )
                  }

                }
                }
              />
            </View>

            <View style={{ flex: 1, backgroundColor: 'white' }} >
              <View style={[styles.searchResultsHeaderItem, { flexDirection: "row", justifyContent: "space-between", alignItems: "center" }]}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox
                    value={checkedSfName}
                    onValueChange={() => { setCheckedSfName(!checkedSfName); setLoading(true) }} // set loading to true here in order to avoid glitch message in search results.
                    style={{ marginLeft: 10, width: 18, borderWidth: 1 }} />
                  <Text style={styles.searchResultsHeaderTitle}>{i18n.t('among_name')}</Text>
                  <Checkbox
                    value={checkedSfAddress}
                    onValueChange={() => { setCheckedSfAddress(!checkedSfAddress); setLoading(true) }} // set loading to true here in order to avoid glitch message in search results.
                    style={{ marginLeft: 10, width: 18, borderWidth: 1 }} />
                  <Text style={styles.searchResultsHeaderTitle}>{i18n.t('among_address')}</Text>
                </View>
                <MyButton
                  appearance="outline"
                  disabled={places.length ? false : true}
                  style={{ marginRight: 20, paddingVertical: 3, backgroundColor: 'rgba(20,20,20,0.5)', minHeight: 30, borderWidth: 0, borderRadius: 5, justifyContent: 'center' }}
                  onPress={() => navigation.navigate('SearchYpageMap', {
                    lat: center_latitude,
                    lng: center_longitude,
                    lat_delta: lat_delta,
                    lng_delta: lng_delta,
                    markers: places,
                    provinceIdx: provinceIdx,
                    localityIdx: localityIdx
                  })}
                  children={() => <Text style={{ color: 'white', fontSize: 13 * fontScale }}>{i18n.t('mapview')}</Text>}
                />
              </View>
              <FlatList
                data={places}
                keyExtractor={(item, index) => index.toString()}
                //ListHeaderComponent={this.renderHeader}
                initialNumToRender={20}
                ListFooterComponent={_renderFooter}
                ListEmptyComponent={
                  <Text style={[styles.basic, { padding: 20 }]}>
                    {loading === false && (checkedSfName || checkedSfAddress) ? <Text>{i18n.t('nothing_searched')}</Text> : <></>}
                  </Text>
                }
                renderItem={({ item }) => {
                  if ((item.name).trim() != "") {
                    let title;
                    let title_full;
                    let remark;
                    let note;
                    if (i18n.locale === 'en' && item.name_en) {
                      title = item.name_en;
                      title_full = item.name_en;
                    }
                    else {
                      title = item.name;
                      title_full = item.name + (item.name_en ? ' / ' + item.name_en : '');
                    }
                    if (i18n.locale === 'en') {
                      remark = item.remark_en ? item.remark_en.trim() : '';
                      note = item.note_en ? item.note_en.trim() : '';
                    }
                    else {
                      remark = item.remark ? item.remark.trim() : '';
                      note = item.note ? item.note.trim() : '';
                    }

                    return (
                      <TouchableOpacity
                        style={styles.ypageSublistItemContentContainer}
                        onPress={() => navigation.navigate('YpageArticle',
                          {
                            title: title_full,
                            formatted_address: item.formatted_address,
                            phone1: item.phone1,
                            phone2: item.phone2,
                            email: item.email1,
                            web: item.web,
                            lat: item.geo_lat,
                            lng: item.geo_lng,
                            itemId: item.id,
                            provinceIdx: provinceIdx,
                            localityIdx: localityIdx,
                            tags: item.tags,
                            note: note,
                          })}
                      >
                        <View
                          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                        >
                          <Text style={styles.ypageSublistItemContent1}>{title}</Text>
                          <TouchableOpacity style={{ width: '36%' }} onLongPress={() => Communications.phonecall(item.phone1, true)}>
                            <Text style={styles.ypageSublistItemContent2}>{item.phone1 ? item.phone1.replace(/[1]*(\d{3})(\d{3})(\d{4})/i, '$1-$2-$3') : ''}</Text>
                          </TouchableOpacity>
                        </View>
                        {remark ? <View><Text numberOfLines={1} style={{ marginHorizontal: 10, marginTop: 3, color: '#a0a070' }}>{remark}</Text></View> : false}
                      </TouchableOpacity>
                    )
                  }
                }
                }
              />
            </View>
          </View>
        }
        keyExtractor={item => item.id}
        renderItem={({ item }) => { return (item.element) }}
      />
    </SafeAreaView>
  );
}

export function SearchYpageMapScreen({ navigation, route }) {
  const params = route.params;
  const lat = params.lat;
  const lng = params.lng;
  const lat_delta = params.lat_delta;
  const lng_delta = params.lng_delta;
  const markers = params.markers;
  const provinceIdx = params.provinceIdx;
  const localityIdx = params.localityIdx;

  // console.log("[ SearchYpageMapScreen ] params: ", params);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={i18n.t('mapview')} />,
      headerTitleAlign: 'center',
    })
  });

  return (
    <View style={{ flex: 1 }}>
      <MapView
        provider={PROVIDER_GOOGLE}
        style={{
          flex: 1,
        }}
        initialRegion={{
          latitude: lat,
          longitude: lng,
          latitudeDelta: lat_delta,
          longitudeDelta: lng_delta
        }}
      >
        {markers &&
          markers.map((marker, index) => (
            <Marker
              key={index}
              coordinate={{ latitude: marker.geo_lat, longitude: marker.geo_lng }}
            >
              <Callout
                onPress={() => {
                  let title;
                  let title_full;
                  let remark;
                  let note;
                  if (i18n.locale === 'en' && marker.name_en) {
                    title = marker.name_en;
                    title_full = marker.name_en;
                  }
                  else {
                    title = marker.name;
                    title_full = marker.name + (marker.name_en ? ' / ' + marker.name_en : '');
                  }
                  if (i18n.locale === 'en') {
                    remark = marker.remark_en ? marker.remark_en.trim() : '';
                    note = marker.note_en ? marker.note_en.trim() : '';
                  }
                  else {
                    remark = marker.remark ? marker.remark.trim() : '';
                    note = marker.note ? marker.note.trim() : '';
                  }

                  navigation.navigate('YpageArticle',
                    {
                      title: title_full,
                      formatted_address: marker.formatted_address,
                      phone1: marker.phone1,
                      phone2: marker.phone2,
                      email: marker.email1,
                      web: marker.web,
                      lat: marker.geo_lat,
                      lng: marker.geo_lng,
                      itemId: marker.id,
                      provinceIdx: provinceIdx,
                      localityIdx: localityIdx,
                      tags: marker.tags,
                      note: note,
                    }
                  )
                }}
              >
                <View style={{ maxWidth: 240 }}>
                  <Text style={{ fontSize: 15, lineHeight: 20 }}>{marker.name}</Text>
                  <Text style={{ fontSize: 12, color: 'gray' }}>{marker.formatted_address}</Text>
                </View>
              </Callout>
            </Marker>
          ))
        }
        {params.provinceIdx === 0 ?
          <Marker
            coordinate={{ latitude: lat, longitude: lng }}
            title={i18n.t('geo_yourlocation')}
            pinColor={'#2f95dc'}
          />
          : null
        }
      </MapView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(226, 226, 226)',
    fontFamily: 'CustomHangul',
    //paddingTop: 10,
  },
  basic: {
    fontFamily: 'CustomHangul',
    fontSize: 14 * fontScale,
  },
  textInput: {
    width: 250 * fontScale, height: 34, borderBottomWidth: 1, borderRadius: 4, borderColor: '#808080', fontSize: 15 * fontScale
  },
  ypageSublistItemContentContainer: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomColor: '#e2e2e2',
    borderBottomWidth: 1,
  },
  ypageSublistItemContent1: {
    width: '60%',
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#595b60',
    marginLeft: 10,
  },
  ypageSublistItemContent2: {
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#595b60',
    textAlign: 'right',
    marginRight: 10,
  },
  footer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  loadMoreBtn: {
    padding: 10,
    backgroundColor: '#800000',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
  },
  modalText: {
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#272727',
    marginBottom: 25,
    textAlign: 'center'
  },
  searchResultsHeaderItem: {
    backgroundColor: '#f6c417',
  },
  searchResultsHeaderTitle: {
    alignSelf: 'flex-start',
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    lineHeight: 36 * fontScale,
    color: '#272727',
    marginLeft: 8,
  },
  bannerContainer: {
    height: 50 * fontScale,
    marginBottom: 0,
    marginHorizontal: 5,
    borderRadius: 5,
    borderColor: '#e6eaed',
    borderWidth: 1,
    backgroundColor: '#edf2f6'
  },
  bannerItemContainer: {
    height: 50 * fontScale,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontFamily: 'CustomHangul',
    width: 130 * fontScale,
    fontSize: 14 * fontScale,
    paddingVertical: 2,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 20, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    fontFamily: 'CustomHangul',
    width: 130 * fontScale,
    fontSize: 14 * fontScale,
    paddingHorizontal: 5,
    paddingVertical: 1,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    backgroundColor: 'rgb(242,242,242)',
    paddingRight: 20, // to ensure the text is never behind the icon
  },
  inputWeb: {
    fontFamily: 'CustomHangul',
    width: 130 * fontScale,
    fontSize: 14 * fontScale,
    paddingHorizontal: 5,
    paddingVertical: 1,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    backgroundColor: 'rgb(242,242,242)',
    paddingRight: 20, // to ensure the text is never behind the icon
  }
});