import React, { useState, useEffect, useContext } from 'react';
import {
    Image, Dimensions, Alert, StyleSheet, Text, TouchableOpacity, View, FlatList, PixelRatio,
    RefreshControl, StatusBar, Platform, Linking, SafeAreaView, TextInput, AppState, ActivityIndicator, Keyboard,
    TouchableWithoutFeedback,
    Modal, // use react-native's Modal.
    ScrollView
} from 'react-native';
import { HeaderCenterText, HeaderOptionsImage, HeaderMenuImage, HeaderNotificationImage } from '../components/CustomHeader';
import { gql, useQuery, useMutation, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import i18n from 'i18n-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MyContext from '../components/MyContext';
import ApiKeys from '../constants/ApiKeys';
import { checkJwtValidity } from './MenuScreen';
import { areaList } from '../assets/data/geo';
import RNPickerSelect from 'react-native-picker-select';
import { useIsFocused } from '@react-navigation/core';
import { sendLog } from '../components/StrapiInterface';
import { MyButton } from '../components/MyComponents';

const newclient = new ApolloClient({
    uri: ApiKeys.StrapiConfig.URI,
    cache: new InMemoryCache(),
});

const { width: windowFullWidth, height: windowHeight } = Dimensions.get('window');
const windowWidth = windowFullWidth > 800 ? 800 : windowFullWidth;
const fontScale = windowWidth > 480 ? 1.5 : 1;

const arrayDataMain = [
    { id: 10, title: 'clothes_c', image_url: require('../assets/images/ypage/shirt.png') },
    { id: 20, title: 'food_c', image_url: require('../assets/images/ypage/restaurant.png') },
    { id: 30, title: 'house_c', image_url: require('../assets/images/ypage/home.png') },
    { id: 40, title: 'car_c', image_url: require('../assets/images/ypage/car.png') },
    { id: 50, title: 'institution_c', image_url: require('../assets/images/ypage/institution.png') },
    { id: 60, title: 'playing_c', image_url: require('../assets/images/ypage/kite.png') },
    { id: 70, title: 'merchandise_c', image_url: require('../assets/images/ypage/box.png') },
    { id: 80, title: 'professional_service_c', image_url: require('../assets/images/ypage/briefcase.png') },
    { id: 90, title: 'life_edu_service_c', image_url: require('../assets/images/ypage/customer-service.png') },
    { id: 100, title: 'company_c', image_url: require('../assets/images/ypage/office-building.png') },
];

export function FoodListScreen({ navigation }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [searchModalVisible, setSearchModalVisible] = useState(false);

    var { myLocale, myProvinceIdx, myLocalityIdx } = useContext(MyContext);

    useEffect(() => {
        navigation.setOptions({
            headerTitle: () => <HeaderCenterText title={i18n.t('food_directory')} />,
            headerTitleAlign: 'left',
            headerRight: () => <HeaderOptionsImage onPress={_showSearchModal} />,
        })

        _getTags();

    }, [])

    var filter_param = '"한식", "일식", "중식", "디저트", "치킨", "분식", "길거리음식", "서양식"';
    var where_cond = "";

    if (keyword) {
        where_cond = `_or: [ {name_contains: "${keyword}"}, {name_en_contains: "${keyword}"}, 
                             {description_contains: "${keyword}"}, {description_en_contains: "${keyword}"} ], `;
    }

    const _getTags = () => {
        console.log("[ FoodListScreen ] (_getTags) is called. filter_param: ", filter_param, ", where_cond: ", where_cond);

        var GET_TAGS = gql`
            query getTags {
              tags (where: { categories: { name_in: [${filter_param}] }, ${where_cond} }) {
                id,
                name,
                name_en,
                description,
                description_en,
                image { id, url },
                categories { name }
              }
            }
          `;

        newclient.query({
            query: GET_TAGS
        })
            .then(res => {
                //console.log(res);
                if (res) {
                    //console.log("[ FoodListScreen ] tags: ", JSON.stringify(res.data.tags));
                    if (res.data.tags) setData(res.data.tags);
                }
                setLoading(false);
            })
            .catch(err => {
                sendLog(i18n.t('search_err') + '[e21]' + JSON.stringify(err));
                Alert.alert(i18n.t('search_err') + ' ' + i18n.t('internet_err'));
                console.log("[ FoodListScreen ] _getTags: ", err)
                setLoading(false);
            });
    }

    const _showSearchModal = () => {
        setSearchModalVisible(true);
        console.log('Showing searchModal ...: ', searchModalVisible);
    }

    const _hideSearchModal = (f) => {
        console.log('Hiding Modal ... flag: ', f);
        setSearchModalVisible(false);
        if (f) _getTags();
    }

    const _onPress = (item) => {
        console.log('onPress ...');
        navigation.navigate('YpageSublist',
        {
          title: item.name,
          id: -1,
          provinceIdx: myProvinceIdx,
          localityIdx: myLocalityIdx,
        })
    }

    return (
        <SafeAreaView style={styles.container} >
            <StatusBar barStyle="default" hidden={false} />

            <Modal
                visible={searchModalVisible}
                transparent={true}
            >
                <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
                    <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
                        <View style={{ marginHorizontal: 15 }}>
                            <Text style={{ fontSize: 17, fontWeight: 'bold', color: '#c1262d', textAlign: 'left', marginTop: 20, marginBottom: 10 }}>{i18n.t('options')} </Text>
                            <View style={{ marginVertial: 20 }}>
                                <Text style={{ fontSize: 14 * fontScale }}>{i18n.t('filtering_letters')}</Text>
                                <TextInput style={{ borderColor: '#272727', marginVertical: 10, height: 32 * fontScale, borderWidth: 0.5, fontSize: 14 * fontScale }}
                                    value={keyword}
                                    onChangeText={(text) => { setKeyword(text.trim()) }}
                                    placeholder={i18n.t('only_one_word')}
                                    placeholderTextColor="#d0d0d0"
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                />
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginVertical: 20 }}>
                            <MyButton
                                onPress={() => _hideSearchModal(false)}
                                children={() => <Text style={styles.appButtonText}>{i18n.t('cancel_m')}</Text>}
                            />

                            <MyButton
                                onPress={() => _hideSearchModal(true)}
                                children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
                            />
                        </View>
                    </View>

                </View>
            </Modal>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Text style={{ fontSize: 12 * fontScale }}>{ keyword ? `${i18n.t('filtering')}: '${keyword}' ` : ` `}</Text>
            </View>
            <View style={{ flex: 1, marginLeft: 5, marginRight: 5, marginTop: 10 }}>
                <FlatList
                    data={data}
                    keyExtractor={item => item.name.toString()}
                    horizontal={false}
                    numColumns={2}
                    //ListHeaderComponent={this.renderHeader}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            enabled={false}
                        />
                    }

                    renderItem={({ item }) => {
                        if (((item.name).trim() != "")) {
                            let imgUrl = item.image ? { uri: 'https://cakory-ds.com'+item.image.url} : require('../assets/images/no-image.png');
                            //console.log("**", imgUrl);
                            return (
                                <TouchableOpacity
                                activeOpacity={0.8}
                                style={styles.slideInnerContainer}
                                onPress={() => _onPress(item)}
                                >
                                  <View style={styles.shadow} />
                                  <View style={styles.imageContainer}>
                                      <Image source={ imgUrl } style={styles.image} />
                  
                                      <View style={styles.radiusMask} />
                                  </View>
                                  <View style={styles.textContainer}>
                                      <Text
                                          style={styles.title}
                                          numberOfLines={2}
                                      >
                                          { i18n.t(item.name) }
                                      </Text>
                                      <Text
                                        style={styles.subtitle}
                                        numberOfLines={2}
                                      >
                                          { item.description_en }
                                      </Text>
                                  </View>
                              </TouchableOpacity>
                  
                            )
                        }
                    }}
                />
            </View>

        </SafeAreaView>
    );

}

const IS_IOS = Platform.OS === 'ios';
const slideHeight = windowWidth * 0.65;
const slideWidth = windowWidth * 0.45;
const itemHorizontalMargin = windowWidth * 0.02;
const itemWidth = slideWidth + itemHorizontalMargin * 2;
const entryBorderRadius = 8;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    basic: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
    },
    button: Platform.select({
        ios: {},
        android: {
            color: '#2196F3',
        },
    }),
    modalView: {
        backgroundColor: 'rgba(255,255,255, 1)',
        position: 'absolute',
        width: windowWidth,
        top: 60,
        height: windowHeight - 120,
    },
    modalContainer: {
        width: windowWidth,
        height: windowHeight,
        backgroundColor: 'rgba(20, 20, 20, 0.8)',
    },
    modalText: {
        fontFamily: 'CustomHangul',
        fontSize: 16 * fontScale,
        color: '#272727',
        marginBottom: 25,
        textAlign: 'center'
    },
    appButtonText: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
        color: "#fff",
        alignSelf: "center",
        textTransform: "uppercase"
    },
    slideInnerContainer: {
        width: itemWidth,
        height: slideHeight / fontScale,
        paddingHorizontal: itemHorizontalMargin,
        paddingBottom: 18 * fontScale // needed for shadow
    },
    shadow: {
        position: 'absolute',
        top: 0,
        left: itemHorizontalMargin,
        right: itemHorizontalMargin,
        bottom: 18,
        shadowColor: '#1a1917',
        shadowOpacity: 0.25,
        shadowOffset: { width: 0, height: 10 },
        shadowRadius: 10,
        borderRadius: entryBorderRadius
    },
    imageContainer: {
        flex: 1,
        marginBottom: IS_IOS ? 0 : -1, // Prevent a random Android rendering issue
        backgroundColor: 'white',
        borderTopLeftRadius: entryBorderRadius,
        borderTopRightRadius: entryBorderRadius
    },
    image: {
        //...StyleSheet.absoluteFillObject,
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
        //borderRadius: IS_IOS ? entryBorderRadius : 0,
        borderTopLeftRadius: entryBorderRadius,
        borderTopRightRadius: entryBorderRadius
    },
    // image's border radius is buggy on iOS; let's hack it!
    radiusMask: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: entryBorderRadius,
        backgroundColor: 'white'
    },
    textContainer: {
        justifyContent: 'center',
        paddingTop: 20 * fontScale - entryBorderRadius,
        paddingBottom: 20 * fontScale,
        paddingHorizontal: 16,
        backgroundColor: 'white',
        borderBottomLeftRadius: entryBorderRadius,
        borderBottomRightRadius: entryBorderRadius
    },
    title: {
        color: '#1a1917',
        fontSize: 13 * fontScale,
        fontWeight: 'bold',
        letterSpacing: 0.5
    },
    subtitle: {
        marginTop: 6,
        color: '#888888',
        fontSize: 12 * fontScale,
        fontStyle: 'italic'
    },
});