import { ApolloClient, InMemoryCache, gql, useMutation, HttpLink, ApolloLink, concat } from '@apollo/client';
import ApiKeys from '../constants/ApiKeys';
import * as Device from 'expo-device';
import Constants from 'expo-constants';
import i18n from 'i18n-js';
import * as Sentry from 'sentry-expo';
import { Alert, Platform } from 'react-native';

// if there is nothing to match the myPushToken, create a new one.
// Otherwise, update myId.
export const createStrapiExpoToken = async ({ myPushToken, myId = 0 }) => {
    console.log("createStrapiExpoToken is called. myPushToken: ", myPushToken, ", myId: :", myId);

    const client_no_jwt = new ApolloClient({
        uri: ApiKeys.StrapiConfig.URI,
        cache: new InMemoryCache(),
    });

    const GET_EXPOTOKENS = gql`
        query getExpoTokens {
            expotokens (where: { token: "${myPushToken}" } ) {
                id
            },
        }
    `;

    const CREATE_EXPOTOKEN = gql`
        mutation createExpoToken {
            createExpotoken (input: { data: { token: "${myPushToken}", user: ${myId}, platform: ${Platform.OS}  } } ) {
                expotoken {
                    id,
                    token,
                    platform
                }
            },
        }
    `;

    var tokens = [];

    try {
        const res = await client_no_jwt.query({
            query: GET_EXPOTOKENS
        });

        //console.log(res);
        if (res) {
            tokens = res.data.expotokens;
            console.log("(createStrapiExpoToken)[1] expoToken: ", JSON.stringify(tokens));
        }

        switch (tokens.length) {
            case 0:
                try {
                    const res = await client_no_jwt.mutate({
                        mutation: CREATE_EXPOTOKEN
                    });

                    //console.log(res);
                    if (res) {
                        let settings = res.data.createExpotoken.expotoken;
                        console.log("(createStrapiExpoToken)[a] expoToken: ", JSON.stringify(settings));
                    }
                } catch (err) {
                    sendLog(i18n.t('setting_expotoken_err') + '[c01]' + JSON.stringify(err));
                    //Alert.alert(i18n.t('setting_expotoken_err') + '[c01]'); console.log("(createStrapiExpoToken) Error: ", err)
                    return false;
                }

                break;
            case 1:
                const UPDATE_EXPOTOKEN = gql`
                    mutation updateExpoToken {
                        updateExpotoken (input: { where: { id: "${tokens[0].id}" }, data: { user: ${myId}, platform: ${Platform.OS}  } } ) {
                            expotoken {
                                id,
                                token,
                                platform
                            }
                        },
                    }
                `;

                try {
                    const res = await client_no_jwt.mutate({
                        mutation: UPDATE_EXPOTOKEN
                    });

                    //console.log(res);
                    if (res) {
                        let settings = res.data.updateExpotoken.expotoken;
                        console.log("(createStrapiExpoToken)[b] expoToken: ", JSON.stringify(settings));
                    }
                } catch (err) {
                    sendLog(i18n.t('setting_expotoken_err') + '[c02]' + JSON.stringify(err));
                    //Alert.alert(i18n.t('setting_expotoken_err') + '[c02]'); console.log("(createStrapiExpoToken) Error: ", err)
                    return false;
                }

                break;
            default:
                console.log("(createStrapiExpoToken) too many entities: ", tokens.length);
                sendLog(i18n.t('setting_expotoken_err') + '[c07]');
                //Alert.alert(i18n.t('setting_expotoken_err') + '[c07]');
                return false;
        }
        if (tokens.length != 1) {
        }
    } catch (err) {
        sendLog(i18n.t('setting_expotoken_err') + '[c09]' + JSON.stringify(err));
        //Alert.alert(i18n.t('setting_expotoken_err') + '[c09]'); console.log("(createStrapiExpoToken) Error: ", err)
        return false;
    }

    return true;
}

export const removeStrapiPushToken = async ({ myPushToken }) => {
    const client_no_jwt = new ApolloClient({
        uri: ApiKeys.StrapiConfig.URI,
        cache: new InMemoryCache(),
    });

    const GET_EXPOTOKENS = gql`
        query getExpoTokens {
            expotokens (where: { token: "${myPushToken}" } ) {
                id
            },
        }
    `;

    var tokens = [];

    try {
        const res = await client_no_jwt.query({
            query: GET_EXPOTOKENS
        });

        //console.log(res);
        if (res) {
            tokens = res.data.expotokens;
            console.log("(removeStrapiPushToken)[1] expoToken: ", JSON.stringify(tokens));
        }

        if (tokens.length != 1) {
            console.log("deleteExpoToken Error!! strapi entity num: ", tokens.length);
            sendLog(i18n.t('setting_expotoken_err') + '[d01]');
            //Alert.alert(i18n.t('setting_expotoken_err') + '[d01]');
            return false;
        }
    } catch (err) {
        sendLog(i18n.t('setting_expotoken_err') + '[d03]' + JSON.stringify(err));
        //Alert.alert(i18n.t('setting_expotoken_err') + '[d03]'); console.log("removeStrapiPushToken[1]: ", err)
        return false;
    }

    const DELETE_EXPOTOKEN = gql`
        mutation deleteExpoToken {
            deleteExpotoken (input: { where: { id: ${tokens[0].id}  } } ) {
                expotoken {
                    id
                }
            },
        }
    `;

    try {
        const res = await client_no_jwt.mutate({
            mutation: DELETE_EXPOTOKEN
        });

        //console.log(res);
        if (res) {
            let settings = res.data.deleteExpotoken.expotoken;
            console.log("(removeStrapiPushToken)[2] expoToken: ", JSON.stringify(settings));
        }
    } catch (err) {
        sendLog(i18n.t('setting_expotoken_err') + '[d05]' + JSON.stringify(err));
        //Alert.alert(i18n.t('setting_expotoken_err') + '[d05]'); console.log("removeStrapiPushToken[2]: ", err);
        return false;
    }

    return true;
}

export async function sendLog(description) {
    if (Platform.OS === 'android' || Platform.OS === 'ios')
        Sentry.Native.captureMessage(description);
/*    
    const client_no_jwt = new ApolloClient({
        uri: ApiKeys.StrapiConfig.URI,
        cache: new InMemoryCache(),
    });

    let system_name = Device.modelName;
    let system_version = Device.osName + " " + Device.osVersion ;
    let app_buildnumber = Constants.manifest.version;
    let system_buildid = Device.osBuildId;
    let system_manufacturer = Device.manufacturer;
    let mac_address = '';
    let description_e = description.replace(/\"/g, '\\\"');

    const CREATE_ERRLOG = gql`
        mutation createErrLog {
        createErrlog (input: { data: { 
                system_name : "${system_name}", 
                system_version: "${system_version}",
                system_buildid: "${system_buildid}",
                system_manufacturer: "${system_manufacturer}",
                app_buildnumber: "${app_buildnumber}",
                mac_address: "${mac_address}",
                description: "${description_e}" } } ) {
            errlog {
            id,
            description,
            }
        },
        }
    `;

    try {
        let result = await client_no_jwt.mutate({
            mutation: CREATE_ERRLOG
        });
        console.log("[ sendLog ] (sendLog) Success: createUserSetting ", result);
    } catch (e) {
        console.log("[ sendLog ] (sendLog) Error: createUserSetting ", e);
    }
*/
}