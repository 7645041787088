import React, { useState, useEffect, useContext } from 'react';
import {
    Image, Dimensions, Alert, StyleSheet, Text, TouchableOpacity, View, FlatList, PixelRatio,
    RefreshControl, StatusBar, Platform, Linking, SafeAreaView, TextInput, AppState, ActivityIndicator, Keyboard,
    TouchableWithoutFeedback, KeyboardAvoidingView,
    Modal, // use react-native's Modal because ui-kitten's Modal makes problems in entering hangul.
    ScrollView
} from 'react-native';
import { HeaderCenterText, HeaderOptionsImage, HeaderMenuImage, HeaderNotificationImage } from '../components/CustomHeader';
import { gql, useQuery, useMutation, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import i18n from 'i18n-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ApiKeys from '../constants/ApiKeys';
import { FontAwesome } from '@expo/vector-icons';
import MyContext from '../components/MyContext';

const newclient = new ApolloClient({
    uri: ApiKeys.StrapiConfig.URI,
    cache: new InMemoryCache(),
});

const { width: windowWidth, height: windowHeight } = Dimensions.get('window');
const fontScale = windowWidth > 480 ? 1.5 : 1;

export function MetricConversionScreen({ navigation }) {
    const [valuePound, setValuePound] = useState('');
    const [valueKilogram, setValueKilogram] = useState('');
    const [valueOunce, setValueOunce] = useState('');
    const [valueMililiter, setValueMililiter] = useState('');
    const [valueInch, setValueInch] = useState('');
    const [valueCentimeter1, setValueCentimeter1] = useState('');
    const [valueCentimeter2, setValueCentimeter2] = useState('');
    const [valueFeet, setValueFeet] = useState('');
    const [valueTempF, setValueTempF] = useState('');
    const [valueTempC, setValueTempC] = useState('');

    var { myLocale } = useContext(MyContext);

    console.log("[ MetricConversionScreen ] is called.", myLocale);

    useEffect(() => {
        navigation.setOptions({
            headerTitle: () => <HeaderCenterText title={i18n.t('unit_conversion')} />,
            headerTitleAlign: 'left',
            headerRight: () => <View style={{ flexDirection: 'row' }}>
                <HeaderNotificationImage onPress={() => navigation.navigate('NotificationList')} />
                <HeaderMenuImage onPress={() => navigation.navigate('MenuList')} />
            </View>,
        });
    }, [])

    return (
        <KeyboardAvoidingView 
            behavior={Platform.OS === "ios" ? "padding" : "height"} 
            style={styles.container} 
            keyboardVerticalOffset={Platform.OS === "ios" ? 90 : 0}
        >
            <StatusBar barStyle="default" hidden={false} />

            <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : null}>
                <ScrollView>
                <View style={{ flex: 1, marginLeft: 5, marginRight: 5, marginTop: 10 }}>
                    <View style={styles.unitItemContainer}>
                        <View style={styles.unitTitleContainer}><Text style={styles.unitTitle}>{i18n.t('weight')}</Text></View>
                        <View style={styles.unitItem}>
                            <TextInput style={styles.textInput}
                                value={valuePound}
                                onChangeText={(text) => { setValuePound(text.replace(/([a-zA-Z]+)/, '')); setValueKilogram((text * 0.453592).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />
                            <FontAwesome name="arrows-h" size={32} color="rgb(193, 38, 45)" />
                            <TextInput style={styles.textInput}
                                value={valueKilogram}
                                onChangeText={(text) => { setValueKilogram(text.replace(/([a-zA-Z]+)/, '')); setValuePound((text * 2.20462).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />

                        </View>
                        <View style={{ height: 5 }}></View>
                        <View style={styles.unitItem}>
                            <Text>{i18n.t('pound')}(lb)</Text>
                            <Text>{i18n.t('kilogram')}(kg)</Text>
                        </View>
                    </View>
                    <View style={styles.unitItemContainer}>
                        <View style={styles.unitTitleContainer}><Text style={styles.unitTitle}>{i18n.t('volume')}</Text></View>
                        <View style={styles.unitItem}>
                            <TextInput style={styles.textInput}
                                value={valueOunce}
                                onChangeText={(text) => { setValueOunce(text.replace(/([a-zA-Z]+)/, '')); setValueMililiter((text * 28.4131).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />
                            <FontAwesome name="arrows-h" size={32} color="rgb(193, 38, 45)" />
                            <TextInput style={styles.textInput}
                                value={valueMililiter}
                                onChangeText={(text) => { setValueMililiter(text.replace(/([a-zA-Z]+)/, '')); setValueOunce((text * 0.0351951).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />

                        </View>
                        <View style={{ height: 5 }}></View>
                        <View style={styles.unitItem}>
                            <Text>{i18n.t('ounce')}(oz)</Text>
                            <Text>{i18n.t('mililiter')}(ml)</Text>
                        </View>
                    </View>
                    <View style={styles.unitItemContainer}>
                        <View style={styles.unitTitleContainer}><Text style={styles.unitTitle}>{i18n.t('temperature')}</Text></View>
                        <View style={styles.unitItem}>
                            <TextInput style={styles.textInput}
                                value={valueTempF}
                                onChangeText={(text) => { setValueTempF(text.replace(/([a-zA-Z]+)/, '')); setValueTempC(((text - 32) * 5 / 9).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />
                            <FontAwesome name="arrows-h" size={32} color="rgb(193, 38, 45)" />
                            <TextInput style={styles.textInput}
                                value={valueTempC}
                                onChangeText={(text) => { setValueTempC(text.replace(/([a-zA-Z]+)/, '')); setValueTempF(((text * 9 / 5) + 32 ).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />

                        </View>
                        <View style={{ height: 5 }}></View>
                        <View style={styles.unitItem}>
                            <Text>{i18n.t('fahrenheit')}(°F)</Text>
                            <Text>{i18n.t('celsius')}(°C)</Text>
                        </View>
                    </View>
                    <View style={styles.unitItemContainer}>
                        <View style={styles.unitTitleContainer}><Text style={styles.unitTitle}>{i18n.t('length')}</Text></View>
                        <View style={styles.unitItem}>
                            <TextInput style={styles.textInput}
                                value={valueInch}
                                onChangeText={(text) => { setValueInch(text.replace(/([a-zA-Z]+)/, '')); setValueCentimeter1((text * 2.54).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />
                            <FontAwesome name="arrows-h" size={32} color="rgb(193, 38, 45)" />
                            <TextInput style={styles.textInput}
                                value={valueCentimeter1}
                                onChangeText={(text) => { setValueCentimeter1(text.replace(/([a-zA-Z]+)/, '')); setValueInch((text * 0.393701).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />

                        </View>
                        <View style={{ height: 5 }}></View>
                        <View style={styles.unitItem}>
                            <Text>{i18n.t('inch')}(in)</Text>
                            <Text>{i18n.t('centimeter')}(cm)</Text>
                        </View>
                        <View style={{ height: 20 }}></View>
                        <View style={styles.unitItem}>
                            <TextInput style={styles.textInput}
                                value={valueFeet}
                                onChangeText={(text) => { setValueFeet(text.replace(/([a-zA-Z]+)/, '')); setValueCentimeter2((text * 30.48).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />
                            <FontAwesome name="arrows-h" size={32} color="rgb(193, 38, 45)" />
                            <TextInput style={styles.textInput}
                                value={valueCentimeter2}
                                onChangeText={(text) => { setValueCentimeter2(text.replace(/([a-zA-Z]+)/, '')); setValueFeet((text * 0.0328084).toFixed(4)) }}
                                autoCapitalize="none"
                                autoCorrect={false}
                                keyboardType="numeric"
                            />

                        </View>
                        <View style={{ height: 5 }}></View>
                        <View style={styles.unitItem}>
                            <Text>{i18n.t('feet')}(ft)</Text>
                            <Text>{i18n.t('centimeter')}(cm)</Text>
                        </View>
                    </View>
                </View>
                </ScrollView>
            </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    basic: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
    },
    button: Platform.select({
        ios: {},
        android: {
            color: '#2196F3',
        },
    }),
    textInput: {
        width: 100 * fontScale, height: 34, borderWidth: 1, borderRadius: 4, borderColor: '#808080', fontSize: 15 * fontScale, paddingHorizontal: 5, textAlign: 'right'
    },
    unitItemContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        marginVertical: 10,
        marginHorizontal: 5,
        padding: 10,
        borderRadius: 5,
        shadowColor: 'rgb(126, 126, 126)',
        shadowOffset: { width: 0.4, height: 0.4 },
        shadowOpacity: 0.8,
        shadowRadius: 1,
        elevation: 5
    },
    unitTitleContainer: {
        width: '80%',
        textAlign: 'left'
    },
    unitTitle: {
        marginBottom: 10,
        fontSize: 16 * fontScale,
        fontWeight: 'bold'
    },
    unitItem: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

});