import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, Alert, StatusBar, Dimensions, Modal, ActivityIndicator, Platform } from 'react-native';
import i18n from 'i18n-js';
import { ApolloClient, InMemoryCache, gql, useMutation } from '@apollo/client';
import { HeaderCenterImage } from '../../components/CustomHeader';
import { Ionicons } from '@expo/vector-icons';
import analytics from '@react-native-firebase/analytics';
import ApiKeys from '../../constants/ApiKeys';
import { MyButton } from '../../components/MyComponents';

const { width: windowFullWidth, height } = Dimensions.get('window');
const windowWidth = windowFullWidth > 800 ? 800 : windowFullWidth;
const fontScale = windowWidth > 480 ? 1.5 : 1;

const SignupScreen = ({ navigation }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [modalMsg, setModalMsg] = useState('');

    useEffect(() => {
        navigation.setOptions({
            headerStyle: {
                backgroundColor: '#272727',
            },
            headerTitle: () => <HeaderCenterImage />,
            headerTitleAlign: 'center',
            headerTintColor: '#ffffff',
            headerBackTitle: '',
            headerBackImage: () => <Ionicons name="chevron-back-sharp" size={36} color="white" />,
        })
    });

    const SIGNUP_MUTATION = gql`
        mutation signupMutation($username: String!, $email: String!, $password: String!) {
            register (input: { username: $username, email: $email, password: $password }) {
                jwt
                user {
                    username
                    email
                }
            }
        }
    `;

    const [mutateFunction, { loading, error, data }] = useMutation(SIGNUP_MUTATION);

    if (loading) console.log(`Trying to sign up ...`);
    if (error) console.log(`Signup error! ${error.message}`);
    if (data) console.log('Signup data ... ' + JSON.stringify(data));

    const _onSignupPress = async () => {
        const error_msg_1 = i18n.t('error_mismatch_pwd_m');
        const error_msg_2 = i18n.t('error_used_email_m');
        const error_msg_3 = i18n.t('error_create_account_m');
        const success_msg = i18n.t('noti_create_account_m');

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) == false) {
            Alert.alert('Invalid email format.');
            return;
        }
        if (password === '') {
            Alert.alert(i18n.t());
            return;
        }
        if (password !== passwordConfirm) {
            Alert.alert(error_msg_1);
            return;
        }

        const client_no_jwt = new ApolloClient({
            uri: ApiKeys.StrapiConfig.URI,
            cache: new InMemoryCache(),
        });
        
        console.log("_onSignupPress is called. email: " + email + ", password: " + password);
        try {
            const GET_USERS = gql`
                query getUsers {
                    users (where: {email: "${email}" }) {
                        id
                        username
                        email
                        confirmed
                    }
                }
            `;

            const res = await client_no_jwt.query({
                query: GET_USERS
            });

            console.log(res);
            if (res) {
                let users = res.data.users;
                console.log("[ SignupScreen ] (_onSignupPress) users: ", JSON.stringify(users));

                if (users.length) {
                    if (users[0].confirmed === true)
                        Alert.alert(i18n.t('email_used'));
                    else 
                        Alert.alert(i18n.t('email_notconfirmed'));
                        
                    return;
                }
            }
        } catch (err) {
            console.log("_onSingupPress getUsers error...", JSON.stringify(err));
            Alert.alert(error_msg_3);
            return;
        };

        try {
            const res = await mutateFunction({ variables: { username: email, email: email, password: password } });
            console.log("OK! jwt is ..." + res.data.login.jwt);
        } catch (error) {
            console.log("_onSignupPress mutation promise error..." + JSON.stringify(error));
            if (error.graphQLErrors) {
                if (Platform.OS === 'android' || Platform.OS === 'ios')
                    await analytics().logEvent('Sign_up', {
                        status: 'fail'
                    });        
                Alert.alert(error_msg_3);
            }
            else {
                if (Platform.OS === 'android' || Platform.OS === 'ios')
                    await analytics().logEvent('Sign_up', {
                        status: 'succeed'
                    });        
                setModalMsg(success_msg);
            }
        };
    }

    return (
        <View style={styles.container}>
            <StatusBar barStyle="light-content" hidden={false} />

            <Modal
                visible={modalMsg ? true : false}
                transparent={true}>
                <View style={{ flex: 1, width: windowWidth, marginHorizontal: 'auto', backgroundColor: 'rgba(20, 20, 20, 0.6)' }}>
                    <View style={{ marginHorizontal: 20, marginVertical: 100, backgroundColor: 'white', borderRadius: 8 }}>
                        <View style={{ marginHorizontal: 15, marginVertical: 20 }}>
                            <Text style={styles.modalText}>{modalMsg}</Text>
                            <MyButton
                                onPress={() => { setModalMsg(''); navigation.goBack(); }}
                                children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>}
                            />
                        </View>
                    </View>
                </View>
            </Modal>

            <Text style={{ fontSize: 19 * fontScale, fontWeight: 'bold', color: '#2b2e34', marginVertical: 25 * fontScale }}>{i18n.t('create_account_m')}</Text>

            <TextInput style={styles.textInput}
                value={email}
                onChangeText={(text) => { setEmail(text) }}
                placeholder={" " + i18n.t('email')}
                placeholderTextColor="#d0d0d0"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
            />

            <View style={{ paddingTop: 10 * fontScale }} />

            <TextInput style={styles.textInput}
                value={password}
                onChangeText={(text) => { setPassword(text) }}
                placeholder={" " + i18n.t('password')}
                placeholderTextColor="#d0d0d0"
                secureTextEntry={true}
                autoCapitalize="none"
                autoCorrect={false}
            />

            <View style={{ paddingTop: 10 * fontScale }} />

            <TextInput style={styles.textInput}
                value={passwordConfirm}
                onChangeText={(text) => { setPasswordConfirm(text) }}
                placeholder={" " + i18n.t('password_confirm')}
                placeholderTextColor="#d0d0d0"
                secureTextEntry={true}
                autoCapitalize="none"
                autoCorrect={false}
            />

            {loading ? 
                <ActivityIndicator style={{ marginTop: 20 * fontScale }}/>
             :
                <MyButton onPress={_onSignupPress} style={{ marginTop: 20 * fontScale }} children={() => <Text style={styles.appButtonText}>{i18n.t('confirm_m')}</Text>} />
            }
        </View>
    )
}

export default SignupScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1, alignItems: "center",
    },
    textInput: {
        fontSize: 14 * fontScale,
        width: 200 * fontScale, height: 40 * fontScale, borderWidth: 1, borderRadius: 4,
    },
    button: {
        marginVertical: 10 * fontScale,
        paddingHorizontal: 15 * fontScale
    },
    appButtonText: {
        fontFamily: 'CustomHangul',
        fontSize: 14 * fontScale,
        color: "#fff",
        alignSelf: "center",
        textTransform: "uppercase"
    },
    modalText: {
        fontFamily: 'CustomHangul',
        fontSize: 16 * fontScale,
        color: '#272727',
        marginBottom: 25,
        textAlign: 'center',
    },
});