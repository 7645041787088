import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  ScrollView, StyleSheet, Text, View, TextInput, Alert, Switch, Linking, AppState, Dimensions, Picker,
  StatusBar, Platform, TouchableOpacity, Image, ActivityIndicator, FlatList, SectionList, Share, Modal
} from 'react-native';
import { HeaderCenterText, HeaderMenuImage, HeaderNotificationImage } from '../components/CustomHeader';
import { Ionicons } from '@expo/vector-icons';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import { gql, useQuery, useMutation, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/core';
import { useFocusEffect } from '@react-navigation/native';
import MyContext from '../components/MyContext';
import Communications from 'react-native-communications';
import analytics from '@react-native-firebase/analytics';
import * as Sentry from 'sentry-expo';
import ApiKeys from '../constants/ApiKeys';

const newclient = new ApolloClient({
  uri: ApiKeys.StrapiConfig.URI,
  cache: new InMemoryCache(),
});

const { width, height } = Dimensions.get('window');
const fontScale = width > 480 ? 1.5 : 1;

const MypageListScreen = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [bookmarksYpages, setBookmarksYpages] = useState([]);
  const [dataYpages, setDataYpages] = useState([]);

  const { myEmail } = useContext(MyContext);

  const _getmasters = async () => {
    if (bookmarksYpages.length === 0) {
      setDataYpages([]);
      return;
    }
    
    const listYpages = JSON.stringify(bookmarksYpages);

    const GET_MASTERS = gql`
      query getMasters {
        masters (where: { id_in: ${listYpages} } ) {
          id,
          name,
          name_en,
          formatted_address,
          phone1,
          phone2,
          email1,
          web,
          geo_lat,
          geo_lng,
          categories { name }
        }
      }
    `;

    try {
      setLoading(true);

      const res = await newclient.query({
        query: GET_MASTERS, fetchPolicy: 'no-cache'
      });
      
      if (res) {
        console.log("[ MyPageListScreen ] get masters of ", listYpages);
        setDataYpages(res.data.masters);
        setLoading(false);
      }
    } catch (err) {
      if (Platform.OS === 'android' || Platform.OS === 'ios')
        Sentry.Native.captureException(err);
      console.log(" [ MyPageListScreen ] get_masters Error: ", err);
      setLoading(false);
    }
  }

  console.log("[ MyPageListScreen ] is called.... ", bookmarksYpages ? bookmarksYpages.length: 0, ",", dataYpages ? dataYpages.length: 0, ",", 
               loading);
  
  useEffect(() => {
    console.log("[ MyPageListScreen ] useEffect(). ");

    navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={i18n.t('mt_bookmark')} onPress={() => navigation.navigate('SearchList')} />,
      headerTitleAlign: 'left',
      headerRight: () => <View style={{ flexDirection: 'row' }}>
        <HeaderNotificationImage onPress={() => navigation.navigate('NotificationList')} />
        <HeaderMenuImage onPress={() => navigation.navigate('MenuList')} />
      </View>,
    })

    _getmasters();
  }, [bookmarksYpages]);

  useFocusEffect(
    useCallback(() => {
      AsyncStorage.getItem('Bookmarks-Ypages')
        .then(res => {
          let stored_bookmarks_ypages;

          stored_bookmarks_ypages = JSON.parse(res);
          console.log('[ MyPageListScreen ] useFocusEffect(). Get Ypage bookmarks from Asyncstorage: ' + stored_bookmarks_ypages);

          if (stored_bookmarks_ypages == '' || stored_bookmarks_ypages == null) {
            console.log('[ MyPageListScreen ] ++++++ ypages empty');
            setBookmarksYpages([]);
            return;
          }

          setBookmarksYpages(stored_bookmarks_ypages);
        })
        .catch(err => {
          if (Platform.OS === 'android' || Platform.OS === 'ios')
            Sentry.Native.captureException(err);
          console.log('[ MyPageListScreen ] Error: Fail to get Ypage bookmarks from Asyncstorage.' + err);
          return;
        })

      return () => {
        //AppState.removeEventListener('change', _handleAppStateChange);
      }
    }, [])
  );

  const _showupShareYpage = async (id, title, content) => {
    if (Platform.OS === 'android' || Platform.OS === 'ios')
      await analytics().logEvent('Share', {
          title: title
      });        

    try {
      const result =     Share.share({
        message: 'CAKORY ' + i18n.t('share_place') + '\n\n'+ title + '\n' + content + '\n\n' + i18n.t('share_cakory_footer'),
        title: 'CAKORY ' + i18n.t('share_place')
      }, {
        // Android only:
        dialogTitle: 'CAKORY ' + i18n.t('share_place'),
        // iOS only:
        subject: 'CAKORY ' + i18n.t('share_place'),
      })
    } catch (err) {
      if (Platform.OS === 'android' || Platform.OS === 'ios')
       Sentry.Native.captureException(err);
      Alert.alert(i18n.t('sharing_err'));      
    }
  }

  const _toggleBookmark = (id, db_name) => {
    /*
    if (!myEmail) {
      Alert.alert(i18n.t('please_login'));
      return;
    }
    */
    console.log('[ MyPageListScreen ] Toggling Bookmark id: ' + id);
    // Check whether this is currently bookmarked.

    AsyncStorage.getItem(db_name)
      .then(res => {
        let stored_bookmarks;
        console.log('[ MyPageListScreen ] result of AsyncStorage getItem: Success');
        if (res != null)
          stored_bookmarks = JSON.parse(res);

        console.log('[ MyPageListScreen ] Get bookmarks from Asyncstorage: ' + stored_bookmarks);

        let new_bookmarks;

        if (stored_bookmarks && stored_bookmarks.indexOf(id) != -1) { // already have
          console.log('[ MyPageListScreen ] ...Bookmark found');
          new_bookmarks = stored_bookmarks.filter(i => i != id);
          console.log('[ MyPageListScreen ] After removing => bookmarks: ' + new_bookmarks);
        }
        else {
          console.log('[ MyPageListScreen ] ... Bookmark not found');
          new_bookmarks = stored_bookmarks ? [...stored_bookmarks, id] : [id];
          
          if (Platform.OS === 'android' || Platform.OS === 'ios')
            analytics().logEvent('Bookmark', {
                id: id
            });        

          console.log('[ MyPageListScreen ] After adding => bookmarks: ' + new_bookmarks);
        }

        AsyncStorage.setItem(db_name, JSON.stringify(new_bookmarks))
          .then(res => {
            console.log('[ MyPageListScreen ]  result of AsyncStorage setItem: Success');
            if (db_name == 'Bookmarks-Ypages') {
              setBookmarksYpages(new_bookmarks);
            } else {
              console.log("[ MyPageListScreen ] Error.... Invalid DB name.");
            }
          })
          .catch(err => {
            if (Platform.OS === 'android' || Platform.OS === 'ios')
              Sentry.Native.captureException(err);
            console.log('[ MyPageListScreen ] Error: Fail to set bookmarks into Asyncstorage.' + err);
            return;
          })
      })
      .catch(err => {
        if (Platform.OS === 'android' || Platform.OS === 'ios')
          Sentry.Native.captureException(err);
        console.log('[ MyPageListScreen ] Error: Fail to get Ypage bookmarks from Asyncstorage.' + err);
        return;
      })
  }

  console.log('[ MyPageListScreen ] Mypage rendering ...');

  return (
    <View style={styles.container}>

      <View style={[styles.ypageSublistCatItem2, { display: "flex" }]}>
        <Text style={styles.ypageSublistCatTitle2}>{i18n.t('bookmarked_places')}</Text>
      </View>

      <FlatList
        data={dataYpages}
        keyExtractor={(item, index) => index.toString()}

        ListEmptyComponent={ 
          <View style={{ padding: 25, fontFamily: 'CustomHangul' }}> 
            { loading ? <ActivityIndicator color='grey' /> : <Text style={styles.basic}>{i18n.t("no_bookmarks")}</Text>}
          </View>
        }

        renderItem={({ item }) => {
          //console.log(item);
          let title;
          if (i18n.locale === 'en' && item.name_en) title = item.name_en;
          else title = item.name;

          return (
            <TouchableOpacity
              style={styles.ypageSublistItemContentContainer}
              onPress={() => navigation.navigate('YpageArticle',
                {
                  title: title,
                  formatted_address: item.formatted_address,
                  phone1: item.phone1,
                  phone2: item.phone2,
                  email: item.email1,
                  web: item.web,
                  lat: item.geo_lat,
                  lng: item.geo_lng,
                  itemId: item.id,
                })}
            >

              <View>
                <Text style={styles.ypageSublistItemContent1}>{title} </Text>
                {item.phone1 ?
                  <TouchableOpacity onLongPress={() => Communications.phonecall(item.phone1, true)}>
                    <Text style={styles.ypageSublistItemContent1}>{item.phone1.replace(/[1]*(\d{3})(\d{3})(\d{4})/i, '$1-$2-$3')}</Text>
                  </TouchableOpacity>
                  :
                  false
                }
              </View>
              <View>
                <TouchableOpacity
                  style={{ position: 'absolute', top: 9, right: 45 }}
                  onPress={() => _showupShareYpage(item.id, item.name, i18n.t('phonenum') + ': ' + item.phone1 + 
                                                  '\n' + i18n.t('address') + ': ' + item.formatted_address + 
                                                  '\nWeb: ' + item.web)}
                >
                  <Ionicons name="share-outline" size={24 * fontScale} color="#2f95dc" />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ position: 'absolute', top: 10, right: 5 }}
                  onPress={() => _toggleBookmark(item.id, 'Bookmarks-Ypages')}
                >
                  <Ionicons name="bookmark" size={24 * fontScale} color="#2f95dc" />

                </TouchableOpacity>
              </View>
            </TouchableOpacity>
          )
        }}

        style={{ backgroundColor: '#ffffff' }}
      />

    </View>
  );

}

export default MypageListScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(226, 226, 226)',
  },
  basic: {
    fontFamily: 'CustomHangul',
    fontSize: 14 * fontScale,
  },
  categoryTitle: {
    marginHorizontal: 15,
    fontSize: 18 * fontScale,
    fontWeight: 'bold',
    color: '#212121',
    backgroundColor: '#e0e0e0',
  },
  categoryTitle2: {
    fontSize: 15 * fontScale,
    color: '#0046d3',
    marginRight: 10,
  },
  ypageSublistCatItem2: {
    backgroundColor: '#f6c417',
  },
  ypageSublistCatItem2b: {
    marginTop: 5,
    backgroundColor: '#c1262d',
  },
  ypageSublistCatTitle2: {
    alignSelf: 'flex-start',
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    lineHeight: 36 * fontScale,
    color: '#272727',
    marginLeft: 25,
  },
  ypageItemContainer: {
    flex: 1,
    marginBottom: 5,
    backgroundColor: 'yellow'
  },
  ypageItemContainerInner: {
    alignSelf: 'center',
    flexDirection: 'row',
    backgroundColor: '#fff'
  },
  ypageDate: {
    fontFamily: 'CustomHangul',
    fontSize: 10 * fontScale,
    fontWeight: 'normal',
    lineHeight: 12 * fontScale,
    color: '#848484',
  },
  ypageImage: {
    width: 135 * fontScale,
    height: 110 * fontScale,
    marginRight: 10,
  },
  ypageBenefits: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 60 * fontScale,
    height: 21 * fontScale,
    backgroundColor: '#c1262d',
    color: '#fff',
    fontSize: 11 * fontScale,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 20 * fontScale,
  },
  ypageText: {
    flex: 1,
    justifyContent: 'space-between',
    fontFamily: 'CustomHangul',
    marginBottom: 5,
    marginRight: 10,
  },
  ypageTitle: {
    fontFamily: 'CustomHangul',
    fontSize: 20 * fontScale,
    lineHeight: 23 * fontScale,
    color: '#212121',
    marginTop: 11,
    width: width - 200,
  },
  ypageVenue: {
    fontSize: 13,
    lineHeight: 15,
    color: '#595b60',
    marginTop: 3,
    marginBottom: 9,
  },
  eventItemContainer: {
    flex: 1,
    marginBottom: 12,
    backgroundColor: '#fff'
  },
  eventItemContainerInner: {
    alignSelf: 'center',
  },
  eventDate: {
    fontFamily: 'CustomHangul',
    fontSize: 14 * fontScale,
    fontWeight: 'bold',
    lineHeight: 20 * fontScale,
    color: '#c1262d',
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 20,
  },
  eventTitle: {
    flex: 1,
    fontFamily: 'CustomHangul',
    fontSize: 18 * fontScale,
    lineHeight: 20 * fontScale,
    color: '#2b2e34',
    marginTop: 11,
    marginLeft: 20,
  },
  eventVenue: {
    fontSize: 13 * fontScale,
    lineHeight: 15 * fontScale,
    color: '#595b60',
    marginTop: 3,
    marginBottom: 9,
    marginLeft: 20,
  },
  eventArticle: {
    flex: 1,
    alignSelf: 'center',
  },
  eventArticleImage: {
    width: '100%',
    alignSelf: 'center',
    height: 210,
    marginTop: 22,
    marginBottom: 16,
  },
  eventArticleDate: {
    fontSize: 15,
    lineHeight: 17,
    color: '#595b60',
    marginLeft: 35,
  },
  eventArticleTitle: {
    fontSize: 24,
    lineHeight: 27,
    letterSpacing: 0,
    marginTop: 16,
    marginBottom: 4,
    marginLeft: 35,
    marginRight: 35,
  },
  eventArticleVenue: {
    fontSize: 15,
    lineHeight: 17,
    color: '#85868a',
    marginLeft: 35,
  },
  eventArticleFootnote: {
    fontSize: 10,
    lineHeight: 21,
    color: '#212121',
    textAlign: 'center',
  },
  ypageArticle: {
    flex: 1,
    alignSelf: 'center',
  },
  ypageArticleImage: {
    width: '100%',
    alignSelf: 'center',
    height: 210,
    marginTop: 22,
    marginBottom: 16,
  },
  ypageArticleBenefits: {
    position: 'absolute',
    top: 10,
    left: 40,
    width: 51,
    height: 50,
    backgroundColor: '#c1262d',
    color: '#fff',
    fontSize: 18,
    textAlign: 'center',
  },
  ypageArticleDate: {
    fontSize: 15,
    lineHeight: 17,
    color: '#595b60',
    marginTop: 35,
    marginBottom: 20,
    marginLeft: 35,
  },
  ypageArticleTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 27,
    letterSpacing: 0,
    marginTop: 16,
    marginBottom: 4,
    marginLeft: 35,
    marginRight: 35,
    color: '#2b2e34',
  },
  ypageArticleSubtitle: {
  },
  ypageArticleVenue: {
    fontSize: 15,
    lineHeight: 17,
    color: '#85868a',
    marginLeft: 35,
  },
  ypageArticleFootnote: {
    fontSize: 10,
    lineHeight: 21,
    color: '#212121',
    textAlign: 'center',
  },
  ypageSublistItemContainer: {
    backgroundColor: 'rgb(226, 226, 226)',
  },
  ypageSublistItemContentContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomColor: '#e2e2e2',
    borderBottomWidth: 1,
  },
  ypageSublistItemContent1: {
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#595b60',
    marginLeft: 10,
    marginVertical: 5,
  },
  ypageSublistItemContent2: {
    fontFamily: 'CustomHangul',
    fontSize: 16 * fontScale,
    color: '#595b60',
    textAlign: 'right',
    marginRight: 10,
  },
  settingTitle: {
    color: "#c1262d", fontSize: 17, paddingTop: 25, paddingBottom: 5, paddingHorizontal: 10,
  },
  settingtext: {
    color: '#595b60', fontSize: 21, paddingTop: 5, paddingBottom: 5, paddingLeft: 20,
  },
  settingButton: Platform.select({
    ios: {
      textAlign: 'left', fontSize: 21, paddingTop: 5, paddingBottom: 5, paddingLeft: 20, color: '#595b60',
    },
    android: {
      textAlign: 'left', fontSize: 21, paddingTop: 5, paddingBottom: 5, paddingLeft: 20, color: '#595b60',
    },
  }),
  settingSwitchPush: {
    flex: 1, paddingTop: 1, paddingBottom: 5, paddingLeft: 45
  },
  switch: Platform.select({
    ios: {
      backgroundColor: 'white', borderRadius: 17
    },
    android: {
      width: '50%',
      borderRadius: 17
    },
  }),
  settingTextInput: {
    width: 200, height: 40, borderWidth: 1, borderRadius: 4, marginVertical: 5, padding: 10, alignSelf: "center", backgroundColor: '#ffffff',
  },
  buttonContainer: Platform.select({
    ios: {
      marginVertical: 10,
    },
    android: {
      marginVertical: 10,
    },
  }),
  modalView: {
    backgroundColor: 'rgba(255,255,255, 1)',
    position: 'absolute',
    width: width,
    top: 60,
    height: height - 120,
  },
  modalContainer: {
    width: width,
    height: height,
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
  },
  appButtonContainer: {
    width: '70%',
    alignSelf: 'center',
    elevation: 8,
    backgroundColor: "#009688",
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 12,
    marginTop: 30
  },
  appButtonText: {
    fontSize: 16,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase"
  }
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    width: 120,
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    width: 120,
    fontSize: 16,
    paddingHorizontal: 5,
    paddingVertical: 4,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputWeb: {
    width: 120,
    fontSize: 16,
    paddingHorizontal: 5,
    paddingVertical: 4,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
});