import React from 'react';
import { StyleSheet, View, Text, TextInput, Button, Alert, StatusBar } from 'react-native';
import { HeaderCenterImage } from '../../components/CustomHeader';
import i18n from 'i18n-js';
import { MyButton } from '../../components/MyComponents';

export default class SignupOkScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    onGoToMainPress = () => {
        this.props.navigation.navigate('Login');
    }

    render() {
        return (
            <View style={{paddingTop:50, alignItems:"center"}}>
                <StatusBar barStyle="light-content" hidden={false} />
                <Text>{i18n.t('success_in_create_account_m')}</Text>

                <MyButton 
                    children={() => <Text style={{ alignSelf: 'center', fontFamily: 'CustomHangul', color: 'white' }}>{i18n.t('go_to_login_page_m')}</Text>} 
                    onPress={this.onGoToMainPress} />
            </View>
        );
    }
}

const styles = StyleSheet.create({

});