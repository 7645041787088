export const areaList = [
    {
      label: 'Your location',
      value: '1',
      cities: [ 
        { label: 'lt5km', value: '1', lat: 0, lng: 0 },
        { label: 'lt15km', value: '2', lat: 0, lng: 0 },
        { label: 'lt25km', value: '3', lat: 0, lng: 0 },
        { label: 'lt50km', value: '4', lat: 0, lng: 0 },
      ]
    },
    {
      label: 'Alberta',
      value: '2',
      cities: [
        { label: 'Calgary', value: '1', lat: 51.049999, lng: -114.066666 },
        { label: 'Edmonton', value: '2', lat: 53.55530, lng: -113.49479 },
        { label: 'Etc', value: '100', lat: 55.000000, lng: -115.000000 },
        { label: 'Allarea', value: '1000', lat: 55.000000, lng: -115.000000 },
      ]
    },
    {
      label: 'British Columbia',
      value: '3',
      cities: [
        { label: 'Victoria', value: '1', lat: 48.407326, lng: -123.329773 },
        { label: 'Vancouver', value: '2', lat: 49.246292, lng: -123.116226 },
        { label: 'Burnaby', value: '3', lat: 49.248810, lng: -122.980507 },
        { label: 'Coquitlam', value: '4', lat: 49.283764, lng: -122.793205 },
        { label: 'Langley', value: '5', lat: 49.103569, lng: -122.656563 },
        { label: 'Surrey', value: '6', lat: 49.104599, lng: -122.823509 },
        { label: 'Etc', value: '100', lat: 53.726669, lng: -127.647621 },
        { label: 'Allarea', value: '1000', lat: 53.726669, lng: -127.647621 },
      ]
    },
    {
      label: 'Manitoba',
      value: '4',
      cities: [
        { label: 'Winnipeg', value: '1', lat: 49.899754, lng: -97.137494 },
        { label: 'Etc', value: '100', lat: 53.760861, lng: -98.813876 },
        { label: 'Allarea', value: '1000', lat: 53.760861, lng: -98.813876 },
      ]
    },
    {
      label: 'New Brunswick',
      value: '5',
      cities: [
        { label: 'Saint John', value: '1', lat: 45.273918, lng: -66.067657 },
        { label: 'Etc', value: '100', lat: 46.498390, lng: -66.159668 },
        { label: 'Allarea', value: '1000', lat: 46.498390, lng: -66.159668 },
      ]
    },
    {
      label: 'Nova Scotia',
      value: '6',
      cities: [
        { label: 'Halifax', value: '1', lat: 44.651070, lng: -63.582687 },
        { label: 'Etc', value: '100', lat: 45.000000, lng: -63.000000 },
        { label: 'Allarea', value: '1000', lat: 45.000000, lng: -63.000000 },
      ]
    },
    {
      label: 'Ontario',
      value: '7',
      cities: [
        { label: 'Ottawa', value: '1', lat: 45.424721, lng: -75.695000 },
        { label: 'Toronto', value: '2', lat: 43.651070, lng: -79.347015 },
        { label: 'Markham', value: '3', lat: 43.856098, lng: -79.337021 },
        { label: 'Mississauga', value: '4', lat: 43.595310, lng: -79.640579 },
        { label: 'Richmond Hill', value: '5', lat: 43.887501, lng: -79.428406 },
        { label: 'Vaughan', value: '6', lat: 43.837208, lng: -79.508278 },
        { label: 'North York', value: '7', lat: 43.756616, lng: -79.443717 },
        { label: 'London', value: '8', lat: 42.99458, lng: -81.24456 },
        { label: 'Hamilton', value: '9', lat: 43.234994, lng: -79.874097 },
        { label: 'Etc', value: '100', lat: 50.000000, lng: -85.000000 },
        { label: 'Allarea', value: '1000', lat: 50.000000, lng: -85.000000 },
      ]
    },
    {
      label: 'Saskatchewan',
      value: '8',
      cities: [
        { label: 'Regina', value: '1', lat: 50.445210, lng: -104.618896 },
        { label: 'Etc', value: '100', lat: 55.000000, lng: -106.000000 },
        { label: 'Allarea', value: '1000', lat: 55.000000, lng: -106.000000 },
      ]
    },
    {
      label: 'Quebec',
      value: '9',
      cities: [
        { label: 'Montreal', value: '1', lat: 45.508888, lng: -73.561668 },
        { label: 'Etc', value: '100', lat: 46.829853, lng: -71.254028 },
        { label: 'Allarea', value: '1000', lat: 46.829853, lng: -71.254028 },
      ]
    },
    {
      label: 'Washington',
      value: '20',
      cities: [
        { label: 'Seattle', value: '1', lat: 47.6062, lng: -122.3321 },
        { label: 'Bellevue', value: '2', lat: 47.6101, lng: -122.2015 },
        { label: 'Federal Way', value: '3', lat: 47.3223, lng: -122.3126 },
        { label: 'Lakewood', value: '4', lat: 47.1718, lng: -122.5185 },
        { label: 'Lynnwood', value: '5', lat: 47.8209, lng: -122.3151 },
        { label: 'Tacoma', value: '6', lat: 47.2529, lng: -122.4443 },
        { label: 'Etc', value: '100', lat: 47.7511, lng: -120.7401 },
        { label: 'Allarea', value: '1000', lat: 47.7511, lng: -120.7401 },
      ]
    },
    {
      label: 'Online Only',
      value: '100',
      cities: [
        { label: 'Allarea', value: '1000', lat: 0.000000, lng: 0.000000 },
      ]
    },
  ];
  
  export const LeftmostLng = -141.7;
  export const RightmostLng = -51.8;