import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, FlatList, TouchableOpacity, Text, StatusBar, ActivityIndicator, AppState, Dimensions, Platform } from 'react-native';
import i18n from 'i18n-js';
import { gql, useQuery, useMutation, ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import ApiKeys from '../constants/ApiKeys';
import { HeaderCenterText, HeaderMenuImage, HeaderNotificationImage } from '../components/CustomHeader';
import MyContext from '../components/MyContext';
import * as Sentry from 'sentry-expo';

const { width, height } = Dimensions.get('window');
const fontScale = width > 480 ? 1.5 : 1;

export default function NotificationListScreen({ navigation }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchOffset, setSearchOffset] = useState(0);

  var { updateMyContext } = useContext(MyContext);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => <HeaderCenterText title={i18n.t('notification_list')} />,
      headerTitleAlign: 'left',
      headerRight: () => <View style={{ flexDirection: 'row' }}>
        <HeaderMenuImage onPress={() => navigation.navigate('MenuList')} />
      </View>,
    })

    updateMyContext({ myNotiCheckTime: Date.now()});   

    fetchData({ refreshing: true });
    const subscription = AppState.addEventListener('change', _handleAppStateChange);

    return () => {
      subscription.remove();
    }
  }, []);

  console.log("[ NotificationListScreen ] is called. ");

  const search_limit = 5;

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'active') {
      console.log('AppState has come to active. fetchData of Notification');
    }
  }

  const fetchData = async ({ refreshing }) => {
    const client_no_jwt = new ApolloClient({
      uri: ApiKeys.StrapiConfig.URI,
      cache: new InMemoryCache(),
    });

    var start_offset;

    if (refreshing) start_offset = 0;
    else start_offset = searchOffset;

    const GET_EXPONOTICATIONS = gql`
      query getExpoNotifications {
          exponotifications (sort: "id:desc", limit: ${search_limit}, start: ${start_offset}, where: { status: "finished" }) {
              id,
              title,
              body,
              created_at
          },
      }
    `;

    console.log("[ NotificationListScreen ] (fetchData) is called. search_limit: ", search_limit, ", start_offset: ", start_offset);

    setLoading(true);

    try {
      const res = await client_no_jwt.query({
        query: GET_EXPONOTICATIONS
      });

      //console.log(res);
      
      if (res) {
        setSearchOffset(start_offset + res.data.exponotifications.length);
        if (refreshing) setData(res.data.exponotifications);
        else setData([...data, ...res.data.exponotifications]);
      }
      setLoading(false);
    } catch (err) {
      if (Platform.OS === 'android' || Platform.OS === 'ios')
        Sentry.Native.captureException(err);
      //setLoading(false);
      //throw err;
    }
    
  };

  const _renderFooter = () => {
    if (data.length && (data.length % search_limit === 0)) {
      return (
        //Footer View with Load More button
        <View style={styles.footer}>
          <Text style={{ marginRight: 10 }}> {i18n.t("upto_now1")} {data.length} {i18n.t("upto_now2")} </Text>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => fetchData({ refreshing: false })}
            //On Click of button load more data
            style={styles.loadMoreBtn}>
            <Text style={styles.btnText}>{i18n.t("load_more")}</Text>
            {loading ? (
              <ActivityIndicator
                color="white"
                style={{ marginLeft: 8 }} />
            ) : null}
          </TouchableOpacity>
        </View>
      );
    }
    return (
      <View style={{ height: 40, backgroundColor: '#f0f0f0' }}></View>
    )
  };

  return (
    <View style={{ marginTop: 10 }}>
      <StatusBar barStyle="default" hidden={false} />
      <FlatList
        data={data}
        keyExtractor={(item, index) => index.toString()}
        //ListHeaderComponent={this.renderHeader}
        ListFooterComponent={_renderFooter}
        ListEmptyComponent={
          <View style={{ padding: 20, textAlign: 'center' }}>
            <ActivityIndicator color='rgb(193, 38, 45)' />
          </View>
        }
        renderItem={({ item }) => {
          return (
            <View style={styles.itemContainer}>
              <TouchableOpacity style={{ paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: '#c0c0c0' }}>
                <Text style={{ fontSize: 17 * fontScale, color: '#272727', fontFamily: 'CustomHangul' }}>{item.title}</Text>
              </TouchableOpacity>
              <View style={{ paddingVertical: 10 }}>
                <Text style={{ fontSize: 14 * fontScale, color: '#595b60', fontFamily: 'CustomHangul' }}>{item.body}</Text>
              </View>
              <Text style={{ fontSize: 12 * fontScale, textAlign: 'right', color: '#a0a0a0' }}>{item.created_at.replace('T', ' ').replace('.000Z', '')}</Text>
            </View>
          )
        }}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 15,
    backgroundColor: '#fff',
  },
  itemContainer: {
    margin: 10 * fontScale, 
    padding: 10 * fontScale,    
    backgroundColor: '#ffffff',
    borderRadius: 5,
    shadowColor: 'rgb(126, 126, 126)',
    shadowOffset: { width: 0.4, height: 0.4 },
    shadowOpacity: 0.8,
    shadowRadius: 1,  
    elevation: 5
  },
  footer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  loadMoreBtn: {
    padding: 10,
    backgroundColor: '#800000',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
  },
});
