import React, { useContext } from 'react';
import { Image,  TouchableOpacity, Text, View, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import MyContext from './MyContext';

const { width, height } = Dimensions.get('window');
const fontScale = width > 480 ? 1.5 : 1;

export class HeaderCenterImage extends React.Component {
    render() {
        return (
            <TouchableOpacity
            style={{
                alignSelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onPress={this.props.onPress}
            >
            <Image source={require('../assets/images/cakory-title1.png')} />
            </TouchableOpacity>
        );
    }
}

export class HeaderCenterText extends React.Component {
    render() {
        return (
            <TouchableOpacity
            style={{
                alignSelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onPress={this.props.onPress}
            >
            <Text style={{ fontSize: 24, fontFamily: 'CustomHangul' }} numberOfLines={1} ellipsizeMode='tail' >{this.props.title}</Text> 
            </TouchableOpacity>
        );
    }
}

export class HeaderRightImage extends React.Component {
    render() {
        return (
            <TouchableOpacity
            style={{
                marginTop: 5,
                marginRight: 5 * fontScale,
            }}
            onPress={this.props.onPress}
            >
            <Ionicons name="search" size={24 * fontScale} color="#272727" />
            </TouchableOpacity>
        );
    }
}

export class HeaderOptionsImage extends React.Component {
    render() {
        return (
            <View style={{ alignItems: 'flex-end'}}>
            <TouchableOpacity
            style={{
                marginLeft: 10,
                marginRight: 5 * fontScale,
            }}
            onPress={this.props.onPress}
            >
            <Ionicons name="options" size={24} color="#272727" />
            </TouchableOpacity>
            {this.props.accessoryLeft}
            </View>
        );
    }
}

export class HeaderSettingImage extends React.Component {
    render() {
        return (
            <TouchableOpacity
            style={{
                marginTop: 5,
                marginRight: 5 * fontScale,
            }}
            onPress={this.props.onPress}
            >
            <Ionicons name="settings-outline" size={24 * fontScale} color="#272727" />
            </TouchableOpacity>
        );
    }
}

export class HeaderMenuImage extends React.Component {
    static contextType = MyContext;

    render() {
        return (
            <TouchableOpacity
            style={{ width: 32, height: 32, borderRadius: 16, backgroundColor: 'rgb(193, 38, 45)', justifyContent: 'center', alignItems: 'center', 
                marginTop: 5,
                marginRight: 12 * fontScale,
                marginLeft: 0,
                marginBottom: 4,
            }}
            onPress={this.props.onPress}
            >        
                {this.context.myId > 0 ? 
                    <Text style={{ fontSize: 20, color: 'white' }}>{this.context.myEmail.charAt(0).toUpperCase()}</Text>
                    : 
                    <Ionicons name="ellipsis-horizontal" size={22} color="white" style={{ left: 1 }} /> 
                }
                
            </TouchableOpacity>
        );
    }
}

export class HeaderNotificationImage extends React.Component {
    static contextType = MyContext;

    render() {
        return (
            <TouchableOpacity
            style={{ width: 32, height: 32, justifyContent: 'center', alignItems: 'center', 
                marginTop: 5,
                marginRight: 20 * fontScale,
                marginBottom: 4,
            }}
            onPress={this.props.onPress}
            >
            <Ionicons name="notifications-outline" size={26} color="#272727" />
            { this.context.myNotiCheckTime < this.context.myNotiArriveTime ?
            <View style={{ width:8, height:8, backgroundColor:'rgb(193, 38, 45)', borderRadius: 6, position:'absolute', top:2, right:2 }} />
            :
            false
            }
            </TouchableOpacity>
        );
    }
}
